import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseAppState } from '../../../../../core/store/reducers';
import { Store } from '@ngrx/store';
import { ElearningActionTypes, LoadQuestions, SubmitElearningAnswers } from '../../actions/elearning.actions';
import {
  getCongressSlugRouterParam,
  getOnDemandVideoIdRouterParam
} from '../../../../../core/router/selectors/router.selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ElearningSelectors } from '../../selectors/elearning.selectors';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { ObservableComponent } from '../../../../components/observable/observable.component';
import { getSelectedOnDemandVideo } from '../../../../../core/data/selectors/data-api.selectors';
import { DataApiActionTypes, LoadOnDemandVideo } from '../../../../../core/data/actions/data-api.actions';
import { QuestionAnswers } from 'medtoday-models-library';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoToOnDemandVideo } from '../../../../../core/router/actions/main-navigation.actions';

declare const $;
@Component({
  selector: 'lib-e-learning',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionsComponent extends ObservableComponent implements OnInit {
  readonly loadOnDemandVideoAsyncKey = DataApiActionTypes.LoadOnDemandVideo;
  readonly loadElearningQuestionsAsyncKey = ElearningActionTypes.LoadQuestions;
  readonly submitElearningAnswersAsyncKey = ElearningActionTypes.SubmitElearningAnswers;

  onDemandVideoId$ = this.store.select(getOnDemandVideoIdRouterParam);
  onDemandVideoData$ = this.store.select(getSelectedOnDemandVideo);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  questionsAnswers$ = this.store.select(ElearningSelectors.selectQuestions);
  activeQuestion$: Subject<number> = new Subject<number>();
  qaForm: FormGroup<{}>;

  constructor(private store: Store<BaseAppState>, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.activeQuestion$.unsubscribe();
  }

  private loadData() {
    combineLatest([this.onDemandVideoId$, this.congressSlug$])
      .pipe(
        filter(([id, _]) => !!id),
        take(1)
      )
      .subscribe(([vodId, congressSlug]) => {
        this.store.dispatch(new LoadOnDemandVideo(congressSlug, vodId));
        this.store.dispatch(new LoadQuestions(vodId));
      });

    this.questionsAnswers$
      .pipe(
        filter(data => !!data?.length),
        take(1)
      )
      .subscribe(data => {
        this.buildForm(data!);
        setTimeout(() => {
          this.initListeners();
        });
      });
  }

  handleSubmitAnswers() {
    if (this.qaForm.valid) {
      combineLatest([this.onDemandVideoId$, this.congressSlug$])
        .pipe(take(1))
        .subscribe(([vodId, congressSlug]) => {
          this.store.dispatch(new SubmitElearningAnswers(vodId, congressSlug, this.qaForm.value));
        });
    }
  }

  private initListeners() {
    fromEvent($('#carousel'), 'slide.bs.carousel')
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((event: any) => {
        this.activeQuestion$.next(event.to);
        this.cdr.detectChanges();
      });
  }

  private buildForm(questionAnswers: QuestionAnswers[]) {
    const form = questionAnswers.reduce((agg, qAnswers) => {
      agg[qAnswers.uuid] = new FormControl(null, Validators.required);
      return agg;
    }, {});

    this.qaForm = new FormGroup(form);
  }

  handleBackToVideo() {
    combineLatest([this.onDemandVideoId$, this.congressSlug$])
      .pipe(take(1))
      .subscribe(([vodId, congressSlug]) => {
        this.store.dispatch(new GoToOnDemandVideo(vodId, false, undefined, congressSlug));
      });
  }
}
