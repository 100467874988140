import { Component, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Topic } from 'medtoday-models-library/lib/models';

import { imagePaths } from '../../../../medtoday/src/environments/environment';
import { getExpertsData, getSelectedExpert } from '../../../core/data/selectors/data-api.selectors';
import { BaseAppState } from '../../../core/store/reducers';
import { TopicCategory } from 'medtoday-models-library';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-expert-details-modal',
  templateUrl: './expert-details-modal.component.html',
  styleUrls: ['./expert-details-modal.component.scss']
})
export class ExpertDetailsModalComponent {
  avatarsImageFolderSlug = imagePaths.avatars;

  selectedExpert$ = this.store.select(getSelectedExpert);
  expertsData$ = this.store.select(getExpertsData);
  @Input() showTopicCategories = false;
  @Input() showTopics = true;
  @Input() topicCategories: TopicCategory[] = [];

  expertTopicCategories = this.selectedExpert$.pipe(
    map(expert => this.topicCategories.filter(topicCategory => expert?.topicCategoryIds?.includes(topicCategory.id)))
  );

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    public store: Store<BaseAppState>,
    @Inject('applicationName') public applicationName: string
  ) {}

  getTopicById(topicId: number, topics: Topic[]) {
    return topics.find((topic: Topic) => topic.id === topicId);
  }
}
