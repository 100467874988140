import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import {
  GoToChannel,
  GoToCongressLandingPage,
  GoToCongressList,
  GoToMedicalEducationPage,
  GoToExperts,
  GoToOnDemandVideos,
  GoToOnDemandVideo,
  GoToProgramme,
  MainNavigationActionTypes,
  GoToFeaturedContent,
  GoToImprint,
  GoToDisclaimer,
  GoToCMEContent,
  GoToCMEPage,
  GoToNewsletter,
  GoToNetiquette,
  GoToUpcomingEventsPage,
  GoToTermsOfService,
  GoToSearch,
  GoToPartnerDetails,
  GoToSponsorBoothDetails,
  GoToElearning,
  GoToElearningResults,
  GoToDiseasesPage
} from '../actions/main-navigation.actions';
import { tap } from 'rxjs/operators';
import { BaseAppState } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { getThirdUrlSegment } from '../selectors/router.selectors';
import { MAIN_NAVIGATION_PATH_ELEMENTS, MAIN_NAVIGATION_PATHS } from '../definitions/main-navigation.definitions';
import { KNOWLEDGE_SERIES_SLUG } from '../../../static/knowledge-series/knowledge-series-event-tile';

@Injectable()
export class MainNavigationEffects {
  goToCongressList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToCongressList),
        tap((_action: GoToCongressList) => {
          this.router.navigate([MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement]);
        })
      ),
    { dispatch: false }
  );

  GoToMedicalEducationPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToMedicalEducationPage),
        tap((_action: GoToMedicalEducationPage) => {
          this.router.navigate([MAIN_NAVIGATION_PATH_ELEMENTS.root.medicalEducationElement]);
        })
      ),
    { dispatch: false }
  );

  goToCongressLangingPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToCongressLandingPage),
        tap((action: GoToCongressLandingPage) => {
          if (action.congressMode === 'MedEd') {
            if (action.slug.toLowerCase() === KNOWLEDGE_SERIES_SLUG.ONKOLOGIE_DER_ZUKUNFT.toLowerCase()) {
              this.router.navigate([MAIN_NAVIGATION_PATHS.root.knowledgeSeries, action.slug]);
            } else {
              this.router.navigate([MAIN_NAVIGATION_PATH_ELEMENTS.root.events, action.slug]);
            }
          } else {
            this.router.navigate([
              MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
              action.slug,
              MAIN_NAVIGATION_PATH_ELEMENTS.root.congressLandingPageElement
            ]);
          }
        })
      ),
    { dispatch: false }
  );

  goToProgramme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToProgramme),
        tap((action: GoToProgramme) => {
          this.router.navigate([
            MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
            action.slug,
            MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement
          ]);
        })
      ),
    { dispatch: false }
  );

  goToExperts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToExperts),
        tap((action: GoToExperts) => {
          this.router.navigate([
            MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
            action.slug,
            MAIN_NAVIGATION_PATH_ELEMENTS.root.expertsElement
          ]);
        })
      ),
    { dispatch: false }
  );

  goToOnDemandVideos$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToOnDemandVideos),
        tap((action: GoToOnDemandVideos) => {
          this.router.navigate(
            [
              MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
              action.congressSlug,
              MAIN_NAVIGATION_PATH_ELEMENTS.root.onDemandVideosElement
            ],
            {}
          );
        })
      ),
    { dispatch: false }
  );

  goToOnDemandVideo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToOnDemandVideo),
        tap((action: GoToOnDemandVideo) => {
          if (action.congressSlug) {
            this.router
              .navigate(
                [
                  MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
                  action.congressSlug,
                  MAIN_NAVIGATION_PATH_ELEMENTS.root.onDemandVideosElement,
                  action.onDemandVideoId
                ],
                { queryParams: { language: action.language } }
              )
              .then(() => {
                if (action.shouldReload) {
                  window.location.reload();
                }
              });
          } else {
            this.router
              .navigate([MAIN_NAVIGATION_PATH_ELEMENTS.root.onDemandVideosElement, action.onDemandVideoId], {
                queryParams: { language: action.language }
              })
              .then(() => {
                if (action.shouldReload) {
                  window.location.reload();
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  goToChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToChannel),
        concatLatestFrom(() => this.store),
        tap(([action, state]: [GoToChannel, BaseAppState]) => {
          const thirdUrlSegment = getThirdUrlSegment(state);
          this.router.navigate([
            MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
            action.congressSlug,
            action.thirdUrlSegment || thirdUrlSegment,
            MAIN_NAVIGATION_PATH_ELEMENTS.root.channelElement,
            action.channelSlug
          ]);
        })
      ),
    { dispatch: false }
  );

  goToFeaturedContent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToFeaturedContent),
        tap((action: GoToFeaturedContent) => {
          this.router.navigate([
            MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
            action.congressSlug,
            MAIN_NAVIGATION_PATH_ELEMENTS.root.featuredContentElement
          ]);
        })
      ),
    { dispatch: false }
  );

  goToCMEContent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToCMEContent),
        tap((action: GoToCMEContent) => {
          this.router.navigate([
            MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
            action.congressSlug,
            MAIN_NAVIGATION_PATH_ELEMENTS.root.cmeElement
          ]);
        })
      ),
    { dispatch: false }
  );

  goToGlobalCMEPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToCMEPage),
        tap((_action: GoToCMEPage) => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.cmeInfoPage]);
        })
      ),
    { dispatch: false }
  );

  goToUpcomingEventsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToUpcomingEventsPage),
        tap((_action: GoToUpcomingEventsPage) => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.upcomingEvents]);
        })
      ),
    { dispatch: false }
  );

  goToImprint$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToImprint),
        tap((_action: GoToImprint) => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.imprint]);
        })
      ),
    { dispatch: false }
  );

  goToDisclaimer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToDisclaimer),
        tap((_action: GoToDisclaimer) => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.disclaimer]);
        })
      ),
    { dispatch: false }
  );

  GoToNetiquette$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToNetiquette),
        tap((_action: GoToNetiquette) => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.netiquette]);
        })
      ),
    { dispatch: false }
  );

  goToAboutUs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToAboutUs),
        tap((_action: GoToDisclaimer) => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.aboutUs]);
        })
      ),
    { dispatch: false }
  );

  goToNewsletter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToNewsletter),
        tap((_action: GoToNewsletter) => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.newsletter], {
            queryParams: {
              emailAddress: _action.emailAddress
            }
          });
        })
      ),
    { dispatch: false }
  );

  goToTermsOfService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToTermsOfService),
        tap((_action: GoToTermsOfService) => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.termsOfService]);
        })
      ),
    { dispatch: false }
  );

  goToSearch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToSearch),
        tap((_action: GoToSearch) => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.search]);
        })
      ),
    { dispatch: false }
  );

  goToPartner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToPartnerDetails),
        tap((action: GoToPartnerDetails) => {
          this.router.navigate([MAIN_NAVIGATION_PATH_ELEMENTS.root.partner, action.partnerSlug]);
        })
      ),
    { dispatch: false }
  );

  goToSponsorBooth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToSponsorBoothDetails),
        tap((action: GoToSponsorBoothDetails) => {
          this.router.navigate([
            MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
            action.congressSlug,
            MAIN_NAVIGATION_PATH_ELEMENTS.root.sponsor,
            action.sponsorSlug
          ]);
        })
      ),
    { dispatch: false }
  );

  goToElearning$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToElearning),
        tap((action: GoToElearning) => {
          this.router.navigate(
            [
              MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
              action.congressSlug,
              MAIN_NAVIGATION_PATH_ELEMENTS.root.eLearning,
              action.vodId
            ],
            {
              skipLocationChange: true
            }
          );
        })
      ),
    { dispatch: false }
  );

  goToElearningResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToElearningResults),
        tap((action: GoToElearningResults) => {
          this.router.navigate(
            [
              MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
              action.congressSlug,
              MAIN_NAVIGATION_PATH_ELEMENTS.root.eLearning,
              action.vodId,
              'results'
            ],
            { skipLocationChange: true }
          );
        })
      ),
    { dispatch: false }
  );

  goToMyCmePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToMyCme),
        tap(() => {
          this.router.navigate([MAIN_NAVIGATION_PATHS.root.myCme]);
        })
      ),
    { dispatch: false }
  );

  goToDiseasesPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToDiseasesPage),
        tap((action: GoToDiseasesPage) => {
          this.router.navigate([MAIN_NAVIGATION_PATH_ELEMENTS.root.diseases, action.topicCategorySlug], {
            queryParams: { topic: action.topicId }
          });
        })
      ),
    { dispatch: false }
  );

  GoToBasicStudyKnowledgePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToBasicStudyKnowledgePage),
        tap(() => {
          this.router.navigate([MAIN_NAVIGATION_PATH_ELEMENTS.root.basicStudyKnowledge]);
        })
      ),
    { dispatch: false }
  );

  GoToPhase3Studies$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActionTypes.GoToPhase3Studies),
        tap(() => {
          this.router.navigate([MAIN_NAVIGATION_PATH_ELEMENTS.root.phase3Studies]);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<BaseAppState>, private router: Router) {}
}
