import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getUserAttributes } from '../../../../../../todaylib/core/auth/selectors/auth.selectors';
import { BaseAppState } from '../../../../../../todaylib/core/store/reducers';
import { ObservableComponent } from '../../../../../../todaylib/shared/components/observable/observable.component';
import {
  LoadUpcomingEventsCountries,
  LoadUpcomingEventsDates,
  LoadUpcomingEventsPageData,
  LoadUpcomingEventsTopicCategories
} from '../../../../../../todaylib/core/data/actions/upcoming-events-api.actions';
import {
  getUpcomingEventsCountries,
  getUpcomingEventsData,
  getUpcomingEventsDates,
  getUpcomingEventsTopicCategories
} from '../../../../../../todaylib/core/data/selectors/data-api.selectors';

@Component({
  selector: 'app-upcoming-events-page',
  templateUrl: './upcoming-events-page.component.html',
  styleUrls: ['./upcoming-events-page.component.scss']
})
export class UpcomingEventsPageComponent extends ObservableComponent implements OnInit {
  showFilterFormMobile = false;
  numberOfEventsShown = 5;

  upcomingEventsPageData$ = this.store.select(getUpcomingEventsData);
  upcomingEventsTopicCategories$ = this.store.select(getUpcomingEventsTopicCategories);
  upcomingEventsDates$ = this.store.select(getUpcomingEventsDates);
  upcomingEventsCountries$ = this.store.select(getUpcomingEventsCountries);
  userAttributes$ = this.store.select(getUserAttributes);

  filterForm: FormGroup = new FormGroup({
    selectedTopicCategory: new FormControl(),
    selectedDate: new FormGroup({
      year: new FormControl(),
      month: new FormControl()
    }),
    selectedCountry: new FormControl()
  });

  constructor(protected store: Store<BaseAppState>, private translationService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.initializeData();
  }

  private initializeData(): void {
    this.store.dispatch(
      new LoadUpcomingEventsPageData(1, null, null, null, this.translationService.currentLang, this.numberOfEventsShown)
    );
    this.store.dispatch(new LoadUpcomingEventsTopicCategories());
    this.store.dispatch(new LoadUpcomingEventsDates());
    this.store.dispatch(new LoadUpcomingEventsCountries());
    this.filterForm.get('selectedCountry')?.setValue(this.translationService.currentLang);
  }

  public handleResetFilter(filterName: string) {
    this.filterForm.get(filterName)?.reset();
    this.loadEventsForFilter();
  }

  handleSelectTopicCategory(topicCategory: string) {
    this.filterForm.get('selectedTopicCategory')?.setValue(topicCategory);
    this.loadEventsForFilter();
  }

  public handleSelectDate(year: number, month: number): void {
    this.filterForm.get('selectedDate')?.get('year')?.setValue(year);
    this.filterForm.get('selectedDate')?.get('month')?.setValue(month);
    this.loadEventsForFilter();
  }

  handleSelectCountry(country: string) {
    this.filterForm.get('selectedCountry')?.setValue(country);
    this.loadEventsForFilter();
  }

  public handleLoadMoreEventsClick(): void {
    this.numberOfEventsShown += 5;
    this.loadEventsForFilter();
  }

  public loadEventsForFilter(): void {
    this.store.dispatch(
      new LoadUpcomingEventsPageData(
        1,
        this.filterForm.get('selectedTopicCategory')?.value,
        this.filterForm.get('selectedDate')?.get('year')?.value,
        this.filterForm.get('selectedDate')?.get('month')?.value,
        this.filterForm.get('selectedCountry')?.value,
        this.numberOfEventsShown
      )
    );
  }

  public getSelectedDate(): string {
    return `${this.filterForm.get('selectedDate')?.get('year')?.value} ${this.returnMonthName(
      this.filterForm.get('selectedDate')?.get('month')?.value
    )}`;
  }

  public returnMonthName(month: number): string {
    switch (month) {
      case 1:
        return this.translationService.instant('Months.January');
      case 2:
        return this.translationService.instant('Months.February');
      case 3:
        return this.translationService.instant('Months.March');
      case 4:
        return this.translationService.instant('Months.April');
      case 5:
        return this.translationService.instant('Months.May');
      case 6:
        return this.translationService.instant('Months.June');
      case 7:
        return this.translationService.instant('Months.July');
      case 8:
        return this.translationService.instant('Months.August');
      case 9:
        return this.translationService.instant('Months.September');
      case 10:
        return this.translationService.instant('Months.October');
      case 11:
        return this.translationService.instant('Months.November');
      case 12:
        return this.translationService.instant('Months.December');
      default:
        return '';
    }
  }

  public showFilterForm(): void {
    const filterFormDom = document.querySelector('.filter-form');
    const filterBtn = document.querySelector('.filter-btn');

    if (!this.showFilterFormMobile) {
      filterFormDom?.setAttribute('style', 'height: 300px; overflow: visible');
      filterBtn?.setAttribute('style', 'background: #dc2061');
      this.showFilterFormMobile = true;
    } else {
      filterFormDom?.removeAttribute('style');
      filterBtn?.removeAttribute('style');
      this.showFilterFormMobile = false;
    }
  }
}
