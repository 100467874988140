import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ResendSignUp } from 'projects/todaylib/core/auth/actions/auth-sign-up.actions';
import { AuthActionTypes, Login } from 'projects/todaylib/core/auth/actions/auth.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { LoginRequest } from 'projects/todaylib/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginFormValue } from '../login-form/login-form-value.model';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements AfterViewInit {
  userEmail: string;

  readonly asyncProcessKey = AuthActionTypes.Login;

  constructor(public store: Store<BaseAppState>, private route: ActivatedRoute) {}

  ngAfterViewInit() {
    this.removeBackdrop();
  }

  handleLoginClick(formValue: LoginFormValue): void {
    const request: LoginRequest = {
      email: formValue.emailAddress,
      password: formValue.password
    };
    this.userEmail = formValue.emailAddress;
    this.getLoginRedirectUrl().subscribe(redirectUrl => {
      this.store.dispatch(new Login(request, redirectUrl || undefined));
    });
  }

  resendSignUpCode(): void {
    this.store.dispatch(new ResendSignUp(this.userEmail));
  }

  private removeBackdrop() {
    const backdrops = document.getElementsByClassName('modal-backdrop');
    if (backdrops?.length) {
      backdrops[0].remove();
    }
  }

  private getLoginRedirectUrl(): Observable<string> {
    return this.route.queryParams.pipe(map(queryParams => queryParams.redirect_uri));
  }
}
