import moment from 'moment';

export function enumToArray(value: {}): number[] {
  return Object.keys(value)
    .filter(e => !isNaN(+e))
    .map(e => +e);
}

export function calculateTimestringForEvent(timeEntryA: Date | string, timeEntryB: Date | string) {
  const a: moment.Moment = moment(timeEntryA);
  const b: moment.Moment = moment(timeEntryB);

  if (b.isAfter(a, 'd')) {
    return `${a.format('DD.MM.')} - ${b.format('DD.MM.yyyy')}`;
  } else {
    return `${a.format('DD.MM.yyyy')} | ${a.format('HH.mm')} - ${b.format('HH.mm')}`;
  }
}

export function shouldShowHourSuffix(dateA: Date | string, dateB: Date | string) {
  if (moment(dateB).isAfter(moment(dateA), 'd')) {
    return false;
  }
  return true;
}
