<div
  id="carousel"
  #carousel
  class="carousel slide show-neighbors mt-5 mt-md-0 carousel-fade"
  data-touch="true"
  data-ride="carousel"
>
  <div class="carousel-inner" [class.single]="items.length === 1">
    <ng-container *ngFor="let item of items; let i = index">
      <div class="carousel-item" #carouselItem [class.active]="i === 0" (click)="handleGoToCongressClick(item)">
        <div class="item__third">
          <img [src]="s3BucketUrl + item.image" class="d-block" [alt]="item.name" />
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- DESKTOP CONTROLS -->
<div class="carousel-controls" *ngIf="currentCarouselItem$ | async as currentCarouselItem">
  <div class="slide-info">
    <h4>
      <a (click)="handleGoToCongressClick(currentCarouselItem)">{{ currentCarouselItem.name }}</a>
    </h4>
  </div>
  <div class="controls d-none d-md-flex" *ngIf="items.length > 1">
    <button class="carousel-control-prev" type="button" data-target="#carousel" data-slide="prev">
      <i class="icon-16" inlineSVG="assets/icons/arrow-left.svg"></i>
      <span class="sr-only">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-target="#carousel" data-slide="next">
      <i class="icon-16" inlineSVG="assets/icons/arrow-right.svg"></i>
      <span class="sr-only">Next</span>
    </button>
  </div>
</div>

<!-- MOBILE CONTROLS -->
<ng-container *ngIf="currentCarouselItem$ | async as currentCarouselItem">
  <ol class="carousel-indicators" data-target="#carousel" *ngIf="items?.length > 1">
    <li
      *ngFor="let item of items; let i = index"
      data-target="#carousel"
      [attr.data-slide-to]="i"
      [class.active]="currentCarouselItem.name === item.name"
    ></li>
  </ol>
</ng-container>
