import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DeleteUser } from 'projects/todaylib/core/auth/actions/auth.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteUserModalComponent {
  constructor(private store: Store<BaseAppState>) {}

  public handleDeleteUser(): void {
    this.store.dispatch(new DeleteUser());
  }
}
