import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PartnersDataState } from '../reducers/partners.reducer';
import { PARTNERS_STATE_KEY } from '../definitions/partners.definitions';
import { Topic, PartnerListItem, Partner, SponsorBooth, PartnerBox } from 'medtoday-models-library';

export namespace PartnersSelectors {
  export const selectState = createFeatureSelector<PartnersDataState>(PARTNERS_STATE_KEY);

  export const selectPartners: MemoizedSelector<object, PartnerListItem[] | undefined> = createSelector(
    selectState,
    state => state.partners
  );

  export const selectTopics: MemoizedSelector<object, Topic[] | undefined> = createSelector(
    selectState,
    state => state.topics
  );

  export const selectPartnerBox: MemoizedSelector<object, PartnerBox | null> = createSelector(
    selectState,
    state => state.partnerBox
  );

  export const selectPartnerDetails: MemoizedSelector<object, Partner | null> = createSelector(
    selectState,
    state => state.partnerDetails
  );

  export const selectSponsorBoothDetails: MemoizedSelector<object, SponsorBooth | null> = createSelector(
    selectState,
    state => state.sponsorBoothDetails
  );
}
