export function getVideoDurationAsString(duration: number) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor(duration % 3600 / 60);
  const seconds = duration % 60;
  return `${padTo2(hours)}:${padTo2(minutes)}:${padTo2(seconds)}`;
}

export function padTo2(value: number) {
  if (!value) {
    return '00';
  }
  return value < 10 ? String(value).padStart(2, '0') : value;
}
