<div class="container" *ngIf="answersResults$ | async as answersResults">
  <app-spacer class="d-block header-spacer"></app-spacer>
  <div class="row justify-content-center align-items-center">
    <div class="col-md-7">
      <a class="btn ghost-button-light mb-5" (click)="handleBackToVideo()">{{
        'CmeLearning.BackToVideo' | translate
      }}</a>
      <h5 class="mb-3" *ngIf="onDemandVideoData$ | async as onDemandVideoData">{{ onDemandVideoData.title }}</h5>
      <h1 class="mb-3">
        {{
          answersResults.passed
            ? ('CmeLearning.ResultTitle' | translate)
            : ('CmeLearning.FailedResultTitle' | translate)
        }}
      </h1>
      <p class="font-weight-normal">
        {{
          answersResults.passed
            ? ('CmeLearning.SuccessResultDesc'
              | translate
                : {
                    correctAnswers: (correctAnswersCount$ | async),
                    questionsCount: answersResults.questionAnswers.length
                  })
            : ('CmeLearning.FailResultDesc'
              | translate
                : {
                    correctAnswers: (correctAnswersCount$ | async),
                    questionsCount: answersResults.questionAnswers.length
                  })
        }}
      </p>
      <a
        target="_blank"
        [href]="answersResults.certificatePdfPath"
        class="btn btn-danger"
        *ngIf="answersResults.passed"
      >
        <span>{{ 'CmeLearning.Certificate' | translate }}</span>
      </a>
      <section id="answers-results" class="mt-5">
        <div>
          <div class="row no-gutters mb-2">
            <div class="col col-10 text-note">
              {{ 'CmeLearning.Question' | translate }}
            </div>
            <div class="col col-2 text-note text-right">
              {{ 'CmeLearning.Result' | translate }}
            </div>
          </div>
          <div
            class="row mb-2 no-gutters pt-3 border-top question-answer"
            *ngFor="let questionAnswer of answersResults.questionAnswers; let index = index"
          >
            <div class="col col-10">
              <div class="row no-gutters">
                <div>{{ index + 1 }}.</div>
                <div class="col pl-2">
                  <span>{{ questionAnswer.question }}</span>
                  <div class="explanation" *ngIf="questionAnswer.explanation">
                    <a
                      class="text-note text-decoration-underline mt-2 mb-1 w-100 collapsed"
                      data-toggle="collapse"
                      href="#q{{ questionAnswer.uuid }}"
                      role="button"
                      aria-expanded="false"
                      aria-controls="{{ questionAnswer.uuid }}"
                    >
                      <span class="explanation-expand">{{ 'CmeLearning.QuestionExplanation' | translate }}</span>
                      <span class="explanation-collapse">{{
                        'CmeLearning.QuestionExplanationCollapse' | translate
                      }}</span>
                    </a>
                    <div class="collapse" id="q{{ questionAnswer.uuid }}">
                      <span class="text-note w-100">
                        {{ questionAnswer.explanation }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-2">
              <i class="bi bi-check-circle correct" *ngIf="questionAnswer.isCorrect"></i>
              <i class="bi bi-x-circle incorrect" *ngIf="!questionAnswer.isCorrect"></i>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <app-spacer></app-spacer>
</div>
<app-async-state-overlay
  (dismiss)="handleBackToVideo()"
  [processKey]="submitElearningAnswersAsyncKey"
></app-async-state-overlay>
