import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { FileDownloadApiEffects } from './effects/file-download-api.effects';
import { FileDownloadDataApiService } from './services/file-download-api.service';
import { AsyncStateOverlayModule } from 'projects/todaylib/core/async-state/async-state.module';
import { CommonModule } from '@angular/common';
import { DownloadFileComponent } from './components/download-file/download-file.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule, AsyncStateOverlayModule, EffectsModule.forFeature([FileDownloadApiEffects])],
  declarations: [DownloadFileComponent],
  exports: [DownloadFileComponent],
  providers: [
    {
      provide: FileDownloadDataApiService,
      useClass: FileDownloadDataApiService
    }
  ]
})
export class FileDownloadModule {}
