<div class="modal fade px-3" id="eventInfoModal" tabindex="-1" aria-labelledby="eventInfoModalLabel" aria-hidden="true">
  <div
    class="modal-dialog modal-dialog-centered modal-xl rounded"
    *ngIf="selectedUpcomingEvent$ | async as selectedUpcomingEvent"
  >
    <div class="modal-content bg-white">
      <div class="row no-gutters">
        <div class="col-12 col-md-6">
          <div
            class="badge p-2 pb-3 m-2 m-md-3"
            [appTopicCategoryColor]
            [topicCategoryName]="selectedUpcomingEvent.topicCategory[0]"
            *ngIf="selectedUpcomingEvent.topicCategory[0]"
          >
            {{ selectedUpcomingEvent.topicCategory[0] }}
          </div>
          <div>
            <img class="event-thumbnail" [src]="selectedUpcomingEvent.thumbnail" [alt]="selectedUpcomingEvent.title" />
            <span class="event-date">
              {{
                selectedUpcomingEvent.congressMode === Mode.VoD
                  ? 'ab' + (selectedUpcomingEvent.start | date)
                  : (selectedUpcomingEvent.start | date : 'dd.MM') +
                    ' - ' +
                    (selectedUpcomingEvent.end | date : 'dd.MM.yyyy')
              }}
            </span>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <button type="button" class="close m-2 mr-1 d-none d-md-block" data-dismiss="modal" aria-label="Close">
            <i class="bi bi-x"></i>
          </button>
          <div class="container p-3 p-md-5 w-100 mt-0 mt-md-3">
            <div>
              <img src="assets/images/calendar.png" />
            </div>
            <div class="ml-3">
              <h4>{{ selectedUpcomingEvent.upcomingPopupHeadline }}</h4>
              <p>{{ selectedUpcomingEvent.upcomingPopupSubline }}</p>
              <button class="btn w-auto btn-calendar" (click)="handleAddToCalendarClick(selectedUpcomingEvent)">
                {{ 'Event.AddToCalendar' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
