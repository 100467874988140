import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TokenValidationService } from 'projects/todaylib/core/auth/services/token-validation.service';
import { LoadProgrammeData } from 'projects/todaylib/core/data/actions/data-api.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { ExpertsPipe } from 'projects/todaylib/shared/pipes/experts.pipe';
import { DynamicScriptLoaderService } from 'projects/todaylib/shared/services/dynamic-script-loader-service.service';
import { EventRegistrationService } from 'projects/todaylib/shared/services/event-registration.service';
import { CongressUtils } from 'projects/todaylib/shared/utilities/congress.utils';
import { SESSION_UTILS_TOKEN } from 'projects/todaylib/shared/utilities/session.utils';
import { delay, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { HomePageComponent } from '../../../../../../todaylib/shared';
import { LoadCongressParticipantData } from '../../../medtoday-store/actions/medtoday-store.actions';

@Component({
  selector: 'congress-home-page',
  templateUrl: '../../../../../../todaylib/shared/pages/components/home-page/home-page.component.html',
  styleUrls: [
    '../../../../../../todaylib/shared/pages/components/home-page/home-page.component.scss',
    './medtoday-home-page.scss'
  ],
  providers: [EventRegistrationService, CongressUtils, TokenValidationService]
})
export class MedTodayHomePageComponent extends HomePageComponent implements OnInit, AfterViewInit {
  displayLogoInCircle = true;

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string,
    translationService: TranslateService,
    protected store: Store<BaseAppState>,
    protected dynamicScriptLoader: DynamicScriptLoaderService,
    expertsPipe: ExpertsPipe,
    @Inject(SESSION_UTILS_TOKEN) public sessionUtils,
    public congressUtils: CongressUtils,
    public registrationService: EventRegistrationService,
    private tokenValidationService: TokenValidationService
  ) {
    super(
      s3BucketUrl,
      applicationName,
      translationService,
      store,
      dynamicScriptLoader,
      expertsPipe,
      sessionUtils,
      congressUtils
    );
  }

  ngOnInit() {
    this.observeCongressSlug();

    this.loadDataForCongress();
    this.createCarouselViewItems();
    this.checkLiveSession();
    this.registrationService.checkForRegistration();
    this.registrationService.checkUserCongressData();
    this.tokenValidationService.observeToken();

    this.registrationService.forceAuthRefresh().subscribe(response => {
      if (response[0].data) {
        this.registrationService.getUserData();
        this.loadDataForCongress();
        this.createCarouselViewItems();
        this.registrationService.checkUserCongressData();
      }
    });
  }

  ngAfterViewInit() {
    this.congressLandingPageData$.pipe(delay(500), takeUntil(this.ngDestroy$)).subscribe(() => {
      this.dynamicScriptLoader.loadScript(`https://widget.tagembed.com/embed.min.js`);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  observeCongressSlug() {
    this.congressSlug$
      .pipe(
        distinctUntilChanged(),
        filter((slug: string) => !!slug),
        takeUntil(this.ngDestroy$)
      )
      .subscribe((slug: string) => {
        this.congressSlug = slug;
        this.store.dispatch(new LoadProgrammeData(slug));
        if (this.registrationService.user) {
          this.store.dispatch(new LoadCongressParticipantData(slug, this.registrationService.user.sub));
        }
      });
  }
}
