import { AsyncAction } from '../../async-state/models/async-action.model';
import { ApiResponse, RequestPasswordResetRequest, ResetPasswordRequest, UpdatePasswordRequest } from '../../../shared/models';

export enum PasswordActionTypes {
  EditPassword = '[Password] Edit password',
  EditPasswordSuccess = '[Password] Edit password success',
  EditPasswordFail = '[Password] Edit password failure',
  RequestPasswordReset = '[Password] Request password reset',
  RequestPasswordResetSuccess = '[Password] Request password reset success',
  RequestPasswordResetFail = '[Password] Request password reset failure',
  ResetPassword = '[Password] Reset password',
  ResetPasswordSuccess = '[Password] Reset password success',
  ResetPasswordFail = '[Password] Reset password failure',
}

export class EditPassword implements AsyncAction {
  readonly type = PasswordActionTypes.EditPassword;
  readonly asyncKey = PasswordActionTypes.EditPassword;
  readonly asyncState = 'start';

  constructor(public payload: UpdatePasswordRequest) {}
}

export class EditPasswordSuccess implements AsyncAction {
  readonly type = PasswordActionTypes.EditPasswordSuccess;
  readonly asyncKey = PasswordActionTypes.EditPassword;
  readonly asyncState = 'success';

  constructor(public response: ApiResponse<string>) {}
}

export class EditPasswordFail implements AsyncAction {
  readonly type = PasswordActionTypes.EditPasswordFail;
  readonly asyncKey = PasswordActionTypes.EditPassword;
  readonly asyncState = 'fail';
}

export class RequestPasswordReset implements AsyncAction {
  readonly type = PasswordActionTypes.RequestPasswordReset;
  readonly asyncKey = PasswordActionTypes.RequestPasswordReset;
  readonly asyncState = 'start';

  constructor(public payload: RequestPasswordResetRequest) {}
}

export class RequestPasswordResetSuccess implements AsyncAction {
  readonly type = PasswordActionTypes.RequestPasswordResetSuccess;
  readonly asyncKey = PasswordActionTypes.RequestPasswordReset;
  readonly asyncState = 'success';

  constructor(public response: ApiResponse<string>) {}
}

export class RequestPasswordResetFail implements AsyncAction {
  readonly type = PasswordActionTypes.RequestPasswordResetFail;
  readonly asyncKey = PasswordActionTypes.RequestPasswordReset;
  readonly asyncState = 'fail';
}

export class ResetPassword implements AsyncAction {
  readonly type = PasswordActionTypes.ResetPassword;
  readonly asyncKey = PasswordActionTypes.ResetPassword;
  readonly asyncState = 'start';

  constructor(public payload: ResetPasswordRequest) {}
}

export class ResetPasswordSuccess implements AsyncAction {
  readonly type = PasswordActionTypes.ResetPasswordSuccess;
  readonly asyncKey = PasswordActionTypes.ResetPassword;
  readonly asyncState = 'success';

  constructor(public response: ApiResponse<string>) {}
}

export class ResetPasswordFail implements AsyncAction {
  readonly type = PasswordActionTypes.ResetPasswordFail;
  readonly asyncKey = PasswordActionTypes.ResetPassword;
  readonly asyncState = 'fail';
}

export type PasswordActions =
  | EditPassword
  | EditPasswordSuccess
  | EditPasswordFail
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFail
;
