import { Inject, Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import {
  ConsoleLogger,
  DefaultMessagingSession,
  LogLevel,
  Message,
  MessagingSession,
  MessagingSessionConfiguration,
  MessagingSessionObserver
} from 'amazon-chime-sdk-js';
import { AuthService, AwsMember } from '../../auth/services/auth.service';
import { PromiseResult } from 'aws-sdk/lib/request';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'projects/medtoday/src/environments/environment';
import { v4 as uuid } from 'uuid';

import Chime from 'aws-sdk/clients/chime';
import AWS from 'aws-sdk/global';

@Injectable()
export class AwsMessagingService {
  messageObserver$: BehaviorSubject<Message | undefined> = new BehaviorSubject(undefined);
  sessionObserver: MessagingSessionObserver;
  session: MessagingSession;
  chime: Chime;
  // tslint:disable-next-line: no-any
  error$: BehaviorSubject<any> = new BehaviorSubject(null);
  appInstanceUserArnHeader = 'x-amz-chime-bearer';

  constructor(private authService: AuthService, @Inject('cognitoConfig') private cognitoConfig) {}

  // tslint:disable-next-line: no-any
  async connect(userId: string, awsCredentials: any): Promise<void> {
    if (!this.chime) {
      await this.initializeChimeClient();
    }

    AWS.config.region = this.cognitoConfig;
    AWS.config.credentials = awsCredentials;
    this.startMessagingSession(userId);
  }

  async initializeChimeClient(): Promise<void> {
    const creds = await Auth.currentCredentials();
    this.chime = new Chime({
      region: 'us-east-1',
      credentials: Auth.essentialCredentials(creds)
    });
  }

  async startMessagingSession(userId: string): Promise<void> {
    this.getMessagingSessionEndpoint(userId)
      .then(async response => {
        this.session = this.initializeSession(userId, response);
        this.sessionObserver = {
          messagingSessionDidReceiveMessage: message => {
            this.messageObserver$.next(message);
          }
        };

        this.session.addObserver(this.sessionObserver);
        this.session.start();
      })
      .catch(err => {
        this.error$.next(err.message);
        console.log(err.message);
        throw err;
      });
  }

  stopMessagingSession() {
    if (this.session) {
      this.messageObserver$.next(undefined);
      this.session.removeObserver(this.sessionObserver);
      this.session.stop();
    }
  }

  initializeSession(userId: string, res: Chime.GetMessagingSessionEndpointResponse): DefaultMessagingSession {
    const memberArn = this.createMemberArn(userId);
    const sessionId = uuid();
    const endpoint = res.Endpoint?.Url;
    const logger = new ConsoleLogger('SDK_Chat_Demo', environment.production ? LogLevel.OFF : LogLevel.INFO);

    const sessionConfig = new MessagingSessionConfiguration(memberArn, sessionId, endpoint!, this.chime);

    return new DefaultMessagingSession(sessionConfig, logger);
  }

  async getMessagingSessionEndpoint(
    userId: string
  ): Promise<PromiseResult<Chime.GetMessagingSessionEndpointResponse, AWS.AWSError>> {
    const request = this.chime.getMessagingSessionEndpoint();
    await this.prepareChimeRequest(request, userId);
    return await request.promise();
  }

  createMemberArn(userId: string): string {
    return `${this.cognitoConfig.appInstanceArn}/user/${userId}`;
  }

  async listChannels(userId: string): Promise<Chime.ChannelSummaryList> {
    const params: Chime.ListChannelsRequest = {
      AppInstanceArn: this.cognitoConfig.appInstanceArn
    };

    const request = this.chime.listChannels(params);
    await this.prepareChimeRequest(request, userId);
    const response = await request.promise();
    const channels = response.Channels;

    return channels!;
  }

  async listChannelsForMember(userId: string): Promise<Chime.ChannelMembershipForAppInstanceUserSummaryList> {
    const request = this.chime.listChannelMembershipsForAppInstanceUser();
    await this.prepareChimeRequest(request, userId);
    const response = await request.promise();
    const channels = response.ChannelMemberships;

    return channels!;
  }

  async sendMessage(
    channelArn: string,
    message: string,
    user: AwsMember,
    options = null
  ): Promise<{
    response: PromiseResult<Chime.SendChannelMessageResponse, AWS.AWSError>;
    CreatedTimestamp: Date;
    Sender: { Arn: string; Name: string };
  }> {
    const params: Chime.SendChannelMessageRequest = {
      ChannelArn: channelArn,
      ClientRequestToken: uuid(),
      Content: message,
      Persistence: 'PERSISTENT',
      Type: 'STANDARD'
    };

    if (options && options?.['Metadata']) {
      params.Metadata = options?.['Metadata'];
    }

    const request = this.chime.sendChannelMessage(params);
    await this.prepareChimeRequest(request, user.chimeUserId);
    const response = await request.promise();
    return {
      response,
      CreatedTimestamp: new Date(),
      Sender: { Arn: this.createMemberArn(user.chimeUserId), Name: `${user.username}` }
    };
  }

  async listChannelMessages(channelArn: string, userId: string): Promise<Chime.ChannelMessageSummaryList> {
    const params: Chime.ListChannelMessagesRequest = {
      ChannelArn: channelArn,
      SortOrder: 'DESCENDING'
    };

    const request = this.chime.listChannelMessages(params);
    await this.prepareChimeRequest(request, userId);
    const response = await request.promise();
    return response.ChannelMessages!;
  }

  async getMessage(userId: string, messageId: string, channelArn: string) {
    const params: Chime.GetChannelMessageRequest = {
      ChannelArn: channelArn,
      MessageId: messageId
    };

    const request = this.chime.getChannelMessage(params);
    await this.prepareChimeRequest(request, userId);
    const response = await request.promise();

    return response.ChannelMessage!;
  }

  async joinChannel(channelArn: string, userId: string): Promise<Chime.CreateChannelMembershipResponse> {
    const memberArn = this.createMemberArn(userId);

    const params: Chime.CreateChannelMembershipRequest = {
      ChannelArn: channelArn,
      MemberArn: memberArn,
      Type: 'DEFAULT'
    };

    const request = this.chime.createChannelMembership(params);
    await this.prepareChimeRequest(request, userId);

    return await request.promise();
  }

  private async prepareChimeRequest<D, E>(request: AWS.Request<D, E>, userId: string): Promise<AWS.Request<D, E>> {
    await this.refreshClientCredetialsIfNeeded();
    request.on('build', () => {
      request.httpRequest.headers[this.appInstanceUserArnHeader] = this.createMemberArn(userId);
    });
    return request;
  }

  private async refreshClientCredetialsIfNeeded(): Promise<void> {
    if (!this.chime) {
      return;
    }

    try {
      await this.authService.refreshAuthIfNeeded();
    } catch (error) {
      this.error$.next(error);
      console.error(`An error occurred while refreshing client credentials`, error);
    }

    this.chime.config.credentials = Auth.essentialCredentials(await Auth.currentCredentials());
  }
}
