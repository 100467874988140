<ng-container *ngIf="congressTopics$ | async as congressTopics">
  <ng-container *ngIf="congressTopics.length">
    <div class="congress-topic-list custom-scrollbar">
      <div class="d-flex">
        <div class="congress-topic-inner">
          <a class="btn mb-4 d-flex w-100" role="button" (click)="navBarService.showMobileTopicChildren(false)">
            <i class="icon-nav mr-3" [inlineSVG]="'assets/icons/arrow-left.svg'"></i>
            <span>
              {{ 'NavBar.Back' | translate }}
            </span>
          </a>
          <p class="text-color">{{ 'EditUser.AreaOfExpertise' | translate }}</p>
          <div class="congress-topic">
            <a
              class="py-1 w-100 d-flex justify-content-between align-items-center"
              (click)="handleTopicCategoryClick()"
            >
              <span
                class="circle mr-md-n2"
                *ngIf="showTopicIndicator"
                appTopicCategoryColor
                [topicCategoryName]="'Special Events'"
                [inverseColors]="true"
              ></span>
              <span class="mr-auto">Special Events</span>
              <i class="icon-nav" [inlineSVG]="'assets/icons/arrow-right.svg'"></i>
            </a>
          </div>
          <div class="divider my-2 w-100"></div>
          <div *ngFor="let congressTopic of congressTopics">
            <a
              class="py-1 text-color w-100 d-flex justify-content-between align-items-center"
              (click)="handleTopicCategoryClick(congressTopic)"
            >
              <span
                class="circle mr-2"
                *ngIf="showTopicIndicator"
                appTopicCategoryColor
                [topicCategoryName]="congressTopic.topicCategoryName"
                [inverseColors]="true"
              ></span>
              <span class="mr-auto">{{ congressTopic.topicCategoryName }}</span>
              <i class="icon-nav" [inlineSVG]="'assets/icons/arrow-right.svg'"></i>
            </a>
            <div class="divider my-2 w-100"></div>
          </div>
        </div>
        <div class="congress-topic-inner">
          <a class="btn hide-desktop w-100 mb-4" role="button" (click)="navBarService.showTopicChildren(false)">
            <i class="icon-nav mr-3" [inlineSVG]="'assets/icons/arrow-left.svg'"></i>
            <span>{{ 'NavBar.Back' | translate }}</span>
          </a>
          <div class="d-flex align-items-center">
            <p *ngIf="navBarService.selectedTopic" class="mb-0 text-danger">
              <span
                class="circle"
                *ngIf="showTopicIndicator"
                appTopicCategoryColor
                [topicCategoryName]="navBarService.selectedTopic"
                [inverseColors]="true"
              ></span>
              {{ navBarService.selectedTopic }}
            </p>
          </div>
          <div class="divider my-2 w-100"></div>
          <div class="w-100 d-grid" *ngFor="let congressForTopic of navBarService.topicCategoryChildren">
            <app-congress-dropdown-list-item
              [congressForTopic]="congressForTopic"
              [mobileView]="true"
            ></app-congress-dropdown-list-item>
            <div class="divider my-2 w-100"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
