import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-sign-up-stepper',
  templateUrl: './sign-up-stepper.component.html',
  styleUrls: ['./sign-up-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: SignUpStepperComponent }]
})
export class SignUpStepperComponent extends CdkStepper implements AfterViewInit {
  @Output() signUpClick = new EventEmitter();
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  handleSignupClick() {
    this.signUpClick.emit();
  }
}
