import moment from 'moment';
import { CongressCategory } from 'medtoday-models-library/lib/models';
import { CongressMetadata, UpcomingEventListItemModel } from 'medtoday-models-library';
import { CalendarOptions } from 'datebook';

export interface CalendarEventItem {
  link: string | null;
  title: string | undefined;
  start: Date | string | undefined | null;
  end: Date | string | undefined;
  registrationType: CongressCategory | undefined;
  location: string | undefined | null;
  city?: string;
}
export class CongressUtils {
  public isBeforeCongress(congressMetaData: CongressMetadata) {
    return moment.utc().isBefore(moment.utc(congressMetaData.startDate));
  }

  public isAfterCongress(congressMetaData: CongressMetadata) {
    return moment.utc().isAfter(moment.utc(congressMetaData.endDate));
  }

  public createCalendarEventItem(congressMetaData?: CongressMetadata, upcomingEventItem?: UpcomingEventListItemModel) {
    if (upcomingEventItem) {
      return {
        link: upcomingEventItem.link,
        title: upcomingEventItem.title,
        start: upcomingEventItem.start,
        end: upcomingEventItem.end,
        registrationType: upcomingEventItem.registrationType,
        city: upcomingEventItem.city,
        location: upcomingEventItem.city
      };
    } else {
      return {
        link: window.location.href,
        title: congressMetaData?.name,
        start: congressMetaData?.startDate,
        end: congressMetaData?.endDate,
        registrationType: congressMetaData?.congressCategory,
        location: congressMetaData?.eventAddress
      };
    }
  }

  public createCalendarEvent(calendarEventItem: CalendarEventItem): CalendarOptions {
    let eventLink = '';
    if (calendarEventItem.link) {
      eventLink = `<a href="${calendarEventItem.link}">${calendarEventItem.title}</a>`;
    }
    return {
      title: calendarEventItem.title,
      start: new Date(calendarEventItem.start as string),
      end: new Date(calendarEventItem.end as string),
      location:
        calendarEventItem.registrationType === CongressCategory.ONLINE
          ? 'ON-LINE'
          : calendarEventItem.location
          ? calendarEventItem.location.replace(/<[^>]*>/g, '')
          : calendarEventItem.city,
      description: eventLink
    };
  }
}
