<div class="container">
  <app-spacer class="d-block header-spacer"></app-spacer>
  <div class="row justify-content-center align-items-center" *ngIf="questionsAnswers$ | async as questionsAnswers">
    <div class="col-md-7">
      <a class="btn ghost-button-light mb-5" (click)="handleBackToVideo()">{{
        'CmeLearning.BackToVideo' | translate
      }}</a>
      <h1 class="mb-3">{{ 'CmeLearning.Title' | translate }}</h1>
      <p
        class="font-weight-normal"
        [innerHTML]="'CmeLearning.Description' | translate: { questionsCount: questionsAnswers.length }"
      ></p>

      <section id="question-answers">
        <div>
          <h5 class="mb-3" *ngIf="onDemandVideoData$ | async as vodData">{{ vodData.title }}</h5>
          <div class="position-relative">
            <!--      NEXT/PREV BUTTONS      -->
            <ng-container *ngIf="(activeQuestion$ | async) + 1 as activeQuestion">
              <div class="control-buttons">
                <a
                  class="btn d-md-flex carousel-control-prev-btn"
                  href="#carousel"
                  role="button"
                  data-slide="prev"
                  draggable="false"
                  *ngIf="activeQuestion"
                >
                  <span class="d-flex">{{ 'CmeLearning.Prev' | translate }}</span>
                </a>
                <a
                  class="btn d-md-flex carousel-control-next-btn ml-auto"
                  href="#carousel"
                  role="button"
                  data-slide="next"
                  *ngIf="activeQuestion < questionsAnswers.length"
                  draggable="false"
                >
                  <span class="d-flex">{{ 'CmeLearning.Next' | translate }}</span>
                </a>
                <a
                  class="btn d-md-flex carousel-control-next-btn ml-auto btn-danger"
                  *ngIf="activeQuestion === questionsAnswers.length"
                  [class.disabled]="activeQuestion === questionsAnswers.length && !qaForm.valid"
                  (click)="handleSubmitAnswers()"
                  draggable="false"
                >
                  <span class="d-flex">{{ 'CmeLearning.SendAnswers' | translate }}</span>
                </a>
              </div>
            </ng-container>
            <!--      END OF BUTTONS      -->
            <div class="question-progress p-2 text-light px-3">
              {{ 'CmeLearning.Question' | translate }} {{ (activeQuestion$ | async) + 1 }}/{{ questionsAnswers.length }}
            </div>
            <div
              class="px-4 px-md-5 py-5 question-step-container carousel"
              data-ride="false"
              data-interval="false"
              *ngIf="qaForm"
              [formGroup]="qaForm"
              id="carousel"
            >
              <div
                class="question-step carousel-item"
                *ngFor="let questionAnswers of questionsAnswers; let i = index"
                [class.active]="i === 0"
              >
                <h5 class="my-3">{{ questionAnswers.question }}</h5>
                <div class="custom-control custom-radio mb-2" *ngFor="let answer of questionAnswers.answers">
                  <input
                    class="custom-control-input mr-3"
                    type="radio"
                    [name]="questionAnswers.uuid"
                    [id]="answer.uuid"
                    [value]="answer.uuid"
                    [formControlName]="questionAnswers.uuid"
                    checked
                  />
                  <label class="custom-control-label text-light" [for]="answer.uuid">
                    {{ answer.answer }}
                  </label>
                </div>
              </div>

              <ol class="carousel-indicators mt-3">
                <li
                  *ngFor="let question of questionsAnswers; let i = index"
                  data-target="#carousel"
                  [class.active]="i === 0"
                  [attr.data-slide-to]="i"
                  [class.answered]="qaForm.controls[questionsAnswers[i].uuid].valid"
                ></li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <app-spacer class="d-block"></app-spacer>
</div>
<app-async-state-overlay
  (dismiss)="handleBackToVideo()"
  [processKey]="loadOnDemandVideoAsyncKey"
></app-async-state-overlay>
<app-async-state-overlay
  (dismiss)="handleBackToVideo()"
  [processKey]="loadElearningQuestionsAsyncKey"
></app-async-state-overlay>
<app-async-state-overlay
  (dismiss)="handleBackToVideo()"
  [processKey]="submitElearningAnswersAsyncKey"
></app-async-state-overlay>
