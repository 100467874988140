import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  getCongressSlugRouterParam,
  getSponsorBoothSlugParam
} from '../../../../../core/router/selectors/router.selectors';
import { LoadSponsorBoothDetails } from '../../actions/partners.actions';
import { PartnersSelectors } from '../../selectors/partners.selectors';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { imagePaths } from '../../../../../../medtoday/src/environments/environment';
import { PartnerDetailPageComponent } from '../partner-detail-page/partner-detail-page.component';

@Component({
  selector: 'app-sponsor-detail-page',
  templateUrl: '../partner-detail-page/partner-detail-page.component.html',
  styleUrls: ['../partner-detail-page/partner-detail-page.component.scss', './sponsor-detail-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SponsorDetailPageComponent extends PartnerDetailPageComponent implements OnInit {
  sponsorParam$ = this.store.select(getSponsorBoothSlugParam);
  congressParam$ = this.store.select(getCongressSlugRouterParam);
  partner$ = this.store.select(PartnersSelectors.selectSponsorBoothDetails);
  avatarsUrl = imagePaths.avatars;
  logosUrl = imagePaths.logos;
  pdfsUrl = imagePaths.pdfs;

  ngOnInit() {
    this.sponsorParam$
      .pipe(
        takeUntil(this.ngDestroy$),
        withLatestFrom(this.congressParam$),
        filter(([sponsorSlug, congressSlug]) => !!sponsorSlug && !!congressSlug)
      )
      .subscribe(([sponsorSlug, congressSlug]) => {
        this.store.dispatch(new LoadSponsorBoothDetails(congressSlug, sponsorSlug));
      });
  }
}
