import { AsyncErrorState } from '../../reducers/async-state.reducers';
import { isCallWithCodeRetryable } from './utils';

const DEFAULT_ERROR_TITLE = 'Error';
const DEFAULT_ERROR_MESSAGE = 'UnexpectedError';

export class ErrorConfig {
  title = '';
  messages: string[] = [];
  isRetryable = false;

  constructor(errorState: AsyncErrorState) {
    if (errorState && errorState.httpStatus && errorState.httpStatus >= 0) {
      this.isRetryable = isCallWithCodeRetryable(errorState.httpStatus);
      this.title = this.createErrorTitle(errorState.httpStatus, errorState);
      this.messages = this.createErrorMessages(errorState);
    } else {
      this.isRetryable = false;
      this.title = errorState.errorTitle || DEFAULT_ERROR_TITLE;
      this.messages = [errorState.errorText || DEFAULT_ERROR_MESSAGE];
    }
  }

  private createErrorMessages(errorState: AsyncErrorState): string[] {
    if (errorState.validationErrors && errorState.validationErrors.length > 0) {
      const messages: string[] = [];
      errorState.validationErrors.forEach(error => {
        if (!error.messages) {
          return;
        }
        error.messages.forEach((message: string) => messages.push(message));
      });
      if (messages.length > 0) {
        return messages;
      }
    }

    if (errorState.errorText) {
      return [errorState.errorText];
    }

    switch (errorState.httpStatus) {
      case 0:
        return ['Daten konnten nicht geladen werden. Bitte stelle sicher, dass eine Internetverbindung besteht.'];
      case 403:
        return ['Zugriff verweigert.'];
      case 401:
        return [];
      case 404:
        return ['Dieser Eintrag existiert nicht.'];
      case 400:
        return ['Bitte überprüfe Deine Eingaben.'];
      case 429:
        return ['Der Server ist momentan außer Betrieb. Bitte hab etwas Geduld!'];
      case 504:
        return ['Das hat zu lange gedauert. Bitte versuche es später erneut.'];
      default:
        return [DEFAULT_ERROR_MESSAGE];
    }
  }

  private createErrorTitle(code: number, errorState: AsyncErrorState): string {
    if (errorState.errorTitle) {
      return errorState.errorTitle;
    }

    switch (code) {
      case 0:
        return 'Netzwerkfehler';
      case 403:
      case 401:
        return 'Zugriff verweigert';
      case 404:
      case 400:
        return 'Ungültige Anfrage';
      case 504:
        return 'Timeout';
      default:
        return DEFAULT_ERROR_TITLE;
    }
  }
}
