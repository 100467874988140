import { CmeTrackingTrigger } from '../../../models/cme-tracking-trigger.model';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { getCMEActiveState, isLoggedIn } from '../../../../../../todaylib/core/auth/selectors/auth.selectors';
import { OpenCmeTrackingPromptModal } from '../../../medtoday-store/actions/medtoday-store.actions';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CmeTrackingTriggerStrategy } from '../cme-tracking-trigger-strategy';
import { ToggleCME } from '../../../../../../todaylib/core/layout/nav-bar/actions/nav-bar.actions';
import { LoadChannelBySlug, PurgeChannelsData } from '../../../../../../todaylib/core/data/actions/data-api.actions';
import { getChannelSlugRouterParam } from '../../../../../../todaylib/core/router/selectors/router.selectors';
import { getChannelData } from '../../../../../../todaylib/core/data/selectors/data-api.selectors';

@Injectable()
export class PromptCmeTrigger extends CmeTrackingTriggerStrategy implements CmeTrackingTrigger {
  cmeActiveState$ = this.store.select(getCMEActiveState);
  isLoggedIn$ = this.store.select(isLoggedIn);
  channelSlug$ = this.store.select(getChannelSlugRouterParam);
  channelData$ = this.store.select(getChannelData);

  triggerCmeStateIfNeeded(): Observable<any> {
    return combineLatest([this.congressSlugParam$, this.channelSlug$]).pipe(
      filter(slug => !!slug),
      take(1),
      switchMap(([congressSlug, channelSlug]) => {
        this.store.dispatch(new LoadChannelBySlug(congressSlug, channelSlug));
        return combineLatest([this.isLoggedIn$, this.cmeActiveState$, this.congressMetaData$, this.channelData$]).pipe(
          filter(([isLoggedIn, _, congressMetaData, channelData]) => isLoggedIn && !!congressMetaData && !!channelData),
          take(1),
          map(([isLogged, cmeActiveState, congressMetaData, channelData]) => {
            if (
              isLogged &&
              channelData.cmeTrackable &&
              congressMetaData.cmeData?.days?.length &&
              congressMetaData.cmeData.cmeEnabled
            ) {
              const hasActiveCmeTimeframe = this.hasActiveCmeDay(congressMetaData.cmeData);
              if (!cmeActiveState && hasActiveCmeTimeframe) {
                this.store.dispatch(new OpenCmeTrackingPromptModal());
                return true;
              } else {
                if (cmeActiveState && !hasActiveCmeTimeframe) {
                  this.store.dispatch(new ToggleCME());
                }
                return false;
              }
            }
            this.store.dispatch(new PurgeChannelsData());
          })
        );
      })
    );
  }
}
