import { Partner, PartnerListItem, SponsorBooth } from 'medtoday-models-library/lib';
import { PartnerBox, Topic } from 'medtoday-models-library/lib/models';
import { PartnersActions, PartnersActionTypes } from '../actions/partners.actions';

export interface PartnersDataState {
  partners: PartnerListItem[];
  partnerDetails: Partner | null;
  topics: Topic[];
  sponsorBoothDetails: SponsorBooth | null;
  partnerBox: PartnerBox | null;
}

const initialState: PartnersDataState = {
  partners: [],
  topics: [],
  partnerDetails: null,
  sponsorBoothDetails: null,
  partnerBox: null
};

export function reducer(state = initialState, action: PartnersActions): PartnersDataState {
  switch (action.type) {
    case PartnersActionTypes.LoadPartnersSuccess: {
      return {
        ...state,
        partners: action.response.partners,
        topics: action.response.topics,
        partnerBox: action.response.partnerBox
      };
    }
    case PartnersActionTypes.LoadPartnerDetailsSuccess: {
      return {
        ...state,
        partnerDetails: action.response
      };
    }
    case PartnersActionTypes.LoadSponsorDetailsSuccess: {
      return {
        ...state,
        sponsorBoothDetails: action.response
      };
    }
    case PartnersActionTypes.PurgeData: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
