<div
  class="modal fade px-3"
  id="expertDetails"
  tabindex="-1"
  aria-labelledby="expertDetailsModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-xl rounded">
    <div
      class="modal-content"
      [class.bg-dark]="applicationName === 'MedToday'"
      [class.bg-white]="applicationName === 'PatientsToday'"
    >
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          [class.patients-bg]="applicationName === 'PatientsToday'"
        >
          <span></span>
        </button>
      </div>
      <div class="modal-body pt-0">
        <ng-container *ngIf="selectedExpert$ | async as expert">
          <div class="container-fluid">
            <div class="row mt-3">
              <div class="px-0 px-lg-5 pb-5 col-lg-5 col-12" [class.col-lg-12]="!expert.disclosure">
                <ng-container *ngIf="expert.expertType">
                  <ng-container *ngIf="applicationName === 'MedToday'"
                    ><h4>{{ 'ExpertsPage.' + expert.expertType | translate }}</h4></ng-container
                  >
                  <ng-container *ngIf="applicationName === 'PatientsToday'"
                    ><h4>{{ 'ExpertsPage.Patients.' + expert.expertType | translate }}</h4></ng-container
                  >
                  <div class="divider w-100 mb-3"></div>
                </ng-container>
                <div class="d-flex pb-3 profile-image">
                  <ng-container *ngIf="expert.profileImage">
                    <img
                      draggable="false"
                      class="rounded-circle"
                      [src]="s3BucketUrl + avatarsImageFolderSlug + expert.profileImage"
                    />
                  </ng-container>
                </div>
                <p class="font-size-small mb-1">{{ expert | expert }}</p>
                <ng-container *ngIf="showTopics">
                  <ng-container *ngIf="expertsData$ | async as expertData">
                    <ng-container *ngIf="expert.topicIds?.length">
                      <div class="d-flex flex-wrap mb-1">
                        <span *ngFor="let topicId of expert.topicIds" class="expertise-tag mr-2 mb-1 p-1 px-2">{{
                          getTopicById(topicId, expertData.topics)?.title
                        }}</span>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="showTopicCategories && expert.topicCategoryIds?.length && topicCategories?.length">
                  <div class="d-flex flex-wrap mb-1 mt-2">
                    <span
                      *ngFor="let topicCategory of expertTopicCategories | async"
                      [appTopicCategoryColor]="topicCategory.slug"
                      class="expertise-tag mr-2 mb-1 p-1 px-2"
                    >
                      {{ topicCategory.title }}</span
                    >
                  </div>
                </ng-container>
                <p class="text-note">{{ expert.areaOfActivity }}</p>
              </div>
              <div class="px-0 px-lg-5 col-lg-7 col-12 disclosures" *ngIf="expert.disclosure">
                <h4>{{ 'ExpertsPage.Disclosures' | translate }}</h4>
                <div class="divider w-100 mb-3"></div>
                <p class="text-note white-space-prewrap text-break">{{ expert.disclosure }}</p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
