<div class="container pb-5 mt-5" *ngIf="partner$ | async as partner">
  <div class="row">
    <div class="cold-12 col-lg-6 d-felx flex-column">
      <div class="mb-32" *ngIf="partner.mediaIdentifier">
        <app-jw-player
          *ngIf="partner.playerIdentifier && partner.mediaProvider === 'JW'"
          [mediaId]="partner.mediaIdentifier"
          [playerId]="partner.playerIdentifier"
        ></app-jw-player>
        <app-vimeo-player
          *ngIf="partner.mediaProvider === 'VIMEO'"
          [mediaId]="partner.mediaIdentifier"
        ></app-vimeo-player>
        <app-youtube-player
          *ngIf="partner.mediaProvider === 'YT'"
          [mediaId]="partner.mediaIdentifier"
        ></app-youtube-player>
      </div>
      <div class="mb-32" *ngIf="!partner.mediaIdentifier && !partner.playerIdentifier && partner.image">
        <img class="w-100 rounded" [src]="s3BucketUrl + partner.image" />
      </div>
      <div class="partner-body">
        <div class="headline mb-4">
          <h1 class="mb-0">{{ partner.name }}</h1>
          <p class="mb-0 bold">{{ partner.subLine }}</p>
        </div>
        <div class="d-flex mb-32">
          <a [href]="partner.link" target="_blank" class="btn btn-danger rounded-pill mr-md-2"
            >{{ 'PartnerDetail.Homepage' | translate }}
            <i class="anchor-icon" [inlineSVG]="'assets/icons/anchor-right-up.svg'"></i
          ></a>
          <a
            *ngIf="partner.pdf"
            [href]="s3BucketUrl + pdfsUrl + partner.pdf"
            download
            target="_blank"
            class="btn ghost-button-light rounded-pill ml-2"
          >
            {{ 'PartnerDetail.InfoPdf' | translate }}
            <i class="download-icon" [inlineSVG]="'assets/icons/pdf-download.svg'"></i>
          </a>
        </div>
        <p replaceToDownloadLink [innerHTML]="partner.description"></p>
        <small class="updated"
          >{{ 'PartnerDetail.UpdatedAt' | translate }} {{ partner.updatedAt | date : 'dd-MM-YYYY' }}</small
        >
      </div>
    </div>
    <div class="cold-12 col-lg-5 d-felx flex-column offset-lg-1 right-side">
      <img class="logo mt-lg-0" [src]="s3BucketUrl + logosUrl + partner.logo" />
      <div class="contact-info">
        <div class="info-line" *ngIf="partner.address">
          <small>{{ 'PartnerDetail.Address' | translate }}</small>
          <p [innerHTML]="partner.address"></p>
        </div>
        <div class="info-line" *ngIf="partner.email">
          <small>{{ 'PartnerDetail.Email' | translate }}</small>
          <p>
            <a href="mailto: {{ partner.email }}">{{ partner.email }}</a>
          </p>
        </div>
        <div class="info-line" *ngIf="partner.telephone">
          <small>{{ 'PartnerDetail.Telephone' | translate }}</small>
          <p>
            <a href="tel: {{ partner.telephone }}">{{ partner.telephone }}</a>
          </p>
        </div>
        <div class="info-line" *ngIf="partner.consultationHours">
          <small>{{ 'PartnerDetail.Open' | translate }}</small>
          <p>{{ partner.consultationHours }}</p>
        </div>
        <div class="info-line related-persons" *ngIf="partner.relatedPersons?.length">
          <small>{{ 'PartnerDetail.People' | translate }}</small>
          <div class="d-flex align-items-start pb-4 pt-1 mt-2" *ngFor="let relatedPerson of partner.relatedPersons">
            <div class="profile-picture rounded-circle overflow-hidden mr-3" *ngIf="relatedPerson.image">
              <img [src]="s3BucketUrl + avatarsUrl + relatedPerson.image" />
            </div>
            <div class="row d-flex flex-column flex-fill">
              <div class="col-lg-8">
                <div class="bolded">{{ relatedPerson.title }} {{ relatedPerson.name }}</div>
                <div *ngIf="relatedPerson.position" class="line-height-1 mb-3">
                  <small class="text-note">{{ relatedPerson.position }}</small>
                </div>
                <span class="d-block">
                  <a href="mailto: {{ relatedPerson.email }}" *ngIf="relatedPerson.email">{{ relatedPerson.email }}</a>
                </span>
                <span class="d-block">
                  <a href="tel: {{ relatedPerson.telephone }}" *ngIf="relatedPerson.telephone">{{
                    relatedPerson.telephone
                  }}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="information" *ngIf="partner.attachments?.length">
        <p class="mb-3 bold">{{ 'PartnerDetail.Helpful' | translate }}</p>
        <ng-container *ngFor="let attachment of partner.attachments">
          <div class="mb-4" *ngIf="!attachment.isLink">
            <small>{{ 'PartnerDetail.PDFDownload' | translate }}</small>
            <div class="info-link">
              <i [inlineSVG]="'assets/icons/pdf-download.svg'"></i>
              <a download target="_blank" [href]="s3BucketUrl + pdfsUrl + attachment.url">{{ attachment.name }}</a>
            </div>
          </div>
          <div class="mb-4" *ngIf="attachment.isLink">
            <small>{{ 'PartnerDetail.Link' | translate }}</small>
            <div class="info-link">
              <i [inlineSVG]="'assets/icons/anchor-right-up.svg'"></i>
              <a [href]="attachment.url" target="_blank">{{ attachment.name }}</a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-scroll-to-top-button [ngClass]="hasScrolled() ? 'd-block' : 'd-none'"></app-scroll-to-top-button>
