import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { UpcomingEventListItemModel } from 'medtoday-models-library';
import { ObservableComponent } from '../../../../../../../todaylib/shared/components/observable/observable.component';
import { DataApiService } from 'projects/todaylib/core/data/services/data-api.service';
import { takeUntil, tap } from 'rxjs/operators';
import { CongressUtils } from 'projects/todaylib/shared/utilities/congress.utils';
import { ICalendar } from 'datebook';

@Component({
  selector: 'app-upcoming-event-card',
  templateUrl: './upcoming-event-card.component.html',
  styleUrls: ['./upcoming-event-card.component.scss'],
  providers: [CongressUtils]
})
export class UpcomingEventCardComponent extends ObservableComponent implements OnInit {
  @Input() event: UpcomingEventListItemModel;
  @Input() user;
  @Input() hideCalendarButtons = false;
  @Input() subTitle;
  isUserRegistered: boolean;
  @Input() eventSameDay = false;

  filterForm: FormGroup = new FormGroup({
    selectedTopicCategory: new FormControl(),
    selectedDate: new FormGroup({
      year: new FormControl(),
      month: new FormControl()
    }),
    selectedCountry: new FormControl()
  });

  constructor(private api: DataApiService, private congressUtils: CongressUtils) {
    super();
  }

  ngOnInit(): void {
    if (this.event.requiresRegistration && this.user) {
      this.getParticipationData();
    }
    this.getEventDates();
  }

  public getParticipationData(): void {
    this.api
      .getCongressParticipantData(this.event.congressSlug, this.user.sub)
      .pipe(
        tap(data => (this.isUserRegistered = data.isParticipant)),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  public getEventDates() {
    // if forced by input param then leave it
    if (this.eventSameDay) {
      return true;
    }

    const start = moment(this.event.start);
    const end = moment(this.event.end);
    if (start.isSame(end, 'day')) {
      this.eventSameDay = true;
    }
  }

  public setEventType(registrationType: string): string {
    switch (registrationType) {
      case 'ONLINE':
        return 'Online';
      case 'ON_SITE':
        return 'On Site';
      case 'HYBRID':
        return 'On Site & Online';
      default:
        return '';
    }
  }

  handleAddToCalendarClick() {
    const icalendar = new ICalendar(
      this.congressUtils.createCalendarEvent(this.congressUtils.createCalendarEventItem(undefined, this.event))
    );
    icalendar.download();
  }

  goToEventLocation() {
    if (this.event.congressLinkActive && this.event.link) {
      window.open(this.event.link);
    }
  }
}
