import { HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../store/reducers';
import { EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { getAuthType, getCongressToken, getToken } from '../selectors/auth.selectors';
import { AuthBaseInterceptor } from './auth-base.interceptor';

const REQUEST_HEADER_AUTHORIZATION = 'Authorization';

@Injectable()
export class AuthTokenInjectorInterceptor extends AuthBaseInterceptor {
  token$ = this.store.select(getToken);
  authType$ = this.store.select(getAuthType);
  congressToken$ = this.store.select(getCongressToken);

  constructor(@Inject('apiBaseUrl') apiBaseUrl: string, protected store: Store<BaseAppState>, router: Router) {
    super(apiBaseUrl, store, router);
  }

  // override
  public intercept(
    // tslint:disable-next-line:no-any
    request: HttpRequest<any>,
    next: HttpHandler
    // tslint:disable-next-line:no-any
  ): Observable<HttpEvent<any>> {
    return from(this.getUpdatedRequestHeaders(request)).pipe(
      switchMap(updatedHeaders => {
        if (!updatedHeaders) {
          return EMPTY;
        }

        request = request.clone({
          headers: updatedHeaders
        });
        return next.handle(request);
      }),
      catchError(err => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  // tslint:disable-next-line: no-any
  private async getUpdatedRequestHeaders(request: HttpRequest<any>): Promise<HttpHeaders | null> {
    let headers = new HttpHeaders();

    const shouldAddAuthorizationHeader = await this.needsAuthentication(request);
    const token = await this.token$.pipe(take(1)).toPromise();
    const authType = await this.authType$.pipe(take(1)).toPromise();
    const congressToken = await this.congressToken$.pipe(take(1)).toPromise();

    if (shouldAddAuthorizationHeader) {
      if (token) {
        headers = headers.append(REQUEST_HEADER_AUTHORIZATION, `Bearer ${token}`);
        headers = headers.append('authmode', `${authType}`);
      } else if (congressToken) {
        headers = headers.append(REQUEST_HEADER_AUTHORIZATION, `${congressToken}`);
        headers = headers.append('authmode', `${authType}`);
      } else {
        return null;
      }
    }

    headers = headers
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .set('If-Modified-Since', '0');
    return headers;
  }
}
