import { FilterOption } from '../models/filter-option.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CartesianProduct } from 'js-combinatorics';
import { take } from 'rxjs/operators';

export class Filter<T> {
  private filters: { [key: string]: FilterOption<any>[] } = {};
  private appliedFilters: Map<string, FilterOption<T>[]>;
  private initialData: T[];
  private data$: Subject<T[]> = new BehaviorSubject<T[]>([]);
  filteredData$: Observable<T[]> = this.data$.asObservable();

  appliedFilter(filterName: string): FilterOption<T>[] {
    return this.appliedFilters.get(filterName)!;
  }

  addFilters<K>(filterName: string, filters: FilterOption<K>[]) {
    this.filters[filterName] = filters;
    this.appliedFilters = new Map<string, FilterOption<T>[]>();
    this.filteredData$.pipe(take(1)).subscribe(data => this.updatePredictedOptionResults(data));
  }

  resetFilters() {
    this.appliedFilters = new Map<string, FilterOption<T>[]>();
    this.data$.next(this.initialData);
    this.updatePredictedOptionResults(this.initialData);
  }

  resetFilter(filterName: string) {
    if (this.appliedFilters?.has(filterName)) {
      this.appliedFilters.get(filterName)!.forEach(filter => (filter.isEnabled = false));
      this.appliedFilters.delete(filterName);
      this.filterRecords();
    }
  }

  loadFilterableRecords(data: T[]) {
    this.initialData = data;
    this.data$.next(data);
  }

  applyFilter(filterName: string, filterOption: FilterOption<T>) {
    if (!this.filters[filterName]) return;
    const option = this.filters[filterName].find(option => option.source === filterOption.source);
    const appliedFilter = this.appliedFilter(filterName) || [];

    if (option) {
      if (!option.isEnabled) {
        appliedFilter.push(option);
        this.appliedFilters.set(filterName, appliedFilter);
      } else {
        this.appliedFilters.set(
          filterName,
          appliedFilter.filter(fo => fo.id !== option.id)
        );
      }
      option.isEnabled = !option.isEnabled;
      if (!this.appliedFilter(filterName)?.length) {
        this.appliedFilters.delete(filterName);
      }
      this.filterRecords();
    }
  }

  private filterRecords() {
    const filterGroups = this.makeFilterGroups();
    let filteredRecords;

    if (filterGroups.length) {
      filteredRecords = this.initialData.filter(record => filterGroups.some(fg => fg.every(fc => fc(record))));
    } else {
      filteredRecords = this.initialData;
    }
    this.updatePredictedOptionResults(filteredRecords);
    this.data$.next(filteredRecords);
  }

  private updatePredictedOptionResults(filteredRecords: T[]) {
    const filterGroups = this.makeFilterGroups();

    for (const key in this.filters) {
      if (Array.from(this.appliedFilters.keys()).includes(key)) {
        continue;
      }

      this.filters[key].forEach(filter => {
        if (!filterGroups?.length || !filterGroups[0]?.length || Array.from(this.appliedFilters.keys()).includes(key)) {
          filter.producesRecords = true;
          return;
        }

        if (!filter.isEnabled) {
          filter.producesRecords = filteredRecords.some(record => filter.condition(record));
        }
      });
    }
  }

  private makeFilterGroups() {
    const mixedConditions = Array.from(this.appliedFilters, ([_, filterOptions]) =>
      filterOptions.map(fo => fo.condition)
    ).filter(array => !!array.length);
    return CartesianProduct.from(mixedConditions).toArray();
  }

  getSelectedFilterOptions(filterName: string): FilterOption<any>[] {
    if (this.appliedFilters && this.appliedFilters.has(filterName)) {
      return this.appliedFilters.get(filterName) as FilterOption<any>[];
    }

    return [];
  }

  getFilterOptions(filterName: string): FilterOption<T>[] {
    return this.filters[filterName];
  }

  destroy() {
    this.data$.unsubscribe();
  }
}
