import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isLoggedIn } from '../../../../../todaylib/core/auth/selectors/auth.selectors';
import { BaseAppState } from '../../../../../todaylib/core/store/reducers';
import { getCongressMetaData } from '../../../../../todaylib/core/data/selectors/data-api.selectors';
import { createNeedsAuthenticationHeader } from '../../../../../todaylib/core/data/utilities/networking.utilites';

@Injectable({ providedIn: 'root' })
export class CMETrackerService {
  private timeStamps: string[] = [];

  isLoggedIn$ = this.store.select(isLoggedIn);
  congressMetaData$ = this.store.select(getCongressMetaData);

  constructor(
    @Inject('apiBaseUrl') protected apiBaseUrl: string,
    protected http: HttpClient,
    private store: Store<BaseAppState>
  ) {}

  // tslint:disable-next-line: no-any
  handleCMEResponse() {
    this.timeStamps = [];
    localStorage.setItem('medtoday_cme_timestamps', JSON.stringify(this.timeStamps));
  }

  handleCMEFailure() {
    localStorage.setItem('medtoday_cme_timestamps', JSON.stringify(this.timeStamps));
  }

  trackData(congressSlug: string, timepoints: string[]) {
    const headers = createNeedsAuthenticationHeader();

    // tslint:disable-next-line: no-any
    return this.http.post<any>(
      `${this.apiBaseUrl}/congress/${congressSlug}/cme/time-points`,
      { timePoints: timepoints },
      headers
    );
  }
}
