import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Format, OnDemandVideoListItem, OnDemandVideoListResponse, Topic } from 'medtoday-models-library';
import moment from 'moment';
import { getCongressMetaData, getOnDemandVideosData } from 'projects/todaylib/core/data/selectors/data-api.selectors';
import { GoToOnDemandVideo } from 'projects/todaylib/core/router/actions/main-navigation.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { ObservableComponent } from 'projects/todaylib/shared/components/observable/observable.component';
import { takeUntil } from 'rxjs/operators';

import { TimeSlotRow } from '../../../../models/time-slot-row';
import { SESSION_UTILS_TOKEN } from '../../../../utilities/session.utils';

@Component({
  selector: 'app-video-row',
  templateUrl: 'video-row.component.html',
  styleUrls: ['../session-row/session-row.component.scss']
})
export class VideoRowComponent extends ObservableComponent implements OnInit {
  congressMetadata$ = this.store.select(getCongressMetaData);
  @Input() row: TimeSlotRow;
  @Input() programmeData;
  @Input() congressSlug: string;
  @Input() selectedTopic: boolean;
  @Input() isFeaturedContent = false;

  public format = '';
  vodFormats: Format[] | undefined;

  onDemandVideosData$ = this.store.select(getOnDemandVideosData);

  constructor(
    private store: Store<BaseAppState>,
    @Inject(SESSION_UTILS_TOKEN) private sessionUtils,
    @Inject('applicationName') public applicationName: string
  ) {
    super();
  }

  ngOnInit(): void {
    this.observeOnDemandVideos();
    this.getCommonVoDFormat(this.row.videos);
  }

  isLive(row: TimeSlotRow) {
    return row.sessions?.length && this.sessionUtils.isActive(row);
  }

  isVideoOnline(row: TimeSlotRow) {
    const dateNow = moment(new Date());
    return dateNow.isSameOrAfter(moment(row.start));
  }

  private getCommonVoDFormat(videos: OnDemandVideoListItem[]) {
    videos.forEach((video: OnDemandVideoListItem) => {
      const areAllSessionOfSameFormat = videos.every((v: OnDemandVideoListItem) => v.formatId === video.formatId);
      if (areAllSessionOfSameFormat) {
        this.format = this.getVoDFormat(videos[0]);
      }
    });
  }

  getTopicById(topicId: number, topics: Topic[]) {
    return topics.find((topic: Topic) => topic.id === topicId);
  }

  public getVoDFormat(video: OnDemandVideoListItem): string {
    return this.vodFormats?.find(f => f.id === video.formatId)?.title ?? '';
  }

  public handleGoToVideo(id: number): void {
    this.store.dispatch(new GoToOnDemandVideo(id, false, undefined, this.congressSlug));
  }

  observeOnDemandVideos() {
    this.onDemandVideosData$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((onDemandVideos: OnDemandVideoListResponse) => (this.vodFormats = onDemandVideos?.formats));
  }
}
