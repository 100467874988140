<div
  class="sponsors-section mt-5"
  replaceToDownloadLink
  *ngIf="
    congressData.sponsorsSection.firstLevelSponsors?.length ||
    congressData.sponsorsSection.secondLevelSponsors?.length ||
    congressData.sponsorsSection.thirdLevelSponsors?.length ||
    congressData.sponsorsSection.fourthLevelSponsors?.length ||
    congressData.sponsorsSection.associateSponsors?.length
  "
>
  <h4 class="mt-5">
    {{ congressData.sponsorsSection.title ? congressData.sponsorsSection.title : ('Sponsors.Sponsors' | translate) }}
  </h4>
  <div *ngIf="congressData.sponsorsSection.description" [innerHTML]="congressData.sponsorsSection.description"></div>
  <div class="sponsors sponsor-level-0" *ngIf="congressData.sponsorsSection.firstLevelSponsors?.length">
    <h5 class="mt-4 mt-lg-5 mb-0">
      {{ congressData.sponsorsSection.firstLevelSponsors[0].level | translate }}
    </h5>
    <div class="row mt-2 justify-content-center">
      <div class="col-md-5 col-6 my-3 rounded" *ngFor="let sponsor of congressData.sponsorsSection.firstLevelSponsors">
        <div class="sponsor-image-wrapper">
          <a [href]="sponsor.link" target="_blank" *ngIf="sponsor.link">
            <img class="w-100 h-100" [src]="s3BucketUrl + logosImageFolderSlug + sponsor.logo" />
          </a>
          <img class="w-100 h-100" [src]="s3BucketUrl + logosImageFolderSlug + sponsor.logo" *ngIf="!sponsor.link" />
        </div>
      </div>
    </div>
  </div>

  <div class="sponsors sponsor-level-1" *ngIf="congressData.sponsorsSection.secondLevelSponsors?.length">
    <h5 class="mt-4 mt-lg-5 mb-0">
      {{ congressData.sponsorsSection.secondLevelSponsors[0].level | translate }}
    </h5>
    <div class="row mt-2 justify-content-center">
      <div
        class="col-md-4 col-5 my-1 px-md-1 sponsor-wrapper"
        *ngFor="let sponsor of congressData.sponsorsSection.secondLevelSponsors"
      >
        <ng-container
          [ngTemplateOutlet]="sponsorWrapper"
          [ngTemplateOutletContext]="{ sponsor: sponsor, level: sponsorLevel.gold }"
        >
        </ng-container>
      </div>
    </div>
  </div>

  <div class="sponsors sponsor-level-2" *ngIf="congressData.sponsorsSection.thirdLevelSponsors?.length">
    <h5 class="mt-4 mt-lg-5 mb-0">
      {{ congressData.sponsorsSection.thirdLevelSponsors[0].level | translate }}
    </h5>
    <div class="row mt-2 justify-content-center">
      <div
        class="col-md-4 col-5 my-1 px-md-1 sponsor-wrapper"
        *ngFor="let sponsor of congressData.sponsorsSection.thirdLevelSponsors"
      >
        <ng-container
          [ngTemplateOutlet]="sponsorWrapper"
          [ngTemplateOutletContext]="{ sponsor: sponsor, level: sponsorLevel.silber }"
        >
        </ng-container>
      </div>
    </div>
  </div>

  <div class="sponsors sponsor-level-3" *ngIf="congressData.sponsorsSection.fourthLevelSponsors?.length">
    <h5 class="mt-4 mt-lg-5 mb-0">
      {{ congressData.sponsorsSection.fourthLevelSponsors[0].level | translate }}
    </h5>
    <div class="row mt-2 justify-content-center">
      <div
        class="col-md-4 col-5 my-1 px-md-1 sponsor-wrapper"
        *ngFor="let sponsor of congressData.sponsorsSection.fourthLevelSponsors"
      >
        <ng-container
          [ngTemplateOutlet]="sponsorWrapper"
          [ngTemplateOutletContext]="{ sponsor: sponsor, level: sponsorLevel.bronze }"
        >
        </ng-container>
      </div>
    </div>
  </div>

  <div class="sponsors sponsor-level-3" *ngIf="congressData.sponsorsSection.associateSponsors?.length">
    <h5 class="mt-4 mt-lg-5 mb-0">
      {{ congressData.sponsorsSection.associateSponsors[0].level | translate }}
    </h5>
    <div class="row mt-2 justify-content-center">
      <div
        class="col-md-4 col-5 my-1 px-md-1 sponsor-wrapper"
        *ngFor="let sponsor of congressData.sponsorsSection.associateSponsors"
      >
        <ng-container
          [ngTemplateOutlet]="sponsorWrapper"
          [ngTemplateOutletContext]="{ sponsor: sponsor, level: sponsorLevel.associate }"
        >
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #sponsorWrapper let-sponsor="sponsor" let-level="level">
  <div class="sponsor-image-wrapper rounded sponsor-level-{{ level }}">
    <a [href]="sponsor.link" target="_blank" *ngIf="sponsor.link">
      <img *ngIf="level !== 4" class="w-100 h-100" [src]="s3BucketUrl + logosImageFolderSlug + sponsor.logo" />
      <h6 *ngIf="level === 4" class="m-0">{{ sponsor.name }}</h6>
    </a>
    <ng-container *ngIf="!sponsor.link">
      <img *ngIf="level !== 4" class="w-100 h-100" [src]="s3BucketUrl + logosImageFolderSlug + sponsor.logo" />
      <h6 *ngIf="level === 4" class="m-0">{{ sponsor.name }}</h6>
    </ng-container>
  </div>
</ng-template>
