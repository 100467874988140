<ng-container *ngIf="congressMetaData$ | async as congressMetaData">
  <ng-container *ngIf="(isCongressMenuEmpty$ | async) === false">
    <div class="dropdown w-100" *ngIf="!mobileView">
      <button
        type="button"
        id="select"
        class="btn d-flex align-items-center justify-content-between navigation-menu w-100 h-100 dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <p class="m-0">{{ 'NavigationMenu.Title' | translate }}</p>
        <!-- Navigation -->
        <span></span>
      </button>
      <div class="dropdown-menu w-100" aria-labelledby="select">
        <a
          [class.selected]="getIsNavItemActive('home')"
          class="dropdown-item"
          draggable="false"
          (click)="handleHomeClick()"
          >{{ 'NavigationMenu.Overview' | translate }}</a
        >
        <ng-container
          *ngIf="congressMetaData.menuOptions?.length > 0"
          [ngTemplateOutlet]="links"
          [ngTemplateOutletContext]="{
            congressMetaData: congressMetaData,
            class: 'dropdown-item menu-link'
          }"
        ></ng-container>
      </div>
    </div>

    <ng-container *ngIf="mobileView">
      <div class="divider mb-3 mt-2 w-100"></div>
      <a
        [class.selected]="getIsNavItemActive('home')"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
        class="menu-link"
        draggable="false"
        (click)="handleHomeClick()"
        >{{ 'NavigationMenu.Overview' | translate }}</a
      >
      <ng-container
        *ngIf="congressMetaData.menuOptions?.length > 0"
        [ngTemplateOutlet]="links"
        [ngTemplateOutletContext]="{ congressMetaData: congressMetaData, class: 'menu-link' }"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #links let-congressMetaData="congressMetaData" let-class="class">
  <ng-container *ngIf="congressMetaData.menuOptions.includes('program')">
    <div class="divider my-3 w-100" *ngIf="mobileView"></div>
    <a
      [class.selected]="getIsNavItemActive('programme')"
      data-toggle="collapse"
      data-target=".navbar-collapse.show"
      [class]="class"
      draggable="false"
      (click)="handleProgrammeClick()"
      >{{
        applicationName === 'MedToday'
          ? ('NavigationMenu.Programme' | translate)
          : ('NavigationMenu.PatientsProgramme' | translate)
      }}</a
    >
  </ng-container>
  <ng-container *ngIf="congressMetaData.menuOptions.includes('experts')">
    <div class="divider my-3 w-100" *ngIf="mobileView"></div>
    <a
      [class.selected]="getIsNavItemActive('experts')"
      data-toggle="collapse"
      data-target=".navbar-collapse.show"
      [class]="class"
      draggable="false"
      (click)="handleExpertsClick()"
      >{{ 'NavigationMenu.Experts' | translate }}</a
    >
  </ng-container>
  <ng-container *ngIf="congressMetaData.menuOptions.includes('onDemand')">
    <div class="divider my-3 w-100" *ngIf="mobileView"></div>
    <a
      [class.selected]="getIsNavItemActive('onDemand')"
      data-toggle="collapse"
      data-target=".navbar-collapse.show"
      [class]="class"
      draggable="false"
      (click)="handleOnDemandClick()"
      >{{
        applicationName === 'MedToday'
          ? ('NavigationMenu.OnDemand' | translate)
          : ('NavigationMenu.PatientsOnDemand' | translate)
      }}</a
    >
  </ng-container>
  <ng-container *ngIf="congressMetaData.menuOptions.includes('featuredContent')">
    <div class="divider my-3 w-100" *ngIf="mobileView"></div>
    <a
      [class.selected]="getIsNavItemActive('featured-content')"
      data-toggle="collapse"
      data-target=".navbar-collapse.show"
      [class]="class"
      draggable="false"
      (click)="handleFeaturedContentClick()"
      >{{ 'NavigationMenu.FeaturedContent' | translate }}</a
    >
  </ng-container>
  <ng-container *ngIf="congressMetaData.menuOptions.includes('newsfeed') && applicationName === 'PatientsToday'">
    <div class="divider my-3 w-100" *ngIf="mobileView"></div>
    <a
      [class.selected]="getIsNavItemActive('newsletter')"
      data-toggle="collapse"
      data-target=".navbar-collapse.show"
      [class]="class"
      draggable="false"
      (click)="handleNewsletterClick()"
      >{{ 'NavigationMenu.Newsletter' | translate }}</a
    >
  </ng-container>
</ng-template>
