import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MedicalEducationResponse, OnDemandVideoSearchResultResponse } from 'medtoday-models-library/lib/models';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { AsyncAction } from '../../../../../todaylib/core/async-state/models/async-action.model';
import { handleAsyncErrors } from '../../../../../todaylib/core/async-state/operators/handle-errors';
import {
  LoadFilteredOnDemandVideos,
  LoadFilteredOnDemandVideosFail,
  LoadFilteredOnDemandVideosSuccess,
  LoadMedicalEducationData,
  LoadMedicalEducationDataFail,
  LoadMedicalEducationDataSuccess,
  MedicalEducationApiActionTypes
} from '../actions/medical-education-api.actions';
import { DataApiService } from '../../../../../todaylib/core/data/services/data-api.service';

@Injectable({
  providedIn: 'root'
})
export class MedicalEducationApiEffects {
  loadMedicalEducationData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedicalEducationApiActionTypes.LoadMedicalEducationData),
        mergeMap((_action: LoadMedicalEducationData) =>
          this.api.getMedicalEducationData().pipe(
            handleAsyncErrors(() => new LoadMedicalEducationDataFail()),
            map((response: MedicalEducationResponse) => new LoadMedicalEducationDataSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadVideosOnDemandSearchResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedicalEducationApiActionTypes.LoadFilteredOnDemandVideos),
        switchMap((action: LoadFilteredOnDemandVideos) =>
          this.api
            .getPagedOnDemandVideos(
              action.page,
              action.topicCategoryId,
              action.topicId,
              action.congressMode,
              action.formatId
            )
            .pipe(
              handleAsyncErrors(() => new LoadFilteredOnDemandVideosFail()),
              map(
                (response: OnDemandVideoSearchResultResponse) =>
                  new LoadFilteredOnDemandVideosSuccess(response, action.isSubsequentVideosRequest)
              ),
              catchError((errorAction: AsyncAction) => of(errorAction))
            )
        )
      ),
    { dispatch: true }
  );

  constructor(private api: DataApiService, private actions$: Actions) {}
}
