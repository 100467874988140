import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { SearchDataState } from '../reducers/search.reducer';
import { SEARCH_STATE_KEY } from '../definitions/search.definitions';
import { CrossPlatformSearchVideoListItem } from 'medtoday-models-library/src/models/responses/on-demand-video/cross-platform-search-video-list-item.model';

export namespace SearchSelectors {
  export const selectSearchState = createFeatureSelector<SearchDataState>(SEARCH_STATE_KEY);

  export const selectGetNextPage: MemoizedSelector<object, number | null> = createSelector(
    selectSearchState,
    searchState => searchState.nextPage
  );

  export const selectSearchResults: MemoizedSelector<object, CrossPlatformSearchVideoListItem[] | undefined> =
    createSelector(selectSearchState, searchState => searchState.results);

  export const selectHasNextPage: MemoizedSelector<object, boolean> = createSelector(
    selectSearchState,
    searchState => !!searchState.nextPage && searchState.nextPage > 1 && !!searchState.results?.length
  );
}
