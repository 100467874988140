import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { appUpdaterInitFactory, AppUpdateService } from './services/app-updater.service';

@NgModule({})
export class AppUpdaterModule {
  static forRoot() {
    return {
      // tslint:disable-next-line: no-use-before-declare
      ngModule: RootAppUpdaterModule,
      providers: [
        AppUpdateService,
        {
          provide: APP_INITIALIZER,
          useFactory: appUpdaterInitFactory,
          deps: [AppUpdateService],
          multi: true,
        },
      ],
    };
  }
}

@NgModule({
  imports: [
    CommonModule,
  ],
})
export class RootAppUpdaterModule {
}
