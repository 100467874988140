<!-- Floating navbar background-->
<ng-content select="picture"></ng-content>
<ng-content select="app-nav-bar"></ng-content>
<app-header
  [backgroundImageUrl]="congressBackgroundUrl"
  [hideNavigation]="hideNavigation"
  [hidden]="hideHeader"
></app-header>
<main>
  <div class="safe-area-top-spacing"></div>
  <ng-content select="router-outlet"></ng-content>
  <div class="safe-area-bottom-spacing"></div>
</main>
<ng-content select="app-footer"></ng-content>
