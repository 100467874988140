<div
  class="modal fade"
  id="registerModal"
  tabindex="-1"
  aria-labelledby="registerModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered rounded">
    <div class="modal-content bg-dark">
      <div class="modal-header p-4">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="bi bi-x-lg text-white"></i>
        </button>
      </div>
      <div class="modal-body">
        <app-login-form *ngIf="(isLoggedIn$ | async) === false" (login)="handleLoginClick($event)"></app-login-form>
        <app-async-state-overlay [processKey]="asyncProcessKey" (resend)="resendSignUpCode()"></app-async-state-overlay>
        <ng-container *ngIf="(isLoggedIn$ | async) === true">
          <ng-container *ngIf="!registrationComplete">
            <h4>{{ 'Auth.RegistrationForEvent' | translate }} {{ congressMetaData.name }}</h4>
            <p>{{ 'Auth.EventRegistrationText' | translate }}</p>

            <form name="register-event" [formGroup]="form" (ngSubmit)="handleSignUpClick()" #ngForm="ngForm" novalidate>
              <div
                class="form-group"
                [ngClass]="ngForm.submitted && form.controls['attendance'].invalid ? 'invalid is-invalid p-2' : ''"
              >
                <ng-container *ngIf="congressMetaData.congressCategory === 'HYBRID'">
                  <div class="custom-control custom-radio" *ngIf="!congressMetaData.onlineRegistrationClosed">
                    <input type="radio" id="onlineAttendance" name="attendance" class="custom-control-input" />
                    <label
                      class="custom-control-label"
                      for="onlineAttendance"
                      (click)="handleSelectAttendance('online')"
                      >{{ 'Auth.OnlineParticipation' | translate }}</label
                    >
                  </div>

                  <div class="custom-control custom-radio" *ngIf="!congressMetaData.offlineRegistrationClosed">
                    <input type="radio" id="offlineAttendance" name="attendance" class="custom-control-input" />
                    <label
                      class="custom-control-label"
                      for="offlineAttendance"
                      (click)="handleSelectAttendance('offline')"
                      >{{ 'Auth.OfflineParticipation' | translate }}</label
                    >
                  </div>
                </ng-container>

                <ng-container *ngIf="congressMetaData.congressCategory === 'ONLINE'">
                  <div class="custom-control custom-radio">
                    <input type="radio" id="onlineAttendance" name="attendance" class="custom-control-input" />
                    <label
                      class="custom-control-label"
                      for="onlineAttendance"
                      (click)="handleSelectAttendance('online')"
                      [translate]="'Auth.OnlineOnlyParticipation'"
                      [translateParams]="{ eventName: congressMetaData.name }"
                    ></label>
                  </div>
                </ng-container>

                <ng-container *ngIf="congressMetaData.congressCategory === 'ON_SITE'">
                  <div class="custom-control custom-radio">
                    <input type="radio" id="offlineAttendance" name="attendance" class="custom-control-input" />
                    <label
                      class="custom-control-label"
                      for="offlineAttendance"
                      (click)="handleSelectAttendance('offline')"
                      [translate]="'Auth.OnlineOnlyParticipation'"
                      [translateParams]="{ eventName: congressMetaData.name }"
                    ></label>
                  </div>
                </ng-container>
              </div>
              <div class="invalid-feedback">
                <div *ngIf="form.controls['attendance'].hasError('required')">
                  {{ 'Auth.AttendanceRequired' | translate }}
                </div>
              </div>

              <div class="form-group row mt-4" *ngIf="user && !user['custom:institution']">
                <div class="col-12">
                  <label>Institution</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    name="institution"
                    placeholder="{{ 'SignUp.EnterInstitution' | translate }}"
                    formControlName="institution"
                    [class.is-invalid]="ngForm.submitted && form.controls['institution'].invalid"
                  />
                </div>
                <div class="invalid-feedback">
                  <div *ngIf="form.controls['institution'].hasError('required')">
                    {{ 'SignUp.InstitutionRequired' | translate }}
                  </div>
                </div>
              </div>

              <button class="btn btn-primary btn-block mt-4" type="submit">
                <span *ngIf="!congressMetaData.congressPayable">{{ 'Auth.SendRegistration' | translate }}</span>
                <span *ngIf="congressMetaData.congressPayable">{{ 'Auth.PayAndRegister' | translate }}</span>
              </button>
            </form>
          </ng-container>

          <ng-container *ngIf="registrationComplete">
            <h4>{{ 'Auth.EventRegistrationThanks' | translate }}</h4>
            <p>{{ 'Auth.EventRegistrationValidationText' | translate }}</p>
            <button class="btn btn-outline-primary btn-block mt-4" (click)="handleAddToCalendarClick(congressMetaData)">
              {{ 'Event.AddEventCalendar' | translate }}
            </button>
            <button class="btn btn-primary btn-block mt-2" data-dismiss="modal" aria-label="Close">
              {{ 'Event.BackToEvent' | translate }}
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<a
  class="payrexx-modal-window"
  href="#"
  style="display: none"
  *ngIf="congressMetaData.congressPayable && user && modalPaymentPage"
  data-href="{{ modalPaymentPage }}"
>
</a>

<app-async-state-overlay [processKey]="addParticipantAsyncProcessKey"></app-async-state-overlay>
