import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-imprint-page',
  templateUrl: './imprint-page.component.html',
  styleUrls: ['./imprint-page.component.scss'],
})
export class ImprintPageComponent implements OnInit {

  get currentLang() {
    return this.translateService.getDefaultLang();
  }

  constructor(private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

}
