export const TOPICS = [
  'Allergology',
  'General Medicine',
  'Angiology',
  'Surgery',
  'Dermatology',
  'Diabetology',
  'Endocrinology',
  'Gastroenterology',
  'Geriatric Medicine',
  'Gynaecology',
  'Haematology',
  'Otolaryngology (ENT)',
  'Immunology',
  'Infectiology',
  'Internal Medicine',
  'Intensive Care Medicine',
  'Cardiology',
  'Molecular Genetics',
  'Naturopathy',
  'Nephrology',
  'Neurology',
  'Oncology',
  'Ophthalmology',
  'Orthopedics',
  'Paediatrics',
  'Palliative Medicine',
  'Pathology',
  'Pneumology',
  'Psychiatry',
  'Radiology',
  'Rheumatology',
  'Sport and Exercise Medicine',
  'Urology',
  'Others'
];
