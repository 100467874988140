import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Player from '@vimeo/player';
import { fromEvent, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ObservableComponent } from '../observable/observable.component';
import { PlayerPositionUpdater } from '../../models/player-position-updater.model';

@Component({
  selector: 'app-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.scss']
})
export class VimeoPlayerComponent extends ObservableComponent implements PlayerPositionUpdater {
  @ViewChild('player') player: ElementRef<HTMLIFrameElement>;
  @Input() isLivestream: boolean;
  @Input() initialPosition = 0;
  @Output() position = new EventEmitter<number>();
  @Output() loaded = new EventEmitter<void>();

  private _mediaId = '';
  public get mediaId() {
    return this._mediaId;
  }
  @Input()
  public set mediaId(value) {
    this._mediaId = value;
    this.loadUrl();
  }

  @Output() videoWatched = new EventEmitter<{ media: string; id: string }>();

  safeSrc: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  private loadUrl() {
    if (this.mediaId) {
      const videoUrl: string = !this.isLivestream
        ? `https://player.vimeo.com/video/${this.mediaId}`
        : `https://vimeo.com/event/${this.mediaId}/embed`;

      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);

      setTimeout(() => {
        this.initListeners();
        this.videoWatched.emit();
      });
    }
  }

  private initListeners() {
    const videoPlayer = new Player(this.player.nativeElement);
    fromEvent(videoPlayer, 'ended')
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => this.videoWatched.emit({ media: this.mediaId, id: this.mediaId }));

    this.observePosition(videoPlayer);
  }

  observePosition(videoPlayer: Player) {
    if (this.initialPosition > 0) {
      videoPlayer.setCurrentTime(this.initialPosition);
    }

    interval(10000)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        videoPlayer.getPaused().then(paused => {
          if (!paused) {
            videoPlayer.getCurrentTime().then(time => {
              this.position.emit(time);
            });
          }
        });
      });
  }
}
