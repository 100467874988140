<div class="cme-toggle-wrapper d-flex flex-column text-dark bg-white">
  <div class="state-note text-center">
    <span
      *ngIf="(cmeActiveState$ | async) === true"
      [innerHTML]="'ChannelPage.CmeTrackingToggleNoteActive' | translate"
    ></span>
    <span
      *ngIf="(cmeActiveState$ | async) === false"
      [innerHTML]="'ChannelPage.CmeTrackingToggleNoteInActive' | translate"
    ></span>
  </div>
  <div class="d-flex">
    <div class="ml-auto switch-text align-self-center">
      <span *ngIf="(cmeActiveState$ | async) === true">{{
        'ChannelPage.CmeTrackingToggleActiveLabel' | translate
      }}</span>
      <span *ngIf="(cmeActiveState$ | async) === false">{{
        'ChannelPage.CmeTrackingToggleInActiveLabel' | translate
      }}</span>
    </div>
    <div class="switch-wrapper d-flex form-group ml-auto mr-3">
      <div class="custom-switch" (click)="handleCmeToggleClick()">
        <input
          type="checkbox"
          class="custom-control-input cms-toggle"
          id="cmeToggleState"
          readonly="readonly"
          [formControl]="cmeToggleStateControl"
        />
        <label class="custom-control-label cms-toggle" for="cmeToggleState" disabled></label>
      </div>
    </div>
  </div>
</div>
