<div class="row">
  <ng-container *ngFor="let video of row.videos; let i = index">
    <div [ngClass]="isFeaturedContent ? 'col-lg-8' : 'col-lg-6'" class="session col-12 adjust-top-2">
      <div *ngIf="!selectedTopic" class="divider w-100 mb-3" [class.hide-mobile]="isFeaturedContent"></div>
      <!-- desktop view -->
      <div class="h-100 d-flex flex-column hide-tablet">
        <div class="title-wrapper d-flex flex-row mt-3 flex-wrap">
          <ng-container
            [ngTemplateOutlet]="vodHeadline"
            [ngTemplateOutletContext]="{ video: video, programmeData: programmeData, row: row }"
          ></ng-container>
        </div>

        <h5 class="m-0 mb-3 mt-md-2" *ngIf="video.title && !isFeaturedContent">{{ video.title }}</h5>
        <div *ngIf="isFeaturedContent" class="d-flex flex-row align-items-center">
          <h5 class="mt-md-2">
            {{ video.title ? video.title : video.format }}
          </h5>
          <app-live-indicator class="ml-2" *ngIf="isLive(row)" [timeSlotRow]="row"></app-live-indicator>
        </div>
        <ng-container *ngIf="video.experts?.length">
          <span class="text-note" *ngFor="let expertIdentity of video.experts"
            >{{ expertIdentity | expert }}<br
          /></span>
        </ng-container>
        <div class="d-flex">
          <button
            *ngIf="isVideoOnline(row)"
            type="button"
            class="btn btn-danger shadow-sm mt-4 font-size-large"
            (click)="handleGoToVideo(video.id)"
          >
            {{ 'Navigation.GoToVideo' | translate }}
            <div class="icon-16 icon-stream" [inlineSVG]="'assets/icons/stream.svg'"></div>
          </button>
        </div>
      </div>
      <!-- mobile view -->
      <div id="accordion" class="hide-desktop">
        <div class="accordion-inner">
          <button
            class="btn collapsed accordion-btn"
            data-toggle="collapse"
            attr.data-target="#video{{ i }}{{ video.id }}"
            aria-expanded="true"
            attr.aria-controls="video{{ i }}{{ video.id }}"
          >
            <div class="d-flex button-inner flex-wrap">
              <ng-container
                [ngTemplateOutlet]="vodHeadline"
                [ngTemplateOutletContext]="{ video: video, programmeData: programmeData, row: row }"
              ></ng-container>
            </div>
            <div class="indicator"></div>
          </button>
          <div id="video{{ i }}{{ video.id }}" class="collapse" data-parent="#accordion">
            <h5 class="m-0 mb-3" *ngIf="video.title && !isFeaturedContent">{{ video.title }}</h5>
            <ng-container *ngIf="video.experts?.length">
              <span class="text-note" *ngFor="let expertIdentity of video.experts"
                >{{ expertIdentity | expert }}<br
              /></span>
            </ng-container>
            <div class="d-flex">
              <button
                *ngIf="isVideoOnline(row)"
                type="button"
                class="btn btn-danger shadow-sm mt-4 font-size-large"
                (click)="handleGoToVideo(video.id)"
              >
                {{ 'Navigation.GoToVideo' | translate }}
                <div class="icon-16 icon-stream" [inlineSVG]="'assets/icons/stream.svg'"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #vodHeadline let-video="video" let-programmeData="programmeData" let-row="row">
  <ng-container *ngIf="currentLang !== 'ch' && !(congressMetadata$ | async)?.hideSponsorInMediaListing">
    <h6 class="card-title badge badge-sponsored p-2 mr-1 rounded" *ngFor="let sponsor of video.sponsors">
      {{ sponsor.name }}
    </h6>
  </ng-container>
  <ng-container *ngIf="(selectedTopic && getVoDFormat(video)) || (isFeaturedContent && getVoDFormat(video))">
    <h6 class="session-format card-title" [ngClass]="isFeaturedContent ? 'badge badge-dark mr-1 p-2' : ''">
      {{ getVoDFormat(video) }}
    </h6>
  </ng-container>
  <ng-container
    *ngIf="
      (!selectedTopic && getTopicById(video.topicId, programmeData.topics)?.title) ||
      (isFeaturedContent && getTopicById(video.topicId, programmeData.topics)?.title)
    "
  >
    <h6
      class="session-format card-title mr-1 p-2"
      [ngClass]="applicationName === 'PatientsToday' ? 'badge-light' : 'badge-dark'"
      *ngIf="video.title !== getTopicById(video.topicId, programmeData.topics).title"
    >
      {{ getTopicById(video.topicId, programmeData.topics).title }}
    </h6>
  </ng-container>
  <h6
    class="session-format card-title badge p-2 mr-1"
    [ngClass]="applicationName === 'PatientsToday' ? 'badge-light' : 'badge-dark'"
    *ngIf="format === '' && !selectedTopic && getVoDFormat(video) && !isFeaturedContent"
  >
    {{ getVoDFormat(video) }}
  </h6>
  <h6 *ngIf="video.title && isFeaturedContent" class="card-title mb-0 mt-2 d-md-none">
    {{ video.title }}
  </h6>
  <h6
    *ngIf="video.spokenLanguage"
    class="session-format card-title badge p-2 mr-1"
    [ngClass]="applicationName === 'PatientsToday' ? 'badge-light' : 'badge-dark'"
  >
    {{ 'VideoOnDemand.' + video.spokenLanguage | translate }}
  </h6>
  <span
    class="p-2 badge font-weight-light mr-1 mb-1"
    *ngIf="video.languages?.length"
    [ngClass]="applicationName === 'PatientsToday' ? 'badge-light' : 'badge-dark'"
  >
    {{ 'VideoOnDemand.Subtitles' | translate }}
  </span>
  <app-live-indicator
    class="ml-2"
    *ngIf="isLive(row)"
    [timeSlotRow]="row"
    [class.d-md-none]="isFeaturedContent"
  ></app-live-indicator>
</ng-template>
