import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseAppState } from '../../../../core/store/reducers';
import { Store } from '@ngrx/store';
import { ClearYoutubeVideoData, LoadYoutubeVideoData } from '../../../../core/data/actions/providers.actions';
import { selectYoutubeVideoData } from '../../../../core/data/selectors/providers.selectors';
import { auditTime, filter, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { TimestampsBaseComponent } from '../timestamps-base.component';
import parseYouTubeChapters from 'get-youtube-chapters';
import { interval } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Component({
  selector: 'app-vod-youtube-timestamps',
  templateUrl: '../vod-timestamps.component.html',
  styleUrls: ['../vod-timestamps.component.scss', './vod-youtube-timestamps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VodYoutubeTimestampsComponent extends TimestampsBaseComponent implements OnInit, OnDestroy {
  youtubeVideoData$ = this.store.select(selectYoutubeVideoData);

  @Input()
  mediaId: string;

  constructor(private store: Store<BaseAppState>) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new LoadYoutubeVideoData(this.mediaId));
    this.observeEvents();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new ClearYoutubeVideoData());
  }

  private observeEvents() {
    this.observeVideoTimestamps();
    this.observePlayingVideo();
  }

  private observeVideoTimestamps() {
    this.youtubeVideoData$
      .pipe(
        takeUntil(this.ngDestroy$),
        filter(data => !!data)
      )
      .subscribe(videoData => {
        const chapters = parseYouTubeChapters(videoData.snippet.description);
        const timestamps = chapters.map(chapter => ({
          text: chapter.title.replace('*', ''),
          begin: chapter.start,
          hasStar: chapter.title.indexOf('*') > -1
        }));
        this.timestamps$.next(timestamps);

        if (timestamps) {
          this.hasStar = timestamps.some(t => t.hasStar);
          this.hasTimestamps$.next(!!timestamps.length);
        }
      });
  }

  private observePlayingVideo() {
    interval(500)
      .pipe(
        takeUntil(this.ngDestroy$),
        auditTime(500),
        withLatestFrom(this.timestamps$),
        filter(([_, timestamps]) => !!timestamps),
        switchMap(() =>
          fromPromise(this.player.getCurrentTime()).pipe(
            switchMap((currentTime: number) => {
              return this.selectCurrentTimestamp(currentTime);
            })
          )
        )
      )
      .subscribe();
  }
}
