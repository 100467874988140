<div class="w-100 footer">
  <div class="container py-5">
    <div class="row d-flex align-items-baseline">
      <div class="col-md-4 mb-3 text-left">
        <img src="assets/images/logo_alternative.svg" draggable="false" />
        <ng-container *ifApp="'PatientsToday'">
          <p class="py-3 text-light">
            <small>{{ 'Footer.PatientsFooterDesc' | translate }}</small>
          </p>
        </ng-container>
        <ng-container *ifApp="'MedToday'">
          <p class="py-3">
            <small>{{ 'Footer.FooterDesc' | translate }}</small>
          </p>
        </ng-container>
        <p class="text-light">
          <small>© {{ currentDate | date : 'yyyy' }}</small>
        </p>

        <!-- hide social media icons as content is not provided yet -->
        <!-- <br> -->
        <p class="text-light"><small>Follow us</small></p>
        <div class="share-icons">
          <ng-container *ifApp="'PatientsToday'">
            <a [href]="patientsInstagram" target="_blank" draggable="false"
              ><i class="icon-24 icon-instagram" [inlineSVG]="'assets/icons/instagram.svg'"></i
            ></a>
            <a [href]="patientsFacebook" target="_blank" draggable="false"
              ><i class="icon-24 icon-facebook" [inlineSVG]="'assets/icons/facebook.svg'"></i
            ></a>
          </ng-container>
          <ng-container *ifApp="'MedToday'">
            <a [href]="twitterProfileUrl" target="_blank" draggable="false"
              ><i class="icon-24 icon-twitter" [inlineSVG]="'assets/icons/twitter.svg'"></i
            ></a>
            <a [href]="linkedInProfileUrl" target="_blank" draggable="false"
              ><i class="icon-24 icon-linkedin" [inlineSVG]="'assets/icons/linkedin.svg'"></i
            ></a>
            <a [href]="instagramProfileUrl" target="_blank" draggable="false"
              ><i class="icon-24 icon-instagram" [inlineSVG]="'assets/icons/instagram.svg'"></i
            ></a>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4 mb-3 text-left">
        <h5 class="mb-3 text-light">{{ 'Footer.Legal' | translate }}</h5>
        <div class="d-flex flex-column text-md-left text-center">
          <a class="text-note text-left mb-3" (click)="handleGoToImprintClick()" draggable="false">{{
            'Footer.Copyright' | translate
          }}</a>
          <a class="text-note text-left mb-3" (click)="handleDataProtectionClick()" draggable="false">{{
            'Footer.PrivacyPolicy' | translate
          }}</a>
          <a class="text-note text-left mb-3" (click)="handleDisclaimerClick()" draggable="false">{{
            'Footer.Disclaimer' | translate
          }}</a>
          <a
            class="text-note text-left mb-3"
            (click)="handleNetiquetteClick()"
            *ifApp="'PatientsToday'"
            draggable="false"
            >{{ 'Footer.Netiquette' | translate }}</a
          >
        </div>
      </div>
      <div class="col-md-4 mb-3 text-left">
        <h5 class="mb-3 text-light">{{ 'Footer.Contact Details' | translate }}</h5>
        <div class="d-flex flex-column text-md-left text-center">
          <p class="text-note text-left mb-1" *ifApp="'PatientsToday'">patients today</p>
          <p class="text-note text-left mb-1" *ifApp="'MedToday'">{{ 'Common.medtoday' | translate }}</p>
          <p class="text-note text-left mb-1" [innerHTML]="'Footer.Contact Details Street' | translate"></p>
          <p class="text-note text-left mb-1" [innerHTML]="'Footer.Contact Details Location' | translate"></p>
          <ng-container *ifApp="'PatientsToday'">
            <a
              href="mailto:{{ 'Footer.Contact Mail Patientstoday' | translate }}"
              class="text-note text-left"
              draggable="false"
              >{{ 'Footer.Contact Mail Patientstoday' | translate }}</a
            >
          </ng-container>
          <ng-container *ifApp="'MedToday'">
            <a
              href="mailto:{{ 'Footer.Contact Mail Medtoday' | translate }}"
              class="text-note text-left"
              draggable="false"
              >{{ 'Footer.Contact Mail Medtoday' | translate }}</a
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
