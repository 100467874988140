import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ExpertListItem } from 'medtoday-models-library';
import { SetSelectedExpert } from 'projects/medtoday/src/app/pages/actions/experts-details.actions';
import { imagePaths } from 'projects/medtoday/src/environments/environment';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';

@Component({
  selector: 'app-expert-list-item',
  templateUrl: './expert-list-item.component.html',
  styleUrls: ['./expert-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpertListItemComponent {
  @Input() expert: ExpertListItem;
  @Input() isColumnOnDekstop = false;
  avatarsImageFolderSlug = imagePaths.avatars;

  constructor(
    protected store: Store<BaseAppState>,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string
  ) {}

  public handleExpertDetailsClick(expert: ExpertListItem): void {
    this.store.dispatch(new SetSelectedExpert(expert));
  }
}
