import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AsyncAction } from '../../../../../todaylib/core/async-state/models/async-action.model';
import { handleAsyncErrors } from '../../../../../todaylib/core/async-state/operators/handle-errors';
import {
  LoadMedBoardsData,
  LoadMedBoardsDataFail,
  LoadMedBoardsDataSuccess,
  MedBoardsDataApiActionTypes
} from '../actions/medboards-api.actions';
import { MedboardsApiService } from '../services/medboards-api.service';
import { MedBoardsResponseModel } from '../../models/medboards.model';
@Injectable({ providedIn: 'root' })
export class MedBoardsApiEffects {
  loadMedBoardsData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedBoardsDataApiActionTypes.LoadMedBoardsData),
        switchMap((_action: LoadMedBoardsData) =>
          this.medBoardsApiService.getMedBoardsData().pipe(
            handleAsyncErrors(() => new LoadMedBoardsDataFail()),
            map((response: MedBoardsResponseModel) => new LoadMedBoardsDataSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );
  constructor(private medBoardsApiService: MedboardsApiService, private actions$: Actions) {}
}
