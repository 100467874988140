<ng-container *ngIf="congressLandingPageData$ | async as congressData">
  <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
    <div class="container mt-5 sub-header">
      <div class="row" *ngIf="congressMetaData.layout.backgroundImage || congressMetaData.layout.logo">
        <ng-container *ifApp="'MedToday'">
          <div
            class="col-12 col-lg-5 order-lg-2 offset-lg-0 offset-md-3 offset-sm-2 mt-4 mb-0 mb-lg-4 pb-5 d-flex align-items-center w-100 logo-wrapper"
          >
            <ng-container *ngIf="congressMetaData.layout.logo">
              <div
                class="rounded-circle logo-circle d-flex align-items-center justify-content-center"
                *ngIf="displayLogoInCircle"
              >
                <img draggable="false" [src]="s3BucketUrl + logosImageFolderSlug + congressMetaData.layout.logo" />
              </div>

              <img
                draggable="false"
                class="d-none d-md-block"
                *ngIf="!displayLogoInCircle"
                [src]="s3BucketUrl + logosImageFolderSlug + congressMetaData.layout.logo"
              />
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ifApp="'PatientsToday'">
          <div
            class="col-12 col-lg-7 order-lg-2 col-md-12 order-md-0 mb-3 mb-md-0 offset-lg-0 d-flex align-items-center w-100 logo-wrapper"
          >
            <ng-container *ngIf="congressMetaData.layout.backgroundImage">
              <div
                class="rounded-circle logo-circle d-flex align-items-center justify-content-center position-relative h-100"
                *ngIf="displayLogoInCircle"
              >
                <img draggable="false" [src]="s3BucketUrl + congressMetaData.layout.backgroundImage" />
              </div>

              <img
                draggable="false"
                class="d-block rounded"
                *ngIf="!displayLogoInCircle"
                [src]="s3BucketUrl + congressMetaData.layout.backgroundImage"
              />
              <app-social-share-button size="small" class="social-share patients"></app-social-share-button>
            </ng-container>
          </div>
        </ng-container>
        <div
          [class]="applicationName === 'PatientsToday' ? 'col-lg-5' : 'col-lg-7'"
          class="col-12 order-lg-1 h-100 mt-0 mt-lg-3 mb-4 pr-sm-2 mb-3"
        >
          <img
            [src]="s3BucketUrl + logosImageFolderSlug + congressMetaData.layout.landingPageLogo"
            class="mb-4 mb-md-5 landing-page-logo"
            *ngIf="congressMetaData.layout.landingPageLogo"
          />
          <h1 [innerHtml]="congressData.title"></h1>
          <div
            class="mb-3"
            replaceToDownloadLink
            *ngIf="congressData.description"
            [innerHtml]="congressData.description"
          ></div>
          <div class="d-flex mt-0 mt-lg-5">
            <button
              *ngIf="isAnySessionLive | async"
              [class]="applicationName === 'PatientsToday' ? 'btn-primary' : 'btn-danger'"
              class="btn mr-3"
              (click)="handleLiveButtonClick()"
            >
              {{ (congressMetaData.mode === 'VoD' ? 'Home.NowAvailable' : 'Home.GoLive') | translate }}
              <i class="text-light pl-3" [inlineSVG]="'assets/icons/live.svg'"></i>
            </button>
            <a
              class="btn w-fit-content text-white mr-3"
              href="#registerModal"
              draggable="false"
              [style]="
                congressMetaData.layout.colors.accent
                  ? 'background: var(--accent)'
                  : 'background: var(--global-pink-color)'
              "
              *ngIf="
                !congressUtils.isAfterCongress(congressMetaData) &&
                !(congressParticipantData$ | async)?.isParticipant &&
                congressMetaData.authorizedOnly &&
                registrationService.showSignUpBtn
              "
              data-toggle="modal"
              (click)="registrationService.handleShowRegisterModal(true)"
              role="button"
            >
              {{ 'Auth.SignUp' | translate }}
            </a>
            <ng-container *ifApp="'MedToday'">
              <button
                *ngIf="
                  registrationService.isUserAuthorized &&
                  (isAnySessionLive | async) === false &&
                  hasProgramme(congressMetaData) &&
                  !congressUtils.isAfterCongress(congressMetaData)
                "
                class="btn btn-primary d-flex text-white mr-2"
                (click)="handleLiveButtonClick()"
              >
                {{ 'Navigation.GoToProgramme' | translate }}
              </button>
            </ng-container>
            <ng-container *ifApp="'PatientsToday'">
              <div
                *ngIf="
                  (isAnySessionLive | async) === false &&
                  hasProgramme(congressMetaData) &&
                  !congressUtils.isAfterCongress(congressMetaData)
                "
                class="d-flex mt-0 mr-2"
              >
                <button class="btn btn-primary d-flex text-white" (click)="handleLiveButtonClick()">
                  {{ 'Navigation.GoToProgramme' | translate }}
                </button>
              </div>
            </ng-container>
            <div
              *ngIf="
                (isAnySessionLive | async) === false &&
                hasOnDemand(congressMetaData) &&
                congressUtils.isAfterCongress(congressMetaData)
              "
              class="d-flex mt-0 mr-2"
            >
              <button class="btn btn-primary d-flex text-white" (click)="handleGoToOnDemandVideosClick()">
                {{ 'Navigation.GoToOnDemand' | translate }}
              </button>
            </div>
            <ng-container *ngIf="isLoggedIn$ | async">
              <div *ngIf="congressData.congressBag.active" class="d-flex mt-0">
                <button
                  class="btn d-flex text-white btn-{{ congressData.congressBag.buttonBackgroundColor }}"
                  (click)="handleGoToCongressBag(congressData.congressBag.buttonUrl)"
                >
                  {{ congressData.congressBag.buttonLabel ?? 'Navigation.GoToCongressBag' | translate }}
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <ng-template #experts>
      <section
        *ngIf="
          congressData.expertsSection.title ||
          congressData.expertsSection.description ||
          congressData.expertsSection.experts.length > 0
        "
        id="experts"
      >
        <div class="container">
          <div
            class="row mx-0 d-flex h-400"
            [style.background-image]="
              congressData.steeringCommitteeBackgroundImage
                ? 'url(' + s3BucketUrl + congressData.steeringCommitteeBackgroundImage + ')'
                : 'none'
            "
          >
            <div class="experts-spacer"></div>
            <div
              *ngIf="congressData.expertsSection.title || congressData.expertsSection.description"
              replaceToDownloadLink
              class="col-lg-5 mb-4 d-flex flex-column experts-description"
            >
              <div class="icon-steering-committee" [inlineSVG]="'assets/icons/steering-committee.svg'"></div>
              <h4 class="max-text-width" *ngIf="congressData.expertsSection.title">
                {{ congressData.expertsSection.title }}
              </h4>
              <p
                class="max-text-width"
                *ngIf="congressData.expertsSection.description"
                [innerHtml]="congressData.expertsSection.description"
              ></p>
              <button
                type="button"
                class="btn ghost-button-light mt-3 align-self-start width-fit-content"
                (click)="handleGoToExpertsClick()"
              >
                {{ 'Home.ExpertsOverview' | translate }}
              </button>
            </div>
            <div
              class="col-lg-6 offset-lg-1 experts mt-5 mt-lg-0"
              *ngIf="congressData.expertsSection.experts.length > 0"
            >
              <div class="d-flex flex-column align-items-start">
                <div
                  *ngFor="let expert of congressData.expertsSection.experts; let i = index"
                  class="expert d-flex flex-fill align-items-center mt-2"
                >
                  <div class="d-flex pr-2 pr-md-4">
                    <img
                      *ngIf="expert.profileImage"
                      draggable="false"
                      class="profile-image rounded-circle"
                      [src]="s3BucketUrl + avatarsImageFolderSlug + expert.profileImage"
                      alt="{{ 'Home.ExpertProfile' | translate }}"
                    />
                  </div>
                  <div class="d-flex flex-fill flex-column">
                    <h6>{{ expert | expert }}</h6>
                    <small class="max-text-width text-note">{{ expert.areaOfActivity }}</small>
                    <div
                      class="d-flex"
                      *ngIf="
                        expert.mediaIdentifier &&
                        (expert.playerIdentifier || congressMetaData.mediaProvider === MediaProvider.YT)
                      "
                    >
                      <a
                        role="button"
                        data-toggle="modal"
                        href="#expertVideo"
                        draggable="false"
                        (click)="setSelectedExpertVideoDetails(expert.mediaIdentifier, expert.playerIdentifier)"
                        class="btn btn-dark width-fit-content"
                      >
                        {{ 'Home.ViewGreeting' | translate }}
                      </a>
                    </div>
                  </div>
                </div>
                <!-- expert video overlay -->
                <ng-container *ngIf="mediaId && expertVideoVisible">
                  <div
                    class="modal"
                    id="expertVideo"
                    tabindex="-1"
                    aria-labelledby="expertVideoModalLabel"
                    aria-focus="true"
                    aria-hidden="true"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <div class="backdrop" data-dismiss="modal" (click)="handleCloseVideo()"></div>
                    <button
                      type="button"
                      class="btn btn-dark rounded-circle d-flex align-items-center justify-content-center close-video font-size-larger shadow-sm"
                      data-dismiss="modal"
                      (click)="handleCloseVideo()"
                    >
                      <icon class="bi bi-x text-light"></icon>
                    </button>
                    <div class="modal-dialog modal-dialog-centered modal-xl rounded">
                      <div class="modal-content">
                        <div class="modal-body p-0">
                          <app-jw-player
                            *ngIf="(congressMetaData$ | async)?.mediaProvider === 'JW' && playerId"
                            [mediaId]="mediaId"
                            [playerId]="playerId"
                          ></app-jw-player>
                          <app-vimeo-player
                            *ngIf="(congressMetaData$ | async)?.mediaProvider === 'VIMEO'"
                            [mediaId]="mediaId"
                          ></app-vimeo-player>
                          <app-youtube-player
                            *ngIf="(congressMetaData$ | async)?.mediaProvider === 'YT'"
                            [mediaId]="mediaId"
                          ></app-youtube-player>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="experts-spacer"></div>
          </div>
          <div class="divider w-100"></div>
        </div>
      </section>
    </ng-template>

    <!-- congress banner if position equals "top" -->
    <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
      <ng-container *ngIf="congressMetaData.layout?.banner?.position === 'top'">
        <app-congress-banner
          [congressLogo]="congressMetaData.layout?.banner?.logo_left"
          [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
          [disclaimer]="congressMetaData.layout?.banner?.text"
        ></app-congress-banner>
      </ng-container>
      <app-spacer class="hide-mobile" id="features-spacer"></app-spacer>
    </ng-container>

    <!-- Features -->
    <section id="features" *ngIf="congressData?.featureBlocks?.length > 0">
      <div class="container">
        <div class="features row mobile-full-width d-flex flex-wrap flex-column flex-md-row mt-4">
          <ng-container *ngIf="!congressMetaData.authorizedOnly">
            <div *ngFor="let feature of congressData.featureBlocks" class="col pb-5 not-full-width">
              <ng-container
                [ngTemplateOutlet]="featureTile"
                [ngTemplateOutletContext]="{ feature: feature, congressMetaData: congressMetaData }"
              ></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="congressMetaData.authorizedOnly">
            <div *ngFor="let feature of congressData.featureBlocks | slice : 0 : 2" class="col pb-5 not-full-width">
              <ng-container
                [ngTemplateOutlet]="featureTile"
                [ngTemplateOutletContext]="{ feature: feature, congressMetaData: congressMetaData }"
              ></ng-container>
            </div>
            <div class="col-12 col-lg-4 col-md-6 mt-md-n4">
              <div class="quickfacts card shadow-lg mt-md-n1 overflow-auto">
                <div
                  class="h-100 quickfacts-inner"
                  [style]="
                    !(congressParticipantData$ | async)?.isParticipant && congressMetaData.layout.colors.accent
                      ? 'background: var(--accent)'
                      : ''
                  "
                >
                  <h4>{{ 'Auth.Registration' | translate }}</h4>
                  <div class="d-flex icon-text">
                    <i [inlineSVG]="'assets/icons/calendar.svg'"></i>
                    <p>
                      {{ congressMetaData.startDate | date : 'dd.MM' }} -
                      {{ congressMetaData.endDate | date : 'dd.MM.yyyy' }}
                    </p>
                  </div>
                  <div class="d-flex icon-text" *ngIf="congressMetaData.congressCategory">
                    <i [inlineSVG]="'assets/icons/type.svg'"></i>
                    <p>{{ registrationService?.setEventType(congressMetaData) }}</p>
                  </div>
                  <div class="d-flex icon-text" *ngIf="congressMetaData.eventAddress">
                    <i [inlineSVG]="'assets/icons/location.svg'"></i>
                    <p [innerHTML]="congressMetaData.eventAddress"></p>
                  </div>
                  <div class="d-flex icon-text" *ngIf="expertsData$ | async">
                    <i [inlineSVG]="'assets/icons/users.svg'"></i>
                    <p>
                      {{ (expertsData$ | async).experts.length }}
                      {{ 'ExpertsPage.expert' | translate }}
                    </p>
                  </div>
                  <div class="d-flex icon-text" *ngIf="congressMetaData.language">
                    <i [inlineSVG]="'assets/icons/language.svg'"></i>
                    <p [innerHTML]="congressMetaData.language"></p>
                  </div>
                  <div
                    *ngIf="congressUtils.isAfterCongress(congressMetaData)"
                    class="d-flex info-line align-items-center"
                  >
                    <i [inlineSVG]="'assets/icons/time.svg'"></i>
                    <span class="pl-3">{{ 'Event.Over' | translate }}</span>
                  </div>
                  <div
                    *ngIf="
                      !congressUtils.isAfterCongress(congressMetaData) &&
                      (congressParticipantData$ | async)?.isParticipant
                    "
                    class="d-flex info-line align-items-center"
                  >
                    <i [inlineSVG]="'assets/icons/check.svg'"></i>
                    <span class="pl-3">{{ 'Event.AlreadyRegistered' | translate }}</span>
                  </div>
                  <div
                    *ngIf="
                      registrationService?.registrationClosed(congressMetaData) &&
                      !congressUtils.isAfterCongress(congressMetaData) &&
                      !(congressParticipantData$ | async)?.isParticipant
                    "
                    class="d-flex info-line align-items-center"
                  >
                    <i [inlineSVG]="'assets/icons/info.svg'"></i>
                    <span class="pl-3">{{ registrationService.registrationClosedText | translate }}</span>
                  </div>
                  <a
                    class="btn w-fit-content"
                    href="#registerModal"
                    draggable="false"
                    [class]="congressMetaData.layout.colors.accent ? 'ghost-button' : 'btn-danger'"
                    *ngIf="
                      !congressUtils.isAfterCongress(congressMetaData) &&
                      !(congressParticipantData$ | async)?.isParticipant &&
                      congressMetaData.authorizedOnly &&
                      registrationService.showSignUpBtn
                    "
                    data-toggle="modal"
                    (click)="registrationService.handleShowRegisterModal(true)"
                    role="button"
                  >
                    {{ 'Auth.SignUp' | translate }}
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </section>

    <!-- Experts - PatiensToday -->
    <ng-container *ifApp="'PatientsToday'" [ngTemplateOutlet]="experts"></ng-container>

    <app-event-register-modal
      *ifApp="'MedToday'"
      [congressMetaData]="congressMetaData"
      [user]="registrationService.user"
      (registrationCompleteEmitter)="registrationService.setHideRegisterModal()"
    ></app-event-register-modal>

    <!-- Teaser Cards -->
    <section id="teaser-cards" *ngIf="congressData?.teaserCards?.length > 0">
      <div class="container">
        <div class="row mt-4 mobile-full-width">
          <ng-container *ngFor="let teaserCard of congressData?.teaserCards">
            <div
              class="teaser-card-wrapper px-0 px-md-2 pb-0 pb-md-3"
              [ngClass]="teaserCard.isFullWidth ? 'col-12' : 'col-lg-6 col-xl-6 col-sm-12'"
              [hidden]="
                ((isLoggedIn$ | async) === false &&
                  teaserCard.design === 'SUBSCRIBE' &&
                  applicationName === 'MedToday') ||
                ((isLoggedIn$ | async) && teaserCard.design === 'REGISTER')
              "
            >
              <app-teaser-card
                [title]="teaserCard.title"
                [description]="teaserCard.description"
                [buttonLabel]="teaserCard.buttonLabel"
                [buttonUrl]="teaserCard.url"
                [cardImage]="teaserCard.cardImage"
                [type]="teaserCard.design"
                [congressBackgroundImage]="s3BucketUrl + congressMetaData.layout.backgroundImage"
                [slug]="congressSlug"
                [cardCongressTheme]="teaserCard.designCongressTheme"
                [badgeText]="teaserCard.tagText"
              ></app-teaser-card>
            </div>
          </ng-container>
        </div>
      </div>
    </section>

    <!-- SotA -->
    <section
      id="state-of-the-art"
      *ngIf="stateOfTheArtVideos.length > 0 && congressData.congressLandingPageVideos.length > 0"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4
              [attr.data-number]="stateOfTheArtVideos.length"
              [innerHTML]="congressData.congressLandingPageVideosHeader"
            ></h4>
          </div>
        </div>
      </div>
      <app-carousel-small
        [carouselItems]="stateOfTheArtVideos"
        [imageKeyField]="'thumbnail'"
        [coverImage]="true"
      ></app-carousel-small>
      <div class="container">
        <div class="divider my-4 w-100"></div>
      </div>
    </section>

    <!-- Patrons -->
    <ng-container
      *ngIf="
        congressData.patronsSection.title ||
        congressData.patronsSection.description ||
        congressData.patronsSection.patrons?.length > 0
      "
    >
      <section id="patrons">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-12 mb-4" *ngIf="congressData.patronsSection.title">
              <h4>{{ congressData.patronsSection.title }}</h4>
            </div>
          </div>
          <div class="row">
            <ng-container *ngIf="congressData.patronsSection.patrons?.length > 0">
              <div
                *ngFor="let patron of congressData.patronsSection.patrons"
                class="patron--wrapper col-lg-3 col-md-6 col-6 px-md-3"
              >
                <a
                  [href]="patron.externalPatronUrl"
                  target="_blank"
                  class="card rounded-lg w-100 h-100"
                  draggable="false"
                >
                  <img
                    *ngIf="patron.logo"
                    class="w-100 h-100"
                    draggable="false"
                    [src]="s3BucketUrl + thumbnailsImageFolderSlug + patron.logo"
                    alt="Schirmherr-Logo"
                  />
                </a>
              </div>
            </ng-container>
            <div class="col-12 mt-4" *ngIf="congressData.patronsSection.description">
              <p
                replaceToDownloadLink
                [innerHtml]="congressData.patronsSection.description"
                class="white-space-prewrap"
              ></p>
            </div>
          </div>
          <div class="divider my-4 w-100"></div>
        </div>
      </section>
    </ng-container>

    <!-- Experts - MedToday -->
    <ng-container *ifApp="'MedToday'" [ngTemplateOutlet]="experts"></ng-container>

    <!-- Sponsors -->
    <section id="sponsors" *ngIf="hasAnySponsor()">
      <div class="container text-center">
        <h2 *ngIf="congressData.sponsorsSection?.title">
          {{ congressData.sponsorsSection?.title }}
        </h2>
        <div class="row">
          <div class="col-12 mt-2">
            <p
              replaceToDownloadLink
              *ngIf="congressData.sponsorsSection?.description"
              [innerHtml]="congressData.sponsorsSection?.description"
              class="white-space-prewrap"
            ></p>
          </div>
        </div>
        <ng-container
          [ngTemplateOutlet]="sponsorTile"
          [ngTemplateOutletContext]="{
            sponsors: congressData.sponsorsSection?.firstLevelSponsors,
            level: sponsorLevel.platin
          }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="sponsorTile"
          [ngTemplateOutletContext]="{
            sponsors: congressData.sponsorsSection?.secondLevelSponsors,
            level: sponsorLevel.gold
          }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="sponsorTile"
          [ngTemplateOutletContext]="{
            sponsors: congressData.sponsorsSection?.thirdLevelSponsors,
            level: sponsorLevel.silber
          }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="sponsorTile"
          [ngTemplateOutletContext]="{
            sponsors: congressData.sponsorsSection?.fourthLevelSponsors,
            level: sponsorLevel.bronze
          }"
        >
        </ng-container>
        <div
          class="d-flex flex-column flex-fill my-5"
          *ngIf="congressData.sponsorsSection?.associateSponsors?.length > 0"
        >
          <h5 class="level-headline" *ifApp="'MedToday'">
            {{ congressData.sponsorsSection.associateSponsors[0].level | translate }}
          </h5>
          <h5 class="level-headline" *ifApp="'PatientsToday'">{{ 'Sponsors.Bronze' | translate }}</h5>
          <div class="row d-flex mt-0 mt-md-4 justify-content-center px-2 sponsor-wrapper">
            <div
              *ngFor="let sponsor of congressData.sponsorsSection?.associateSponsors"
              class="col-12 col-sm-4 p-0 p-md-2 sponsor-level-4"
            >
              <a
                target="_blank"
                draggable="false"
                [href]="sponsor.link"
                *ngIf="sponsor.link"
                [class.text-dark]="applicationName === 'PatientsToday'"
                class="card sponsor rounded-lg text-light justify-content-center"
              >
                <h6 class="m-0">{{ sponsor.name }}</h6>
              </a>
              <div
                [class.text-dark]="applicationName === 'PatientsToday'"
                *ngIf="!sponsor.link"
                class="card sponsor rounded-lg text-light justify-content-center"
              >
                <h6 class="m-0">{{ sponsor.name }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Partners network -->
    <section id="partners-network" *ngIf="congressData.partnersSection.partners?.length">
      <div class="container text-center">
        <div class="divider w-100"></div>
        <div class="d-flex flex-column flex-fill my-5">
          <div class="col-12 mb-4" *ngIf="congressData.partnersSection.title">
            <h4>{{ congressData.partnersSection.title }}</h4>
          </div>
          <div class="row d-flex mt-4 justify-content-center px-2">
            <ng-container *ngIf="congressData.partnersSection.partners.length > 0">
              <div
                *ngFor="let partner of congressData.partnersSection.partners"
                class="
              col-{{ calculateColumnsWidth(congressData.partnersSection.partnersPerRow) }}
              col-sm-{{ calculateColumnsWidth(congressData.partnersSection.partnersPerRow) }}
              p-2"
              >
                <a
                  target="_blank"
                  draggable="false"
                  href="{{ partner.link }}"
                  class="card sponsor rounded-lg text-light justify-content-center"
                >
                  <img
                    class="w-100"
                    draggable="false"
                    [src]="s3BucketUrl + logosImageFolderSlug + partner.logo"
                    alt="partner-logo"
                  />
                </a>
              </div>
            </ng-container>
            <div class="col-12 mt-4 text-left" *ngIf="congressData.partnersSection.description">
              <p
                replaceToDownloadLink
                [innerHtml]="congressData.partnersSection.description"
                class="white-space-prewrap"
              ></p>
            </div>
          </div>
        </div>
        <div class="divider w-100"></div>
      </div>
    </section>
  </ng-container>

  <!-- Twitter -->
  <ng-container *ifApp="'MedToday'">
    <app-mt-social-wall></app-mt-social-wall>
  </ng-container>

  <!-- Patientstoday social wall -->
  <ng-container *ifApp="'PatientsToday'">
    <app-pt-social-wall></app-pt-social-wall>
  </ng-container>
</ng-container>
<app-spacer></app-spacer>

<!-- congress banner if position equals "bottom" -->
<ng-container *ngIf="congressMetaData$ | async as congressMetaData">
  <ng-container *ngIf="congressMetaData.layout?.banner?.position === 'bottom'">
    <app-congress-banner
      [congressLogo]="congressMetaData.layout?.banner?.logo_left"
      [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
      [disclaimer]="congressMetaData.layout?.banner?.text"
    ></app-congress-banner>
  </ng-container>
</ng-container>

<app-scroll-to-top-button [ngClass]="hasScrolled() ? 'd-block' : 'd-none'"></app-scroll-to-top-button>

<app-async-state-overlay [processKey]="loadDataAsyncKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="loadOnDemandVideoDataAsyncKey"></app-async-state-overlay>

<ng-template #featureTile let-feature="feature" let-congressMetaData="congressMetaData">
  <div class="h-100">
    <img
      *ngIf="feature.image"
      draggable="false"
      class="mb-3"
      [src]="s3BucketUrl + thumbnailsImageFolderSlug + feature.image"
    />
    <h4 class="card-title" [class.invisible]="!feature.title">{{ feature.title }} &nbsp;</h4>
    <p class="card-text">{{ feature.description }}</p>
    <div class="mt-2">
      <a
        *ngIf="feature.url !== 'keine Auswahl' && feature.linkName"
        class="btn btn-primary"
        draggable="false"
        (click)="handleFeatureLinkClick(feature)"
        >{{ feature.linkName }}</a
      >
    </div>
  </div>
</ng-template>

<ng-template #sponsorTile let-sponsors="sponsors" let-level="level">
  <div class="d-flex flex-column flex-fill mt-4 mt-md-5" *ngIf="sponsors?.length > 0">
    <h2 class="level-headline" *ngIf="level === sponsorLevel.platin">
      {{ sponsors[0].level | translate }}
    </h2>
    <h5 class="level-headline" *ngIf="level !== sponsorLevel.platin">
      {{ sponsors[0].level | translate }}
    </h5>
    <div class="row mt-md-4 mt-0 d-flex align-items-center justify-content-center sponsor-wrapper">
      <div
        *ngFor="let sponsor of sponsors"
        class="col-6 pb-0 pb-sm-3 sponsor-level-{{ level }} align-self-start"
        [class.col-sm-4]="level !== sponsorLevel.platin"
        [class.col-lg-3]="level !== sponsorLevel.platin"
        [class.col-lg-4]="level === sponsorLevel.platin"
      >
        <ng-container *ngIf="sponsor.link; else noLink">
          <a [href]="sponsor.link" target="_blank" draggable="false" class="card rounded-lg text-dark">
            <img
              class="w-100"
              *ngIf="sponsor.logo"
              draggable="false"
              [src]="s3BucketUrl + logosImageFolderSlug + sponsor.logo"
              alt="Sponsor-Logo"
            />
          </a>
        </ng-container>
        <ng-template #noLink>
          <div class="card rounded-lg text-dark">
            <img
              class="w-100"
              *ngIf="sponsor.logo"
              draggable="false"
              [src]="s3BucketUrl + logosImageFolderSlug + sponsor.logo"
              alt="Sponsor-Logo"
            />
          </div>
        </ng-template>
        <app-sponsor-more-info-link
          *ngIf="isLoggedIn$ | async"
          [congressSlug]="congressSlug"
          [sponsor]="sponsor"
        ></app-sponsor-more-info-link>
      </div>
    </div>
  </div>
</ng-template>
