import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TeaserCardDesigns } from 'medtoday-models-library/lib/models';
import {
  GoToEditUser,
  GoToLogin,
  GoToSignUp
} from 'projects/medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';
import { imagePaths } from 'projects/medtoday/src/environments/environment';
import { take, tap } from 'rxjs/operators';

import { isLoggedIn } from '../../../core/auth/selectors/auth.selectors';
import {
  GoToCMEContent,
  GoToFeaturedContent,
  GoToNewsletter
} from '../../../core/router/actions/main-navigation.actions';
import { BaseAppState } from '../../../core/store/reducers';
import { ObservableComponent } from '../observable/observable.component';

enum CardSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

@Component({
  selector: 'app-teaser-card',
  templateUrl: './teaser-card.component.html',
  styleUrls: ['./teaser-card.component.scss']
})
export class TeaserCardComponent extends ObservableComponent implements AfterViewInit {
  private _type: TeaserCardDesigns;
  @Input()
  set type(value) {
    // @ts-ignore
    this._type = TeaserCardDesigns[value];
  }

  get type(): TeaserCardDesigns {
    return this._type;
  }

  get getBadgeText(): string {
    if (this.badgeText) {
      return this.badgeText;
    }

    if (this.isCMECard) {
      return 'CME Punkte';
    }

    return 'Sponsor';
  }

  @Input() title = '';
  @Input() description = '';
  @Input() buttonLabel = '';
  @Input() congressBackgroundImage = '';
  @Input() slug = '';
  @Input() cardImage = null;
  @Input() badgeText = null;
  @Input() cardCongressTheme = null;
  @Input() buttonUrl = '';

  readonly mediumBreakpoint = 520;
  readonly largeBreakpoint = 800;

  readonly logoUrl = imagePaths.logos;

  private isLoggedIn$ = this.store.select(isLoggedIn);
  public cardSize = CardSize.Large;

  get cardImageFullUrl() {
    return this.s3BucketUrl + this.logoUrl + this.cardImage;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const offsetWidth = this.element.nativeElement.children[0].offsetWidth;
    if (offsetWidth <= this.mediumBreakpoint) {
      this.cardSize = CardSize.Small;
    } else if (offsetWidth > this.mediumBreakpoint && offsetWidth <= this.largeBreakpoint) {
      this.cardSize = CardSize.Medium;
    } else {
      this.cardSize = CardSize.Large;
    }
  }

  constructor(
    private element: ElementRef,
    private store: Store<BaseAppState>,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string,
    private router: Router
  ) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    });
  }

  handleTeaserCardClick(type: TeaserCardDesigns) {
    if (this.buttonUrl) {
      try {
        const url = new URL(this.buttonUrl);
        if (url.hostname === window.location.hostname) {
          return this.router.navigateByUrl(url.pathname);
        }
      } catch (e) {
        console.log(e);
      }

      window.open(this.buttonUrl, '_blank')?.focus();
      return;
    }

    switch (type) {
      case TeaserCardDesigns.REGISTER:
        this.store.dispatch(new GoToSignUp());
        break;
      case TeaserCardDesigns.SUBSCRIBE:
        if (this.applicationName === 'PatientsToday') {
          this.store.dispatch(new GoToNewsletter());
        } else {
          this.isLoggedIn$
            .pipe(
              take(1),
              tap(isLogged => {
                if (isLogged) {
                  this.store.dispatch(new GoToEditUser({ scrollToNewsletter: true }));
                } else {
                  this.store.dispatch(new GoToLogin());
                }
              })
            )
            .subscribe();
        }
        break;
      case TeaserCardDesigns.CME:
        this.store.dispatch(new GoToCMEContent(this.slug));
        break;
      case TeaserCardDesigns.FEATURE:
        this.store.dispatch(new GoToFeaturedContent(this.slug));
        break;
      default:
        break;
    }
  }

  handleGoToLoginClick() {
    this.store.dispatch(new GoToLogin());
  }

  get isFeatureCard() {
    return this.type === TeaserCardDesigns.FEATURE;
  }

  get isRegisterCard() {
    return this.type === TeaserCardDesigns.REGISTER;
  }

  get isSubscribeCard() {
    return this.type === TeaserCardDesigns.SUBSCRIBE;
  }

  get isCMECard() {
    return this.type === TeaserCardDesigns.CME;
  }

  get isQRCard() {
    return this.type === TeaserCardDesigns.QR;
  }

  get isGenericCard() {
    // @ts-ignore
    return this.type === TeaserCardDesigns.GENERIC;
  }

  get isNoStyleCard() {
    return this.type === TeaserCardDesigns.NONE;
  }
}
