import { Action } from '@ngrx/store';
import { SignUpRequest } from '../../../../../todaylib/shared/models';
import { EditUserPageData } from '../../../../../todaylib/core/auth/models/edit-user-page-data.model';

export enum AuthUINavigationActionTypes {
  GoToLogin = '[Router] Navigate to login',
  GoToSignUp = '[Router] Navigate to sign up',
  GoToRequestPasswordReset = '[Router] Navigate to request password reset',
  GoToSignUpSuccess = '[Router] Navigate to sign up success',
  GoToEditPassword = '[Router] Navigate to edit password',
  GoToEditUser = '[Router] Navigate to edit user'
}

export class GoToLogin implements Action {
  readonly type = AuthUINavigationActionTypes.GoToLogin;
  constructor(public reload = false) {}
}

export class GoToSignUp implements Action {
  readonly type = AuthUINavigationActionTypes.GoToSignUp;
  constructor(public userData?: Partial<SignUpRequest>, public step?: number) {}
}

export class GoToSignUpSuccess implements Action {
  readonly type = AuthUINavigationActionTypes.GoToSignUpSuccess;
}

export class GoToRequestPasswordReset implements Action {
  readonly type = AuthUINavigationActionTypes.GoToRequestPasswordReset;
}

export class GoToEditPassword implements Action {
  readonly type = AuthUINavigationActionTypes.GoToEditPassword;
}

export class GoToEditUser implements Action {
  readonly type = AuthUINavigationActionTypes.GoToEditUser;

  constructor(public data?: EditUserPageData) {}
}

export type AuthUINavigationActions =
  | GoToLogin
  | GoToSignUp
  | GoToSignUpSuccess
  | GoToRequestPasswordReset
  | GoToEditPassword
  | GoToEditUser;
