import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OnDemandVideoCrossPlatformSearchResultResponse } from 'medtoday-models-library';

@Injectable()
export class SearchService {
  constructor(@Inject('apiBaseUrl') protected apiBaseUrl: string, protected http: HttpClient) {}

  search(searchTerm: string, nextPage: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('searchTerm', searchTerm);
    queryParams = queryParams.set('page', nextPage);

    return this.http.get<OnDemandVideoCrossPlatformSearchResultResponse>(
      `${this.apiBaseUrl}/onDemand/crossPlatformSearch?` + queryParams.toString()
    );
  }
}
