import { ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { BaseAppState } from './index';

export function localStorageSyncReducer(reducer: ActionReducer<BaseAppState>): ActionReducer<BaseAppState> {
  return localStorageSync({
    keys: ['auth'],
    storageKeySerializer: (key) => `medtoday_${key}`,
    rehydrate: true,
    storage: localStorage,
  })(reducer);
}
