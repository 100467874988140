<header>
  <div
    *ngIf="onDemandVideo$ | async as video"
    [ngClass]="video.hasQuestionsAndAnswers ? 'container-fluid width-90' : 'container'"
  >
    <div class="row">
      <div class="col-12" [class.col-md-8]="video.hasElearning">
        <div
          class="row mobile-full-width"
          *ngIf="video.videoType !== 'researchContribution' && congressMetaData$ | async as congressMetaData"
        >
          <div
            [ngClass]="{
              'col-xl-4 col-lg-5': congressMetaData.mode !== 'MedEd',
              'col-xl-3 col-lg-3': congressMetaData.mode === 'MedEd'
            }"
            class="on-demand col-12 h-100 mb-3 pr-sm-2 hide-tablet"
          >
            <app-navigation-menu
              *ngIf="congressMetaData.mode !== 'MedEd' && applicationName === 'MedToday'"
            ></app-navigation-menu>
            <patients-navigation-menu *ngIf="applicationName === 'PatientsToday'"></patients-navigation-menu>
            <button
              class="btn btn-danger h-100 w-100"
              *ngIf="congressMetaData.mode === 'MedEd' && !congressMetaData.feedOnDemandVideoId"
              (click)="handleGoToCongressClick(congressMetaData.slug, congressMetaData.mode)"
            >
              {{ 'Event.Overview' | translate }}
            </button>
          </div>
          <div
            [ngClass]="{
              'col-xl-8 col-lg-7 pl-lg-2': congressMetaData.mode !== 'MedEd' && congressMetaData.menuOptions.length > 0,
              'col-xl-9 col-lg-9 pl-lg-2': congressMetaData.mode === 'MedEd' && !congressMetaData.feedOnDemandVideoId
            }"
            class="w-100 mb-3 col-12 dropdown-container"
          >
            <div
              *ngIf="video.isVideo && (isKnowledgeSeriesVideo$ | async) === false"
              class="rounded d-flex h-100 w-100 align-items-center justify-content-between navigation-menu"
            >
              <ng-container *ngIf="!video.hasElearning">
                <p class="display-6 pl-3 m-0 font-weight-light" *ngIf="video.topic?.title">
                  {{ video.title ? video.title : video.topic.title }}
                </p>
              </ng-container>
              <div class="dropdown w-100 h-100" *ngIf="video.hasElearning">
                <button
                  class="btn d-flex align-items-center justify-content-between navigation-menu dropdown-toggle text-light w-100 h-100 position-relative py-0"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <h6 class="text-left m-0 w-100 d-flex flex-row align-items-center">
                    {{ video.title ? video.title : video.topic.title }}
                  </h6>
                  <i class="bi bi-chevron-down arrow-down"></i>
                </button>
                <div class="dropdown-menu custom-scrollbar-transparent w-100" aria-labelledby="select">
                  <div>
                    <a
                      class="dropdown-item d-flex flex-row align-items-center text-white"
                      *ngFor="let congressVod of video.congressOnDemandVideos"
                      [class.text-primary]="congressVod.id === video.id"
                      (click)="handleGoToVideoClick(congressVod.id)"
                    >
                      {{ congressVod.title ? congressVod.title : congressVod.topic.title }}
                    </a>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!video.topic?.title && video.sponsors?.length">
                <p class="display-6 pl-3 m-0 font-weight-light mr-2" *ngFor="let sponsor of video.sponsors">
                  {{ sponsor?.name }}
                </p>
              </ng-container>
            </div>
            <div *ngIf="!video.isVideo && video.timeSlot && video.topic" class="mt-2">
              <h5 class="m-0">{{ video.topic.title }}</h5>
              <p class="text-primary font-weight-light">
                {{ video.timeSlot?.start | date: 'EEEE, dd.MM.yyyy' }}
              </p>
            </div>
            <p></p>
          </div>
        </div>
        <div class="row mobile-full-width">
          <div class="col-md-12 col-sm-12 col-12 h-100 mb-3">
            <ng-container
              *ngIf="
                video.playerIdentifier &&
                video.mediaIdentifier &&
                video.isVideo &&
                ((congressMetaData$ | async)?.mediaProvider === 'JW' || video.mediaProvider === 'JW')
              "
            >
              <app-jw-player
                #player
                [hideControls]="video.hasElearning"
                [mediaId]="video.mediaIdentifier"
                [playerId]="video.playerIdentifier"
                [initialPosition]="(onDemandVideoUserData$ | async)?.position"
                [autostart]="!!(isKnowledgeSeriesVideo$ | async)"
                (position)="handlePositionUpdate($event)"
                (languages)="onLanguagesUpdate($event)"
                (videoWatched)="onVideoWatched(video.id)"
                (loaded)="onWidgetSetupCompleted()"
              ></app-jw-player>
            </ng-container>
            <ng-container
              *ngIf="
                video.mediaIdentifier &&
                video.isVideo &&
                ((congressMetaData$ | async)?.mediaProvider === 'VIMEO' || video.mediaProvider === 'VIMEO')
              "
            >
              <app-vimeo-player
                #player
                [mediaId]="video.mediaIdentifier"
                (position)="handlePositionUpdate($event)"
                (videoWatched)="onVideoWatched(video.id)"
                [initialPosition]="(onDemandVideoUserData$ | async)?.position"
                (loaded)="onWidgetSetupCompleted()"
              ></app-vimeo-player>
            </ng-container>
            <ng-container
              *ngIf="
                video.mediaIdentifier &&
                video.isVideo &&
                ((congressMetaData$ | async)?.mediaProvider === 'YT' || video.mediaProvider === 'YT')
              "
            >
              <app-youtube-player
                #player
                [mediaId]="video.mediaIdentifier"
                (loaded)="onWidgetSetupCompleted()"
              ></app-youtube-player>
            </ng-container>
            <ng-container *ngIf="video.reactLink && video.reactLink !== '' && !video.isVideo">
              <app-react-wrapper [playbackLink]="video.reactLink"></app-react-wrapper>
            </ng-container>
          </div>
        </div>
        <div class="row d-flex align-items-center justify-content-between" *ngIf="video.isVideo">
          <div class="mb-3 mb-md-0 col-12 d-flex flex-column flex-lg-row align-items-lg-center justify-content-between">
            <h5 class="mb-0 px-0" *ngIf="video.videoType !== videoType.researchContribution && video.subtitle">
              {{ video.subtitle }}
            </h5>
            <h5 class="mb-0 px-0" *ngIf="video.videoType === videoType.researchContribution">{{ video.title }}</h5>
            <h5 class="mb-0 px-0" *ngIf="!video.subtitle">
              {{ video.format?.title }}
              {{ video.timeSlot ? ('VideoOnDemand.from' | translate) : '' }}
              {{ video.timeSlot?.start | date: 'EEEE, dd.MM.yyyy' }}
            </h5>
            <div class="d-flex justify-content-end">
              <app-social-share-button
                class="hide-tablet"
                size="small"
                [class.congress-colors]="applicationName === 'PatientsToday'"
              ></app-social-share-button>

              <div
                class="dropdown language-list align-items-center secondary mt-3 mt-lg-0"
                *ngIf="languageList?.length > 1"
              >
                <button
                  class="btn d-flex align-items-center justify-content-between navigation-menu dropdown-toggle text-light w-100 px-3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <h6 class="text-left m-0 w-100 text-capitalize">
                    {{
                      selectedLanguage
                        ? ('VideoOnDemand.' + (selectedLanguage | uppercase) | translate)
                        : ('Common.Caption' | translate)
                    }}
                  </h6>
                  <div class="icon-16 arrow-indicator" [inlineSVG]="'assets/icons/arrow-down.svg'"></div>
                </button>
                <div class="dropdown-menu dropdown-small-gap bg-dark w-100" aria-labelledby="select">
                  <a
                    *ngFor="let lang of languageList; let i = index"
                    class="dropdown-item text-light text-primary"
                    (click)="handleCaptionSelect(i)"
                    >{{ 'VideoOnDemand.' + ((lang.language ? lang.language : lang.label) | uppercase) | translate }}</a
                  >
                </div>
              </div>
              <ng-container *ngIf="video.attachments?.length">
                <div class="btn-pdfs-container mt-3 mt-lg-0">
                  <a
                    class="btn btn-pdf-menu justify-content-between d-flex"
                    [class.btn-dark]="applicationName === 'MedToday'"
                    [class.text-dark]="applicationName === 'PatientsToday'"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                  >
                    <span>{{ 'VideoOnDemand.Attachments' | translate }}</span>
                    <div
                      class="download-link d-flex align-items-center"
                      [inlineSVG]="'assets/icons/anchor-download.svg'"
                    ></div>
                  </a>
                  <div class="border-light dropdown-menu shadow-lg px-2" aria-labelledby="select">
                    <div class="attachment d-flex align-items-center" *ngFor="let attachment of video.attachments">
                      {{ attachment.name }}
                      <a
                        class="d-flex btn btn-sm btn-pdf ml-auto text-uppercase"
                        [class.btn-dark]="applicationName === 'MedToday'"
                        [class.text-dark]="applicationName === 'PatientsToday'"
                        (click)="handlePdfDownloadClick(attachment.url)"
                        target="_blank"
                        [href]="s3BucketUrl + pdfImageUrl + attachment.url"
                      >
                        {{ attachment.extension }}
                        <i
                          class="download-link d-flex align-self-center ml-auto"
                          [inlineSVG]="'assets/icons/anchor-download.svg'"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-4 mt-md-0 cme-learning-container p-3 h-100" *ngIf="video.hasElearning">
        <div class="p-2">
          <h4>{{ 'VideoOnDemand.CmeLearningTitle' | translate }}</h4>
          <p class="font-weight-normal">
            {{ 'VideoOnDemand.CmeLearningDesc' | translate }}
          </p>
          <div
            *ngIf="(eLearningUserMetadata$ | async)?.passed || (isUserBlacklisted$ | async)"
            class="d-flex info-line align-items-center mt-4 py-3 px-4 px-lg-5 mb-n4"
          >
            <ng-container *ngIf="!(eLearningUserMetadata$ | async)?.passed && (isUserBlacklisted$ | async)">
              <i class="d-inline-flex bi bi-x-circle"></i>
              <span class="pl-3 pt-1" *ngIf="(blacklistedDaysLeft$ | async) !== 0">
                {{
                  'VideoOnDemand.CmeLearningBlacklisted'
                    | translate: { blacklistedUntil: (blacklistedDaysLeft$ | async) }
                }}
              </span>
              <span class="pl-3 pt-1" *ngIf="(blacklistedDaysLeft$ | async) === 0">
                Sie dürfen die Fragen in einem Tag wiederholen
              </span>
            </ng-container>
            <ng-container *ngIf="(eLearningUserMetadata$ | async)?.passed">
              <i [inlineSVG]="'assets/icons/check.svg'"></i>
              <span class="pl-3 pt-1">{{ 'VideoOnDemand.CmeLearningCompleted' | translate }}</span>
            </ng-container>
          </div>
          <button
            class="btn btn-primary"
            draggable="false"
            *ngIf="!(eLearningUserMetadata$ | async)?.passed && (isUserBlacklisted$ | async) === false"
            [tooltipEnable]="(isUserBlacklisted$ | async) === true"
            tooltip="Sie können die Fragen zu diesem Video in {{ blacklistedDaysLeft$ | async }} Tagen beantworten."
            [disabled]="!(onDemandVideoUserData$ | async)?.watched || (isUserBlacklisted$ | async) === true"
            (click)="handleGoToElearning(video.id)"
          >
            {{ 'VideoOnDemand.CmeLearningBtnLabel' | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="video.hasQuestionsAndAnswers" class="col-xl-4 col-lg-4 col-12 qa-box">
        <form [formGroup]="qaForm" name="qa-form" (ngSubmit)="handleSendQuestionClick()" #ngForm="ngForm" novalidate>
          <div class="form-group row">
            <div class="col-12">
              <label>{{ 'VideoOnDemand.YourName' | translate }}</label>
              <input
                formControlName="name"
                type="text"
                class="form-control form-control-lg"
                [class.patients-form]="applicationName === 'PatientsToday'"
                placeholder="{{ 'VideoOnDemand.EnterName' | translate }}"
                [class.is-invalid]="ngForm.submitted && qaForm.controls['name'].invalid"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <label>{{ 'Auth.Email' | translate }}*</label>
              <input
                formControlName="email"
                type="text"
                class="form-control form-control-lg"
                [class.patients-form]="applicationName === 'PatientsToday'"
                placeholder="{{ 'SignUp.EnterEmailAddress' | translate }}"
                [class.is-invalid]="ngForm.submitted && qaForm.controls['email'].invalid"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <label>{{ 'VideoOnDemand.Question' | translate }}</label>
              <textarea
                formControlName="message"
                rows="5"
                type="text"
                class="form-control form-control-lg"
                [class.patients-form]="applicationName === 'PatientsToday'"
                placeholder="{{ 'VideoOnDemand.EnterQuestion' | translate }}"
              ></textarea>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <div class="data-privacy custom-control custom-checkbox text-small">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="acknowledgeDataProtectionInformationCheckbox"
                  formControlName="hasAcknowledgedDataProtectionInformation"
                  [class.is-invalid]="
                    ngForm.submitted && qaForm.controls['hasAcknowledgedDataProtectionInformation'].invalid
                  "
                  [class.invalid]="
                    ngForm.submitted && qaForm.controls['hasAcknowledgedDataProtectionInformation'].invalid
                  "
                />
                <label
                  class="custom-control-label pl-3"
                  for="acknowledgeDataProtectionInformationCheckbox"
                  *ngIf="video.privacyDeclaration"
                  [innerHtml]="video.privacyDeclaration"
                ></label>
              </div>
              <div
                class="invalid-feedback d-block"
                [class.d-block]="
                  ngForm.submitted && qaForm.controls['hasAcknowledgedDataProtectionInformation'].invalid
                "
              >
                <div *ngIf="qaForm.controls['hasAcknowledgedDataProtectionInformation'].hasError('required')">
                  {{ 'HasAcknDataProtection' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-secondary d-flex" type="submit">
                {{ 'VideoOnDemand.SubmitQuestion' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</header>
<div
  [ngClass]="video.hasQuestionsAndAnswers ? 'container-fluid width-90' : 'container'"
  class="mb-3 related-container"
  *ngIf="onDemandVideo$ | async as video"
>
  <div class="row mt-3" *ngIf="video.isVideo">
    <div
      [ngClass]="video.isVideo && video.hasQuestionsAndAnswers ? 'col-xl-6 col-lg-6 offset-lg-1' : 'col-xl-7 col-lg-7'"
    >
      <app-video-on-demand-tabs [videoWidgetReady]="videoWidgetReady" [player]="player"></app-video-on-demand-tabs>
    </div>
    <div class="col-12 col-lg-5 flex-wrap mt-17">
      <app-sponsors-box
        *ngIf="video.sponsors?.length"
        [sponsors]="video.sponsors"
        [showCategories]="video.showSponsorsCategories"
        [sponsorAreaTitle]="video.sponsorAreaTitle"
      >
      </app-sponsors-box>
    </div>
  </div>
</div>
<app-spacer></app-spacer>
<ng-container *ngIf="onDemandVideo$ | async as video">
  <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
    <ng-container *ngIf="congressMetaData.layout?.banner?.position !== 'none' && !video.sponsors?.length">
      <app-congress-banner
        [congressLogo]="congressMetaData.layout?.banner?.logo_left"
        [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
        [disclaimer]="congressMetaData.layout?.banner?.text"
        [onBottom]="true"
      ></app-congress-banner>
    </ng-container>
  </ng-container>
</ng-container>
<app-async-state-overlay (dismiss)="goBack()" [processKey]="loadOnDemandVideoAsyncKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="sendOnDemandVideoQuestionAsyncKey"></app-async-state-overlay>
