import {Component, Inject, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getCongressMetaData} from '../data/selectors/data-api.selectors';
import {BaseAppState} from '../store/reducers';
import {ObservableComponent} from '../../shared/components/observable/observable.component';
import {ActivationStart, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {getCongressSlugRouterParam} from '../router/selectors/router.selectors';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent extends ObservableComponent implements OnInit {
  congressMetaData$ = this.store.pipe(select(getCongressMetaData));
  congressSlug$ = this.store.pipe(select(getCongressSlugRouterParam));
  congressBackgroundUrl = '';
  hideHeader = false;
  hideNavigation = false;

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private store: Store<BaseAppState>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([this.router.events, this.congressMetaData$, this.congressSlug$])
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(([routerEvents, metaData, congressSlug]) => {
        if (routerEvents instanceof ActivationStart && routerEvents.snapshot) {
          this.hideHeader = routerEvents.snapshot.data?.hideHeader;
          this.hideNavigation = routerEvents.snapshot.data?.hideNavigation;
        }

        if (!this.hideHeader) {
          if (metaData && metaData.layout?.backgroundImage) {
            this.congressBackgroundUrl = this.s3BucketUrl + metaData.layout.backgroundImage;
          } else {
            this.congressBackgroundUrl = '';
          }

          if (!this.hideNavigation) {
            // hide navigation if there is no congress context
            this.hideNavigation = !congressSlug;
          }
        }
      });
  }
}

