import { Component, Inject, Input } from '@angular/core';
import { PartnerListItem } from 'medtoday-models-library/lib';
import { GoToPartnerDetails } from '../../../core/router/actions/main-navigation.actions';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../core/store/reducers';
import { imagePaths } from '../../../../medtoday/src/environments/environment';

@Component({
  selector: 'app-partners-box',
  templateUrl: './partners-box.component.html',
  styleUrls: ['./partners-box.component.scss']
})
export class PartnersBoxComponent {
  @Input() partners: PartnerListItem[];
  logosImageFolderSlug = imagePaths.logos;

  constructor(protected store: Store<BaseAppState>, @Inject('s3BucketUrl') public s3BucketUrl: string) {}

  handlePartnerBoothClick(partner: PartnerListItem) {
    if (partner.hasDetailPage) {
      this.store.dispatch(new GoToPartnerDetails(partner.slug));
    } else if (partner.link) {
      window.location.href = partner.link;
    }
  }

  handlePartnerUrlClick(partner: PartnerListItem) {
    if (partner.link) {
      window.location.href = partner.link;
    } else {
      this.store.dispatch(new GoToPartnerDetails(partner.slug));
    }
  }
}
