import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AsyncStateEffects } from './effects/async-state.effects';
import { reducer } from './reducers/async-state.reducers';
import { AsyncStateOverlayComponent } from './components/async-state-overlay/async-state-overlay.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
  declarations: [AsyncStateOverlayComponent, SpinnerComponent],
  exports: [AsyncStateOverlayComponent, SpinnerComponent]
})
export class AsyncStateOverlayModule {}

@NgModule({
  imports: [StoreModule.forFeature('asyncState', reducer), EffectsModule.forFeature([AsyncStateEffects])]
})
export class AsyncStateModule {}
