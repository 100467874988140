<div class="container" *ngIf="activeSession">
  <div
    class="modal fade"
    id="consentDialog"
    tabindex="-1"
    aria-labelledby="consentDialogModalLabel"
    aria-focus="true"
    aria-hidden="true"
    aria-modal="true"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal">
      <div class="modal-content bg-dark">
        <div class="modal-header justify-content-end">
          <button
            type="button"
            class="text-light btn p-2 d-flex align-items-center justify-content-center font-size-larger"
            data-dismiss="modal"
            aria-label="Close"
            (click)="handleNoConsentClick()"
            [inlineSVG]="'assets/icons/accordion-close.svg'"
          ></button>
        </div>
        <div class="modal-body p-4 mx-5 mb-5">
          <div class="row mb-2" *ngIf="channel?.sponsors?.length">
            <div class="col-12">
              <h4>{{ 'ChannelPage.MyConsent' | translate }}</h4>
              <br />
              <p
                class="small"
                [translate]="'ChannelPage.Consent'"
                [translateParams]="{
                  sponsorName: channel?.sponsors[0]?.name,
                  sessionFormat: activeSession?.format,
                  sessionTitle: activeSession?.title
                }"
              ></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3">
              <button class="w-100 btn btn-danger rounded-pill" (click)="handleConsentClick()">
                {{ 'ChannelPage.AgreeNow' | translate }}
              </button>
            </div>
            <div class="col-12">
              <button class="w-100 btn btn-dark rounded-pill" (click)="handleNoConsentClick()">
                {{ 'ChannelPage.Abort' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="backdrop" id="backdrop" style="display: none"></div>
</div>
<header>
  <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
    <ng-container *ngIf="congressMetaData.layout.backgroundImage">
      <img draggable="false" class="teaser-image" [src]="s3BucketUrl + congressMetaData.layout.backgroundImage" />
    </ng-container>
    <ng-container *ngIf="channel">
      <div class="container">
        <div class="row h-50 mb-5 mobile-full-width">
          <div
            [ngClass]="[
              !channelHasCommunicators ? 'col-lg-12' : 'col-lg-8',
              channel.isZoomChannel || channelHasCommunicators ? 'offset-lg-0' : ''
            ]"
            class="pr-lg-2 mt-4 p-0 px-sm-3"
          >
            <div class="row mx-0 mx-sm-n3 mobile-full-width">
              <div
                [ngClass]="{
                  'col-xl-4 col-lg-4': !channelHasCommunicators && congressMetaData.mode !== 'MedEd',
                  'col-xl-5 col-lg-6': channelHasCommunicators && congressMetaData.mode !== 'MedEd',
                  'col-xl-3 col-lg-3': channelHasCommunicators && congressMetaData.mode === 'MedEd',
                  'col-xl-2 col-lg-2': !channelHasCommunicators && congressMetaData.mode === 'MedEd'
                }"
                class="col-4 w-100 mb-3 d-none d-md-block"
              >
                <app-navigation-menu *ngIf="congressMetaData.mode !== 'MedEd'"></app-navigation-menu>
                <button
                  class="btn btn-danger h-100 w-100"
                  *ngIf="congressMetaData.mode === 'MedEd'"
                  (click)="handleGoToCongressClick(congressMetaData.slug, congressMetaData.mode)"
                >
                  {{ 'Event.Overview' | translate }}
                </button>
              </div>
              <div
                [ngClass]="{
                  'col-xl-8 col-lg-8': !channelHasCommunicators && congressMetaData.mode !== 'MedEd',
                  'col-xl-7 col-lg-6': channelHasCommunicators && congressMetaData.mode !== 'MedEd',
                  'col-xl-9 col-lg-9': channelHasCommunicators && congressMetaData.mode === 'MedEd',
                  'col-xl-10 col-lg-10': !channelHasCommunicators && congressMetaData.mode === 'MedEd'
                }"
                class="col-12 col-md-8 w-100 mb-3 pl-lg-0 pl-md-3 p-0 px-sm-3"
              >
                <div class="dropdown h-100">
                  <button
                    *ngIf="channel.channelNavigationItems.length !== 1"
                    class="btn d-flex align-items-center justify-content-between navigation-menu dropdown-toggle text-light w-100 h-100 position-relative"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <h6 class="text-left m-0 w-100 d-flex flex-row align-items-center">
                      <p class="m-0">
                        <ng-container *ngIf="hasTitle && !channel.isZoomChannel">
                          {{ channel.title }}
                        </ng-container>
                        <ng-container *ngIf="!hasTitle || channel.isZoomChannel">
                          {{ getActiveChannelSlug(channel.channelNavigationItems)?.text }}
                        </ng-container>
                      </p>
                      <app-live-indicator
                        class="ml-2"
                        [showTimeUntil]="false"
                        *ngIf="getIsCurrentChannelLive()"
                      ></app-live-indicator>
                    </h6>
                    <i class="bi bi-chevron-down arrow-down"></i>
                  </button>
                  <div
                    class="d-flex align-items-center justify-content-between navigation-menu rounded text-light w-100 h-100"
                    *ngIf="channel.channelNavigationItems.length === 1"
                  >
                    <h6 class="text-left m-0 w-100 d-flex flex-row align-items-center">
                      <p class="m-0">
                        <ng-container *ngIf="hasTitle && !channel.isZoomChannel">
                          {{ channel.title }}
                        </ng-container>
                        <ng-container *ngIf="!hasTitle || channel.isZoomChannel">
                          {{ getActiveChannelSlug(channel.channelNavigationItems)?.text }}
                        </ng-container>
                      </p>
                      <app-live-indicator
                        class="ml-2"
                        [showTimeUntil]="false"
                        *ngIf="getIsCurrentChannelLive()"
                      ></app-live-indicator>
                    </h6>
                  </div>
                  <div class="dropdown-menu custom-scrollbar-transparent w-100" aria-labelledby="select">
                    <div *ngFor="let navItem of channelNavigationItems">
                      <a
                        class="dropdown-item d-flex flex-row align-items-center"
                        [ngClass]="navItem.slug === channelSlug ? 'text-primary' : 'text-light'"
                        (click)="handleGoToStreamClick(navItem.slug)"
                      >
                        <p class="m-0">
                          {{ navItem.text }}
                        </p>
                        <app-live-indicator
                          class="ml-2"
                          [showTimeUntil]="false"
                          *ngIf="navItem.live"
                        ></app-live-indicator>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video" *ngIf="hasJWVideo && !channel.isZoomChannel && congressMetaData.mediaProvider === 'JW'">
              <app-live-stream
                [config]="{
                  channelId: channel.channelIdentifier,
                  playlistId: channel.playlistIdentifier,
                  playerId: channel.playerIdentifier
                }"
              ></app-live-stream>
            </div>
            <div class="video" *ngIf="congressMetaData.mediaProvider === 'VIMEO'">
              <app-vimeo-player [mediaId]="channel.channelIdentifier" isLivestream="isLive"></app-vimeo-player>
            </div>
            <div class="video" *ngIf="congressMetaData.mediaProvider === 'YT'">
              <app-youtube-player [mediaId]="channel.channelIdentifier"></app-youtube-player>
            </div>
            <div class="row px-3" *ngIf="channel.isZoomChannel">
              <div class="col-12 zoom backdrop-blur p-5">
                <div class="row">
                  <div class="col-lg-9 col-12">
                    <h1
                      class="mb-4"
                      *ngIf="channel.channelZoomRoomHeadline"
                      [innerHtml]="channel.channelZoomRoomHeadline"
                    ></h1>
                    <p *ngIf="channel.description">
                      <small>{{ channel.description }}</small>
                    </p>
                    <a
                      *ngIf="activeSession?.zoomLink"
                      [href]="activeSession?.zoomLink"
                      target="_blank"
                      class="btn btn-danger rounded-lg mt-3"
                      >{{ 'ChannelPage.JoinZoomRoom' | translate }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="getLiveSessionFormat()">
              <div class="d-flex mt-3 px-3 justify-content-between align-items-center">
                <div class="d-flex" *ngIf="!channel.isZoomChannel">
                  <h5 class="now-live mb-0">{{ 'ChannelPage.LiveNow' | translate }}:&nbsp;</h5>
                  <h5 *ngIf="currentLang !== 'ch'" class="mb-0">{{ getLiveSessionFormat() }}</h5>
                  <h5 *ngIf="currentLang === 'ch'" class="mb-0">
                    {{ activeSession?.title ? activeSession?.title : getLiveSessionFormat() }}
                  </h5>
                </div>
                <div class="d-flex" *ngIf="channel.isZoomChannel">
                  <h5 class="now-live mb-0">{{ 'ChannelPage.LiveNow' | translate }}</h5>
                </div>
                <app-social-share-button class="hide-tablet" size="small"></app-social-share-button>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="(activeChatChannel$ | async) && !channel.hasSlido">
            <div id="chat" class="d-none d-md-block col-lg-4 col-12 mb-3 pl-lg-2 h-50vh mt-4">
              <div class="d-flex flex-column card chat h-100">
                <div class="row pl-3 pr-3 pb-3 pt-0 h-100 overflow-hidden">
                  <h5 class="mx-3 mb-0 w-100 chat-title">Live-Chat</h5>
                  <div
                    class="chat-content d-flex flex-column w-100 overflow-auto pt-3 custom-scrollbar-transparent"
                    id="chat-container"
                    *ngIf="sortedMessages$ | async as messages"
                    (scroll)="onScroll()"
                  >
                    <div
                      class="col-12 d-flex w-100 align-items-center justify-content-center"
                      *ngIf="(isLoading$ | async) === true"
                    >
                      <div>{{ 'ChannelPage.ChatLoading' | translate }}</div>
                    </div>

                    <div
                      class="col-12 d-flex w-100 align-items-center justify-content-center px-4"
                      *ngIf="awsMessagingService.error$ | async"
                    >
                      <div>{{ 'ChannelPage.ChatError' | translate }}</div>
                    </div>

                    <ng-container *ngIf="messages.length > 0">
                      <div class="col-12 d-flex message pb-3" *ngFor="let message of messages">
                        <div class="pr-3">
                          <div class="rounded-circle bg-success profile-picture">
                            {{ message.Sender.Name | stringToAcronym | uppercase }}
                          </div>
                        </div>
                        <div class="d-flex flex-fill flex-column">
                          <h6 class="pb-1 m-0">{{ message.Sender.Name }}</h6>
                          <span>{{ message.Content }}</span>
                        </div>
                      </div>
                    </ng-container>
                    <div id="chat-bottom"></div>
                  </div>
                </div>
                <!-- hide scroll to bottom btn -->
                <!-- <div
                  *ngIf="newMessagesAvailable && !hasScrolledToBottomOfChat"
                  class="row new-messages-button"
                >
                  <div class="offset-lg-4 col-lg-6 col-12">
                    <button class="btn btn-danger rounded-pill" (click)="scrollToChatbottom()">
                      {{ 'ChannelPage.NewMessages' | translate }}
                      <i class="bi bi-arrow-down text-light"></i>
                    </button>
                  </div>
                </div> -->
                <form [formGroup]="messageForm">
                  <div class="card-body flex-grow-0 chat-input d-flex justify-content-between align-items-center">
                    <input
                      type="text"
                      formControlName="message"
                      placeholder="{{ 'ChannelPage.ChatWith' | translate }}"
                    />
                    <button
                      class="icon-chat-send"
                      (click)="handleSendMessage()"
                      [inlineSVG]="'assets/icons/chat-send.svg'"
                    ></button>
                  </div>
                </form>
              </div>
            </div>
          </ng-container>
          <div *ngIf="channel.hasSlido" class="d-none d-md-block col-lg-4 col-12 mb-3 pl-lg-2 h-50vh mt-4">
            <iframe [src]="slidoLink" height="100%" width="100%" class="w-100 h-100" title="Slido"></iframe>
          </div>
        </div>
      </div>
      <div id="programme" class="container main-bg px-lg-5 pt-4 z-index-0 position-relative">
        <div class="row">
          <div [class.col-xl-5.offset-xl-1]="(activeChatChannel$ | async) === null" class="col-xl-6 col-12">
            <p
              *ngIf="channel.channelZoomRoomDescription && channel.isZoomChannel"
              class="mt-3"
              replaceToDownloadLink
              [innerHtml]="channel.channelZoomRoomDescription"
            ></p>
            <div class="tab-wrapper px-3 mx-n3 mb-3 mb-md-5 main-bg">
              <div id="tabs-container" class="d-flex">
                <a class="d-block d-md-none" *ngIf="activeChatChannel$ | async" (click)="changeTab(TABS.Chat)">
                  <h5 [ngClass]="selectedTab === TABS.Chat ? 'text-primary selected-tab' : ''" class="tab m-0">
                    {{ 'ChannelPage.Chat' | translate }}
                  </h5>
                </a>
                <a class="d-block d-md-none" *ngIf="channel.hasSlido" (click)="changeTab(TABS.Slido)">
                  <h5 [ngClass]="selectedTab === TABS.Slido ? 'text-primary selected-tab' : ''" class="tab m-0">
                    {{ 'ChannelPage.Slido' | translate }}
                  </h5>
                </a>
                <a
                  *ngIf="!channel.isZoomChannel && channel.channelZoomRoomDescription"
                  (click)="changeTab(TABS.Information)"
                >
                  <h5 [ngClass]="selectedTab === TABS.Information ? 'text-primary selected-tab' : ''" class="tab m-0">
                    {{ 'ChannelPage.Information' | translate }}
                  </h5>
                </a>
                <a (click)="changeTab(TABS.Program)">
                  <h5
                    [class.right-tab]="!channel.isZoomChannel && channel.channelZoomRoomDescription"
                    [ngClass]="selectedTab === TABS.Program ? 'text-primary selected-tab' : ''"
                    class="tab m-0"
                  >
                    {{ 'ChannelPage.Programme' | translate }}
                  </h5>
                </a>
                <a (click)="changeTab(TABS.Experts)" *ngIf="hasAnyExpertsForSessions()">
                  <h5
                    [class.outer-tab]="!channel.isZoomChannel && channel.channelZoomRoomDescription"
                    [ngClass]="selectedTab === TABS.Experts ? 'text-primary selected-tab' : ''"
                    class="tab m-0"
                  >
                    {{ 'ChannelPage.Experts' | translate }}
                  </h5>
                </a>
              </div>
              <div class="divider w-100 mt-0"></div>
            </div>
            <ng-container *ngIf="(activeChatChannel$ | async) && selectedTab === TABS.Chat">
              <div id="chat" class="mb-3 pb-3 d-block d-md-none mx-n3">
                <div class="d-flex flex-column chat h-100">
                  <div class="row pl-3 pr-3 pt-0 h-100 overflow-hidden">
                    <div
                      class="chat-content d-flex flex-column w-100 overflow-auto pt-3"
                      id="chat-container"
                      *ngIf="sortedMessages$ | async as messages"
                      (scroll)="onScroll()"
                    >
                      <div
                        class="col-12 d-flex w-100 align-items-center justify-content-center"
                        *ngIf="(isLoading$ | async) === true"
                      >
                        <div>{{ 'ChannelPage.ChatLoading' | translate }}</div>
                      </div>
                      <div
                        class="col-12 d-flex w-100 align-items-center justify-content-center px-4"
                        *ngIf="awsMessagingService.error$ | async"
                      >
                        <div>{{ 'ChannelPage.ChatError' | translate }}</div>
                      </div>

                      <ng-container *ngIf="messages.length > 0">
                        <div class="col-12 d-flex message pb-3" *ngFor="let message of messages">
                          <div class="pr-3">
                            <div class="rounded-circle bg-success profile-picture">
                              {{ message.Sender.Name | stringToAcronym | uppercase }}
                            </div>
                          </div>
                          <div class="d-flex flex-fill flex-column">
                            <h6 class="pb-1 m-0">{{ message.Sender.Name }}</h6>
                            <span>{{ message.Content }}</span>
                          </div>
                        </div>
                      </ng-container>
                      <div id="chat-bottom"></div>
                    </div>
                  </div>
                  <div *ngIf="newMessagesAvailable && !hasScrolledToBottomOfChat" class="row new-messages-button">
                    <div class="offset-lg-4 col-lg-6 col-12">
                      <button class="btn btn-danger rounded-pill" (click)="scrollToChatbottom()">
                        {{ 'ChannelPage.NewMessages' | translate }}
                        <i class="bi bi-arrow-down text-light"></i>
                      </button>
                    </div>
                  </div>
                  <form [formGroup]="messageForm">
                    <div class="card-body flex-grow-0 chat-input d-flex justify-content-between align-items-center">
                      <input
                        type="text"
                        formControlName="message"
                        placeholder="{{ 'ChannelPage.ChatWith' | translate }}"
                      />
                      <button
                        class="icon-chat-send"
                        (click)="handleSendMessage()"
                        [inlineSVG]="'assets/icons/chat-send.svg'"
                      ></button>
                    </div>
                  </form>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="selectedTab === TABS.Slido">
              <div class="h-100">
                <iframe [src]="slidoLink" height="100%" width="100%" class="w-100 h-100" title="Slido"></iframe>
              </div>
            </ng-container>
            <ng-container *ngIf="selectedTab === TABS.Information">
              <h6 class="mb-0 description-headline" *ngIf="channel.channelZoomRoomHeadline">
                {{ channel.channelZoomRoomHeadline }}
              </h6>
              <p
                *ngIf="channel.channelZoomRoomDescription"
                class="mt-3 description-text"
                [innerHtml]="channel.channelZoomRoomDescription"
              ></p>
            </ng-container>
            <ng-container *ngIf="selectedTab === TABS.Program && timeSlots.length > 0">
              <ng-container *ngFor="let row of timeSlots; let r = index">
                <ng-container *ngIf="row.sessions && row.sessions.length > 0">
                  <section class="program-item" [id]="r">
                    <div class="row mt-3">
                      <div class="col-xl-5 col-lg-6 col-12">
                        <h4 class="text-primary">
                          {{ row.start | date : 'EEEE' }},<br />{{ row.start | date : 'HH:mm' }} -
                          {{ row.finish | date : 'HH:mm' }} {{ 'Common.Hour' | translate }}
                        </h4>
                      </div>
                    </div>
                    <div class="row" *ngFor="let session of row.sessions">
                      <div class="col-12 mt-1">
                        <h4 [class.live-after]="isLive(row)" class="display-5 d-inline-block">
                          {{ session.title ? session.title : session.format }}
                        </h4>
                        <app-live-indicator class="ml-2" *ngIf="isLive(row)" [timeSlotRow]="row"></app-live-indicator>
                        <ng-container *ngFor="let expert of session.experts">
                          <h6 class="text-note">{{ expert | expert }}</h6>
                        </ng-container>
                      </div>
                    </div>
                  </section>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedTab === TABS.Experts && hasAnyExpertsForSessions()">
              <ng-container *ngIf="this.channelExperts.length > 0">
                <div class="channel-experts d-flex flex-column">
                  <ng-container *ngFor="let expert of this.channelExperts">
                    <div class="d-flex flex-fill align-items-top my-3">
                      <div class="profile-picture rounded-circle overflow-hidden" *ngIf="expert.profileImage">
                        <img [src]="s3BucketUrl + avatarImageUrl + expert.profileImage" *ngIf="expert.profileImage" />
                      </div>
                      <div class="d-flex flex-column pl-3">
                        <h6>{{ expert | expert }}</h6>
                        <p>
                          <small>{{ expert.areaOfActivity }}</small>
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div class="col-xl-6 col-12 mt-5 mt-md-3">
            <ng-container *ngIf="channel.teaserCards?.length">
              <div class="col-12 mt-4">
                <ng-container *ngFor="let teaserCard of channel.teaserCards">
                  <div
                    class="teaser-card-container row"
                    [attr.teaser-type-container]="teaserCard.design"
                    *ngIf="teaserCard.title || teaserCard.description || teaserCard.cardImage"
                  >
                    <app-teaser-card
                      [title]="teaserCard.title"
                      [description]="teaserCard.description"
                      [cardImage]="teaserCard.cardImage"
                      [buttonLabel]="teaserCard.buttonLabel"
                      [buttonUrl]="teaserCard.url"
                      [type]="teaserCard.design"
                    ></app-teaser-card>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <app-sponsors-box
              *ngIf="channel.sponsors?.length && channel.showMultipleSponsors"
              [sponsors]="channel.sponsors"
              [showCategories]="channel.showSponsorsCategories"
              [sponsorAreaTitle]="channel.sponsorAreaTitle"
            >
            </app-sponsors-box>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</header>
<app-spacer></app-spacer>
<!-- congress banner if position equals "bottom" -->
<ng-container *ngIf="channelData$ | async as channel">
  <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
    <ng-container *ngIf="congressMetaData.layout?.banner?.position !== 'none' && !channel.sponsors?.length">
      <app-congress-banner
        [congressLogo]="congressMetaData.layout?.banner?.logo_left"
        [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
        [disclaimer]="congressMetaData.layout?.banner?.text"
        [onBottom]="true"
      ></app-congress-banner>
    </ng-container>
  </ng-container>
</ng-container>
<app-scroll-to-top-button [ngClass]="hasScrolled() ? 'd-block' : 'd-none'"></app-scroll-to-top-button>
<app-async-state-overlay (dismiss)="goBack()" [processKey]="asyncKey"></app-async-state-overlay>
<ng-container *ngIf="congressMetaData$ | async as congressMetaData">
  <app-cme-tracking-toggle
    *ngIf="
      currentLang === 'de' &&
      channel?.cmeTrackable &&
      cmeTrackingTriggerStrategy.hasActiveCmeDay(congressMetaData.cmeData)
    "
    [class.shift-left]="hasScrolled()"
  ></app-cme-tracking-toggle>
</ng-container>
