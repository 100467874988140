import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { imagePaths } from '../../../../medtoday/src/environments/environment';
import { Sponsor } from 'medtoday-models-library/lib/models';
import { GoToSponsorBoothDetails } from '../../../core/router/actions/main-navigation.actions';
import { AnalyticsService } from '../../../core/data/services/ga-analytics.service';
import { IS_MED_TODAY_APP } from '../../../core/definitions/app.definitions';
import { BaseAppState } from '../../../core/store/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-sponsor-more-info-link',
  templateUrl: './sponsor-more-info-link.component.html',
  styleUrls: ['./sponsor-more-info-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SponsorMoreInfoLinkComponent {
  @Input() sponsor: Sponsor;

  @Input() congressSlug: string;

  logosUrl = imagePaths.logos;
  pdfUrl = imagePaths.pdfs;

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private analyticsService: AnalyticsService,
    @Inject(IS_MED_TODAY_APP) public isMedTodayApp: string,
    private store: Store<BaseAppState>
  ) {}

  handleDownloadSponsorPdf(pdf: any) {
    return this.analyticsService.pushTag({}, 'channel-sponsor-pdf-download-click', 'pdf_download', pdf);
  }

  handleOpenSponsorBooth(sponsor: Sponsor) {
    if (sponsor.link && !sponsor.hasDetailPage) {
      window.open(sponsor.link, '_blank');
    } else {
      this.store.dispatch(new GoToSponsorBoothDetails(this.congressSlug, sponsor.slug));
    }
  }
}
