<div class="row event">
  <div class="col-12 d-flex">
    <a class="event-thumbnail" (click)="goToEventLocation()" [class.link-disabled]="!event.congressLinkActive">
      <span class="hide-tablet" *ngIf="eventSameDay">{{ event.start | date : 'dd.MM.YY' }}</span>
      <span class="hide-tablet" *ngIf="!eventSameDay"
        >{{ event.start | date : 'dd.MM.' }} - {{ event.end | date : 'dd.MM.YY' }}</span
      >
      <span class="hide-desktop text-center"
        >{{ event.start | date : 'dd' }} <br />
        {{ event.start | date : 'MMM' }}
      </span>
      <img *ngIf="event.thumbnail" [src]="event.thumbnail" />
    </a>
    <div class="col p-0 mr-4 d-flex flex-column justify-content-md-between">
      <div>
        <h2 class="event-title">{{ event.title }}</h2>
        <div class="d-flex hide-tablet">
          <ng-container *ngFor="let topicCategory of event.topicCategory">
            <div class="badge badge-dark p-2 mr-1" *ngIf="topicCategory">
              {{ topicCategory }}
            </div>
          </ng-container>
          <div class="badge badge-dark p-2 mr-1" *ngIf="event.cmeActive">
            {{ 'Common.CME Punkte' | translate }}
          </div>
        </div>
        <p class="text-note mt-2" *ngIf="subTitle">{{ subTitle }}</p>
      </div>
      <div class="event-infos">
        <div class="d-flex icon-text hide-mobile" *ngIf="event.registrationType">
          <i [inlineSVG]="'assets/icons/type.svg'"></i>
          <p>{{ setEventType(event.registrationType) }}</p>
        </div>
        <button class="d-flex icon-text d-md-none" *ngIf="!hideCalendarButtons" (click)="handleAddToCalendarClick()">
          <i [inlineSVG]="'assets/icons/calendar.svg'"></i>
          <p>
            <ng-container *ngIf="!eventSameDay"
              >{{ event.start | date : 'dd.MM.' }} - {{ event.end | date : 'dd.MM.YY' }}</ng-container
            >
            <ng-container *ngIf="eventSameDay">{{ event.start | date : 'dd.MM.YY' }}</ng-container>
          </p>
        </button>
        <div class="d-flex icon-text" *ngIf="event.city">
          <i [inlineSVG]="'assets/icons/location.svg'"></i>
          <p [innerHTML]="event.city"></p>
        </div>
        <div class="d-flex icon-text hide-mobile" *ngIf="event.language">
          <i [inlineSVG]="'assets/icons/language.svg'"></i>
          <p [innerHTML]="event.language"></p>
        </div>
      </div>
    </div>
    <div class="hide-tablet d-flex flex-column">
      <div *ngIf="!hideCalendarButtons">
        <button class="btn calendar-button mb-2 btn-calendar" (click)="handleAddToCalendarClick()">
          {{ 'Event.SaveInCalendar' | translate }}
          <i [inlineSVG]="'assets/icons/calendar.svg'"></i>
        </button>
        <a *ngIf="event.congressLinkActive" [href]="event.link" class="btn btn-danger w-100">
          {{
            event.requiresRegistration && !isUserRegistered
              ? ('Auth.SignInNow' | translate)
              : ('UpcomingEvents.GoToEvent' | translate)
          }}
        </a>
      </div>
      <!--   CUSTOM EVENT BUTTON   -->
      <ng-content select="button"></ng-content>

      <div class="registered" *ngIf="isUserRegistered">
        <i [inlineSVG]="'assets/icons/check.svg'"></i>
        <p class="mb-0">{{ 'UpcomingEvents.AlreadyRegistered' | translate }}</p>
      </div>
    </div>
  </div>
  <div class="divider w-100"></div>
</div>
