<app-layout>
  <app-nav-bar class="sticky-top"></app-nav-bar>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</app-layout>
<!-- <app-survey-popup *ngIf="domain === 'de'"></app-survey-popup> -->
<app-missing-user-data-modal
  *ngIf="(showMissingUserDataModal$ | async) && domain === 'de'"
></app-missing-user-data-modal>
<app-cme-tracking-prompt-modal
  *ngIf="domain === 'de' && (showCmeTrackingPromptModal$ | async)"
></app-cme-tracking-prompt-modal>
<app-async-state-overlay [processKey]="loadCongressLayoutDataAsyncKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="loadCongressListAsyncKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="loadCongressTopicsDataAsyncKey"></app-async-state-overlay>
