import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-text-card',
  templateUrl: './accordion-text-card.component.html',
  styleUrls: ['./accordion-text-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionTextCardComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() video: boolean;
  @Input() videoSrc: string;
  @Input() videoPoster: string;
  @Input() id: number;
  @Input() accordionName: string;
  @Input() optionalHeading: string;
  @Input() defaultShow: boolean;
}
