import { AsyncAction } from '../../../../../todaylib/core/async-state/models/async-action.model';
import { MedBoardsResponseModel } from '../../models/medboards.model';

export enum MedBoardsDataApiActionTypes {
  LoadMedBoardsData = '[Data] Load MedBoards Data',
  LoadMedBoardsDataSuccess = '[Data] Load MedBoards Data Success',
  LoadMedBoardsDataFail = '[Data] Load MedBoards Data Fail'
}

export class LoadMedBoardsData implements AsyncAction {
  readonly asyncKey = MedBoardsDataApiActionTypes.LoadMedBoardsData;
  readonly asyncState = 'start';
  readonly type = MedBoardsDataApiActionTypes.LoadMedBoardsData;
}

export class LoadMedBoardsDataSuccess implements AsyncAction {
  readonly asyncKey = MedBoardsDataApiActionTypes.LoadMedBoardsData;
  readonly type = MedBoardsDataApiActionTypes.LoadMedBoardsDataSuccess;
  readonly asyncState = 'success';

  constructor(public response: MedBoardsResponseModel) {}
}

export class LoadMedBoardsDataFail implements AsyncAction {
  readonly asyncKey = MedBoardsDataApiActionTypes.LoadMedBoardsData;
  readonly type = MedBoardsDataApiActionTypes.LoadMedBoardsDataFail;
  readonly asyncState = 'fail';
}

export type MedBoardsApiActions = LoadMedBoardsDataSuccess;
