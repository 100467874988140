import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { delay, filter, take, tap, takeUntil, map, concatWith } from 'rxjs/operators';
import { NewsletterCreateUpdateItemModel, NewsletterCreateUpdateRequestModel } from 'medtoday-models-library';
import {
  AuthActionTypes,
  EditUserProperties,
  GetUserProfile,
  ResetScrollToNewsletter,
  UpdateNewsletter
} from 'projects/todaylib/core/auth/actions/auth.actions';
import {
  getShouldScrollToNewsletter,
  getUserAttributes,
  getUserProfile
} from 'projects/todaylib/core/auth/selectors/auth.selectors';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { ObservableComponent } from 'projects/todaylib/shared/components/observable/observable.component';

import { GoToEditPassword } from '../../actions/auth-ui-navigation.actions';
import { environment } from 'projects/medtoday/src/environments/environment';
import { AuthService, UpdateUserRequest } from 'projects/todaylib/core/auth/services/auth.service';
import { isUserDoctor, occupationActivities } from '../sign-up/sign-up-form/sign-up-form-definitions';
import { TOPICS } from '../../definitions/topics';
import { includes } from 'lodash';
import { defer, of } from 'rxjs';

export enum UserTabs {
  'Login',
  'Personal',
  'Newsletter'
}

@Component({
  selector: 'app-edit-user-page',
  templateUrl: './edit-user-page.component.html',
  styleUrls: ['./edit-user-page.component.scss']
})
export class EditUserPageComponent extends ObservableComponent implements OnInit, OnDestroy {
  readonly asyncKey = AuthActionTypes.EditUserProperties;
  readonly UserTabs = UserTabs;

  occupationActivities = occupationActivities;
  selectedTab: UserTabs = UserTabs.Login;
  hasNewsletter = environment.hasNewsletter;

  userAttributes$ = this.store.select(getUserAttributes);
  userProfile$ = this.store.select(getUserProfile);
  shouldScrollToNewsletter$ = this.store.select(getShouldScrollToNewsletter);

  newsLetterForm: FormGroup;

  loginDataForm: FormGroup = new FormGroup({
    emailAddress: new FormControl({ value: '', disabled: true }, [Validators.required])
  });

  topics = TOPICS;

  form: FormGroup = new FormGroup({
    salutation: new FormControl(null, [Validators.required]),
    efnNumber: new FormControl(''),
    title: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    institution: new FormControl(''),
    streetAddress: new FormControl(''),
    zipCode: new FormControl('', [Validators.pattern('^[0-9]{4,5}$')]),
    city: new FormControl(''),
    phoneNumber: new FormControl(''),
    occupationActivity: new FormControl(''),
    hasAcceptedPrivacyPolicy: new FormControl(''),
    sourceApp: new FormControl(''),
    topic: new FormControl('')
  });

  isUserDoctor$ = defer(() =>
    of(this.form.get('occupationActivity')?.value).pipe(
      concatWith(this.form.get('occupationActivity')!.valueChanges),
      map(isUserDoctor)
    )
  );

  get currentLang() {
    return this.translateService.getDefaultLang();
  }

  constructor(
    private store: Store<BaseAppState>,
    private translateService: TranslateService,
    public authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new GetUserProfile());
    this.observeNewsletter();
    this.initializeFormData();
    this.initializeNewsletterData();
  }

  public changeTab(tab: UserTabs): void {
    this.selectedTab = tab;
  }

  observeNewsletter() {
    this.shouldScrollToNewsletter$
      .pipe(
        take(1),
        delay(500),
        tap((shouldScrollToNewsletter: boolean | undefined) => {
          if (shouldScrollToNewsletter) {
            document
              .getElementById('acceptNewsletterCheckbox')
              ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }
          this.store.dispatch(new ResetScrollToNewsletter());
        })
      )
      .subscribe();
  }

  initializeFormData() {
    this.userAttributes$.pipe(takeUntil(this.ngDestroy$)).subscribe((userAttributes: CognitoUserAttribute[]) => {
      if (userAttributes) {
        this.loginDataForm.setValue({ emailAddress: userAttributes['email'] });
        this.form.setValue({
          salutation: userAttributes['custom:salutation'] ? userAttributes['custom:salutation'] : null,
          efnNumber: userAttributes['custom:efnNumber'] ? userAttributes['custom:efnNumber'] : '',
          title: userAttributes['custom:title'] ? userAttributes['custom:title'] : '',
          name: userAttributes['given_name'],
          lastName: userAttributes['name'],
          institution: userAttributes['custom:institution'] ?? '',
          streetAddress: userAttributes['custom:streetAddress'] ?? '',
          city: userAttributes['custom:city'] ?? '',
          zipCode: userAttributes['custom:zipCode'] ?? '',
          phoneNumber: userAttributes['custom:phoneNumber'] ?? '',
          occupationActivity: userAttributes['custom:occupationActivity'] ?? '',
          hasAcceptedPrivacyPolicy: userAttributes['custom:hasPrivacyPolicy'] ?? '',
          sourceApp: userAttributes['custom:sourceApp'] ?? '',
          topic: userAttributes['custom:topic'] ?? ''
        });
      }
    });
  }

  initializeNewsletterData() {
    this.userProfile$
      .pipe(
        filter(data => !!data),
        tap(data => {
          const group: any = {};
          data?.newsletters.forEach(newsletter => {
            group[newsletter.id] = new FormControl(newsletter.isSubscribed);
          });
          this.newsLetterForm = new FormGroup(group);
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  handleOccupationActivityChange(occupationActivity: { key: string; text: string }) {
    if (occupationActivity) {
      this.form.get('occupationActivity')?.setValue(occupationActivity.key);

      if (!isUserDoctor(occupationActivity.key)) {
        this.form.get('topic')?.reset('');
      }

      this.form.markAsDirty();
    }
  }

  handleSaveUserClick() {
    if (!this.form.valid) {
      return;
    }

    const request: UpdateUserRequest = {
      ...this.form.value
    };

    this.store.dispatch(new EditUserProperties(request));
    this.form.markAsPristine();
  }

  handleSaveNewsletterClick() {
    let newsletters: NewsletterCreateUpdateItemModel[] = [];

    Object.keys(this.newsLetterForm.value).forEach(value => {
      newsletters = [
        ...newsletters,
        { id: parseInt(value), newsletterSubscribed: this.newsLetterForm.controls[value].value }
      ];
    });

    const request: NewsletterCreateUpdateRequestModel = {
      email: null,
      firstName: null,
      lastName: null,
      newsletters: newsletters
    };
    this.store.dispatch(new UpdateNewsletter(request));
    this.newsLetterForm.markAsPristine();
  }

  handleSalutationSelect(value: string) {
    this.form.controls['salutation'].setValue(value);
    this.form.markAsDirty();
  }

  handleGoToChangePasswort() {
    this.store.dispatch(new GoToEditPassword());
  }

  handleTopicSelect(topicName: string) {
    if (topicName !== '--') {
      this.form.get('topic')?.setValue(topicName);
    } else {
      this.form.get('topic')?.setValue('');
    }
    this.form.markAsDirty();
  }

  searchForTopics(term: string) {
    const termLc = term.toLowerCase();
    this.topics = TOPICS.filter(topic => includes(topic.toLowerCase(), termLc));
  }
}
