import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../../todaylib/core/store/reducers';
import { filter, map, take } from 'rxjs/operators';
import { getUserAttributes, isLoggedIn } from '../../../../../todaylib/core/auth/selectors/auth.selectors';
import { FormControl } from '@angular/forms';
import { UpdateEfnNumber } from '../../../../../todaylib/core/auth/actions/auth.actions';

@Component({
  selector: 'app-efn-updater-control',
  templateUrl: './efn-updater-control.component.html',
  styleUrls: ['./efn-updater-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EfnUpdaterControlComponent implements OnInit {
  isLoggedIn$ = this.store.select(isLoggedIn);
  userAttributes$ = this.store.select(getUserAttributes);
  efnNumber$ = this.userAttributes$.pipe(
    filter(userAttributes => !!userAttributes),
    map(userAttributes => userAttributes!['custom:efnNumber'])
  );
  efnNumberControl = new FormControl<string>('');

  constructor(private store: Store<BaseAppState>) {}

  ngOnInit() {
    this.efnNumber$.pipe(take(1)).subscribe(efnNumber => {
      this.efnNumberControl.setValue(efnNumber);
    });
  }

  handleUpdateEfnNumber() {
    if (this.efnNumberControl.value) {
      this.store.dispatch(new UpdateEfnNumber(this.efnNumberControl.value));
    }
  }
}
