import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CongressMetadata } from 'medtoday-models-library/lib/models';
import { combineLatest } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { getShowMissingUserDataModal, getUserAttributes } from '../../../todaylib/core/auth/selectors/auth.selectors';

import {
  DataApiActionTypes,
  LoadCongressesList,
  LoadCongressMetaData,
  LoadCongressTopics
} from '../../../todaylib/core/data/actions/data-api.actions';
import { DataLayerCustomDimensions } from '../../../todaylib/core/data/enums/data-layer-custom-dimensions';
import { getCongressMetaData } from '../../../todaylib/core/data/selectors/data-api.selectors';
import { AnalyticsService } from '../../../todaylib/core/data/services/ga-analytics.service';
import { getCongressSlugRouterParam } from '../../../todaylib/core/router/selectors/router.selectors';
import { BaseAppState } from '../../../todaylib/core/store/reducers';
import { ObservableComponent } from '../../../todaylib/shared/components/observable/observable.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { getShowCmeTrackingPromptModal } from './medtoday-store/selectors/medtoday-store.selectors';
import { CmeTrackingTriggerStrategy } from './classes/cme/cme-tracking-trigger-strategy';
import { LoadUpcomingEventsPageData } from 'projects/todaylib/core/data/actions/upcoming-events-api.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends ObservableComponent implements OnInit, AfterViewInit {
  readonly loadCongressListAsyncKey = DataApiActionTypes.LoadCongressesList;

  readonly loadCongressLayoutDataAsyncKey = DataApiActionTypes.LoadCongressMetaData;
  readonly loadCongressTopicsDataAsyncKey = DataApiActionTypes.LoadCongressTopics;

  showMissingUserDataModal$ = this.store.select(getShowMissingUserDataModal);
  showCmeTrackingPromptModal$ = this.store.select(getShowCmeTrackingPromptModal);
  congressMetaData$ = this.store.select(getCongressMetaData);
  userAttributes$ = this.store.select(getUserAttributes);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  version: string;
  private megaMenuFilterExclude = environment.megaMenuFilterExclude;

  constructor(
    private store: Store<BaseAppState>,
    private titleService: Title,
    private _analytics: AnalyticsService,
    private _router: Router,
    private translator: TranslateService,
    @Inject('domain') public domain: string,
    private cmeTrackingTriggerStrategy: CmeTrackingTriggerStrategy
  ) {
    super();
    // tslint:disable-next-line: no-any
    (window as any).dataLayer = (window as any).dataLayer || [];

    combineLatest([this._router.events, this.userAttributes$]).subscribe(([event, userAttributes]) => {
      if (event instanceof NavigationEnd) {
        this._analytics.pageView({
          [DataLayerCustomDimensions.userType]: userAttributes
            ? userAttributes['custom:occupationActivity']
            : undefined,
          [DataLayerCustomDimensions.fieldOfExpertise]: userAttributes ? userAttributes['custom:topic'] : undefined,
          url: event.urlAfterRedirects
        });
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(new LoadCongressesList());
    this.store.dispatch(new LoadCongressTopics(this.megaMenuFilterExclude));
    switch (this.translator.getDefaultLang()) {
      case 'ch':
      case 'en':
        registerLocaleData(localeEn, this.translator.getDefaultLang());
        break;
      case 'de':
        registerLocaleData(localeDe, this.translator.getDefaultLang());
        break;
    }
    this.observeTitle();

    // init cme tracker trigger only for CH - auto strategy
    if (environment.language === 'ch') {
      this.cmeTrackingTriggerStrategy.init();
    }

    this.translator.use(this.translator.getDefaultLang());
    this.store.dispatch(new LoadUpcomingEventsPageData(1, null, null, null, this.translator.currentLang, null));
  }

  ngAfterViewInit() {
    this.reloadCongressLayoutData();
    this.observeCongressMetaData();
  }

  reloadCongressLayoutData() {
    this.congressSlug$
      .pipe(
        tap((slug: string) => {
          if (slug && slug !== '') {
            this.store.dispatch(new LoadCongressMetaData(slug));
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeTitle() {
    combineLatest([this.congressMetaData$, this.congressSlug$])
      .pipe(
        tap(([data, slug]: [CongressMetadata, string]) => {
          if (data) {
            this.setTitle(data.name);
          }
          if (!data || !slug) {
            this.setTitle('medtoday');
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeCongressMetaData() {
    this.congressMetaData$
      .pipe(
        filter(data => Boolean(data)),
        tap((data: CongressMetadata) => {
          if (data) {
            this.setThemeColors(data.layout.colors.primary, data.layout.colors.secondary, data.layout.colors.accent);
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }
  setThemeColors(primaryColor: string | null, secondaryColor: string | null, accentColor: string | null) {
    const rootElement = document.querySelector(':root') as HTMLElement;
    if (primaryColor) {
      rootElement?.style.setProperty('--primary', `#${primaryColor}`);
    }
    if (secondaryColor) {
      rootElement?.style.setProperty('--secondary', `#${secondaryColor}`);
    }
    if (accentColor) {
      rootElement?.style.setProperty('--accent', `#${accentColor}`);
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle.replace(/<[^>]*>/g, ''));
  }
}
