import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicScriptLoaderService } from '../../services/dynamic-script-loader-service.service';
import { environment as patientsenv } from '../../../../patientstoday/src/environments/environment';

@Component({
  selector: 'app-pt-social-wall',
  templateUrl: './pt-social-wall.component.html',
  styleUrls: ['./pt-social-wall.component.scss']
})
export class PtSocialWallComponent implements AfterViewInit, OnDestroy {
  currentLang = this.translateService.currentLang;
  patientsInstagram = patientsenv.instagramProfileUrl;

  constructor(private translateService: TranslateService, private dynamicScriptLoader: DynamicScriptLoaderService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.dynamicScriptLoader.loadScript(`https://widget.tagembed.com/embed.min.js`);
    });
  }

  ngOnDestroy() {
    this.dynamicScriptLoader.removeScript(`https://widget.tagembed.com/embed.min.js`);
  }
}
