<div class="med-carousel overflow-hidden">
  <div class="med-carousel-wrapper container">
    <div #sliderRef class="keen-slider">
      <ng-container *ngIf="isCongressList && !isUpcomingEvents">
        <ng-container *ngFor="let carouselItem of carouselItems; let i = index">
          <div
            class="keen-slider__slide congress-list-item"
            [class.flex]="stretchSingleItemWidth"
            [class.in-vp]="isElementInViewPort(i) | async"
          >
            <app-carousel-small-slide
              [index]="i"
              [carouselItem]="carouselItem"
              [isCongressList]="isCongressList"
              [isUpcomingEvents]="isUpcomingEvents"
              [coverImage]="coverImage"
              [scaleOnHover]="scaleOnHover"
              [imageKeyField]="imageKeyField"
            ></app-carousel-small-slide>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!isCongressList && !isUpcomingEvents">
        <ng-container *ngFor="let carouselItem of carouselItems; let i = index">
          <div
            class="keen-slider__slide med-carousel-item video-list-item"
            [class.in-vp]="isElementInViewPort(i) | async"
          >
            <app-carousel-small-slide
              [index]="i"
              [carouselItem]="carouselItem"
              [isCongressList]="isCongressList"
              [isUpcomingEvents]="isUpcomingEvents"
              [coverImage]="coverImage"
              [typeOfItem]="typeOfItem"
              [scaleOnHover]="scaleOnHover"
              [imageKeyField]="imageKeyField"
            ></app-carousel-small-slide>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isUpcomingEvents && !isCongressList">
        <ng-container *ngFor="let carouselItem of carouselItems; let i = index">
          <div
            class="keen-slider__slide med-carousel-item event-list-item"
            [class.in-vp]="isElementInViewPort(i) | async"
          >
            <app-carousel-small-slide
              [index]="i"
              [carouselItem]="carouselItem"
              [isCongressList]="isCongressList"
              [isUpcomingEvents]="isUpcomingEvents"
              [coverImage]="coverImage"
              [showCmePoints]="showCmePoints"
              [scaleOnHover]="scaleOnHover"
              [showTitleOnThumbnail]="showTitleOnThumbnail"
              [showDatePrefix]="true"
              [imageKeyField]="imageKeyField"
            ></app-carousel-small-slide>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="carouselItems?.length > 4">
      <div class="controls left" (click)="slider.prev()" [class.disabled]="isPreviousButtonDisabled()">
        <div class="icon-16" [inlineSVG]="'assets/icons/arrow-left.svg'"></div>
      </div>
      <div class="controls right" (click)="slider.next()" [class.disabled]="isNextButtonDisabled()">
        <div class="icon-16" [inlineSVG]="'assets/icons/arrow-right.svg'"></div>
      </div>
    </ng-container>
  </div>
</div>
