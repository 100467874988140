import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { getTopicCategories } from '../../../patientstoday/src/app/patientstoday-store/selectors/patientstoday-store.selectors';
import { BaseAppState } from '../../core/store/reducers';
import { filter, take } from 'rxjs/operators';

@Directive({
  selector: '[appTopicCategoryColor]'
})
export class TopicCategoryColorDirective implements AfterViewInit {
  topicCategories$ = this.store.select(getTopicCategories);

  @Input('appTopicCategoryColor') topicCategorySlug: string;
  @Input() inverseColors: boolean = false;
  @Input() topicCategoryName: string;
  @Input() withoutBackgroundColor = false;

  get getBackgroundColor() {
    return this.inverseColors ? 'solid' : 'light';
  }

  get getFontColor() {
    return this.inverseColors ? 'light' : 'solid';
  }

  @HostBinding('style.backgroundColor') get backgroundColor() {
    if (!this.withoutBackgroundColor) {
      return `var(--topic-${this.topicCategorySlug}-${this.getBackgroundColor})`;
    }

    return this.element.nativeElement.style.backgroundColor;
  }

  @HostBinding('style.color') get color() {
    return `var(--topic-${this.topicCategorySlug}-${this.getFontColor})`;
  }

  constructor(private store: Store<BaseAppState>, private element: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (!this.topicCategorySlug && this.topicCategoryName) {
      this.topicCategories$
        .pipe(
          filter(topicCategories => !!topicCategories),
          take(1)
        )
        .subscribe(topicCategories => {
          const tCat = topicCategories.find(topicCategory => topicCategory.title === this.topicCategoryName);
          if (tCat) {
            this.topicCategorySlug = tCat.slug!;
            this.cdr.detectChanges();
          }
        });
    }
  }
}
