import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ExpertIdentity } from 'medtoday-models-library/lib/models/responses/expert/expert-identity';

@Pipe({
    name: 'experts',
})
export class ExpertsPipe implements PipeTransform {
    constructor(public translate: TranslatePipe) {}

    transform(experts: ExpertIdentity[] | null, limit = 3): string {
        return experts
          ? experts
              .slice(0, limit)
              .map(expert => expert.lastName)
              .join(', ')
              .concat(experts.length > limit ? `, ${this.translate.transform('u.a.')}` : '')
          : '';
    }
}
