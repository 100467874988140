<div class="container">
  <div data-overlay class="d-flex flex-column justify-content-md-center o-hidden">
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
            <h1 class="h3 mb-4">{{ 'LogIn.New Password' | translate }}</h1>
            <form [formGroup]="form" name="sign-up" (ngSubmit)="handleChangeClick()" #ngForm="ngForm" novalidate>
              <div class="form-group">
                <input
                  type="password"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  name="reset-password"
                  placeholder="{{ 'Auth.Passwort' | translate }}"
                  formControlName="password"
                  [class.is-invalid]="ngForm.submitted && form.controls['password'].invalid"
                />
                <div class="invalid-feedback">
                  <div *ngIf="form.controls['password'].hasError('required')">
                    {{ 'LogIn.PasswordRequired' | translate }}
                  </div>
                  <div *ngIf="form.controls['password'].hasError('minlength')">
                    {{ 'SignUp.PasswordMinLength' | translate }}
                  </div>
                  <div
                    *ngIf="
                      !form.controls['password'].hasError('minlength') && form.controls['password'].hasError('pattern')
                    "
                  >
                    {{ 'SignUp.PasswordInvalidRules' | translate }}
                  </div>
                </div>
              </div>

              <div class="form-group">
                <input
                  type="password"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  name="reset-password-confirm"
                  placeholder="{{ 'SignUp.ConfirmPassword' | translate }}"
                  formControlName="passwordConfirmation"
                  [class.is-invalid]="ngForm.submitted && form.controls['passwordConfirmation'].invalid"
                />
                <div class="invalid-feedback">
                  <div *ngIf="form.controls['passwordConfirmation'].hasError('required')">
                    {{ 'SignUp.ConfirmPasswordRequired' | translate }}
                  </div>
                  <div
                    *ngIf="
                      form.controls['passwordConfirmation'].hasError('mismatchedPasswords') &&
                      !form.controls['passwordConfirmation'].hasError('required')
                    "
                  >
                    {{ 'SignUp.PasswordNotSame' | translate }}
                  </div>
                </div>
              </div>

              <button class="btn btn-danger rounded-pill btn-block mt-4" type="submit">
                {{ 'EditUser.ChangePassword' | translate }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>

  <app-async-state-overlay
    [processKey]="asyncProcessKey"
    (resendPassword)="handleResetClick($event)"
  ></app-async-state-overlay>
</div>
<app-spacer></app-spacer>
