import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { imagePaths } from 'projects/medtoday/src/environments/environment';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ObservableComponent } from '../../../../../shared/components/observable/observable.component';
import {
  getCongressesList,
  getCongressTopics,
  getSpecialEventCongresses
} from '../../../../data/selectors/data-api.selectors';
import { NavBarService } from '../../services/nav-bar.service';
import { CongressesTopicModel } from 'medtoday-models-library/lib/models';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-congress-dropdown',
  templateUrl: './congress-dropdown.component.html',
  styleUrls: ['./congress-dropdown.component.scss'],
  providers: [NavBarService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CongressDropdownComponent extends ObservableComponent {
  @Input() showTopicIndicator = false;
  @Input() congressTileStretched = false;
  @Output() congressSelected = new EventEmitter();
  public logoPathUrl = imagePaths.logos;
  public thumbnailUrl = imagePaths.thumbnails;
  private readonly ENTRIES_PER_COLUMN = 8;
  specialEvents$ = this.store.select(getSpecialEventCongresses);
  congressTopics$ = this.store.select(getCongressTopics);
  congressesColumnsCount$ = new BehaviorSubject<number>(1);

  activeCongresses$ = this.store.select(getCongressesList).pipe(
    filter(congresses => !!congresses?.length),
    map(congresses => {
      if (this.domain !== 'ch') {
        return congresses
          .filter(congress => congress.congressMode !== 'MedEd' && !!congress.slug && congress.showCongressLinkButton)
          .slice(0, 2);
      }
      return congresses.filter(congress => !!congress.slug && congress.showCongressLinkButton).slice(0, 2);
    })
  );

  constructor(
    protected store: Store<BaseAppState>,
    public navBarService: NavBarService,
    public translateService: TranslateService,
    @Inject('domain') public domain: string,
    @Inject('applicationName') public applicationName: string,
    @Inject('s3BucketUrl') public s3BucketUrl: string
  ) {
    super();
  }

  public congressForColumn(index: number) {
    return this.congressesColumnsCount$.pipe(
      switchMap(congressesColumnCount => {
        return this.activeCongresses$.pipe(
          map(activeCongresses => {
            return activeCongresses[index - congressesColumnCount];
          })
        );
      })
    );
  }

  public handleTopicCategoryClick(congressTopic?: CongressesTopicModel) {
    if (congressTopic) {
      this.navBarService.showTopicChildren(true, congressTopic?.congresses, congressTopic?.topicCategoryName);
      this.congressesColumnsCount$.next(this.calcCongressesColumnsCount(congressTopic?.congresses));
    } else {
      this.specialEvents$
        .pipe(
          filter(events => Boolean(events && events?.length)),
          take(1),
          tap(events => {
            this.navBarService.showTopicChildren(true, events, 'Special Events');
            this.congressesColumnsCount$.next(this.calcCongressesColumnsCount(events));
          })
        )
        .subscribe();
    }
  }

  private calcCongressesColumnsCount(congresses: any[] | undefined): number {
    if (!congresses?.length) return 0;
    return Math.ceil(congresses.length / this.ENTRIES_PER_COLUMN);
  }

  public handleBackToTopicCategories() {
    this.navBarService.showTopicChildren(false);
    this.congressesColumnsCount$.next(1);
  }

  public getTopicCategoryChildren(from: number) {
    return this.navBarService.getTopicCategoryChildren(from, from + this.ENTRIES_PER_COLUMN);
  }

  public handleGoToCongressClick(slug: any, congressMode: any) {
    this.navBarService.handleGoToCongressClick(slug, congressMode);
    this.handleAfterCongressClick();
  }

  public handleAfterCongressClick() {
    this.congressSelected.emit(true);
    this.handleBackToTopicCategories();
  }
}
