import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer/survey.reducer';
import { SurveyPopupComponent } from './components/survey-popup.component';
import { EffectsModule } from '@ngrx/effects';
import { SurveyEffects } from './effects/survey.effects';

export const COMPONENTS = [SurveyPopupComponent];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    InlineSVGModule,
    StoreModule.forFeature('survey-store', reducer),
    EffectsModule.forFeature([SurveyEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class SurveyModule {}
