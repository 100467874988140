<ng-container *ngIf="congressLandingPageData$ | async as congressData">
  <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
    <div class="container" *ngIf="congressMetaData.layout">
      <app-event-page-header
        [congressTimeRange]="congressTimeRange"
        [isAnySessionLive$]="isAnySessionLive"
        [liveSession]="liveSession"
        [isMultiChannel$]="isMultiChannel"
      ></app-event-page-header>

      <div class="row mt-0 mt-md-5 mobile-full-width">
        <div class="col-lg-5 col-12 order-lg-1 offset-0 offset-md-1">
          <div
            class="quickfacts card shadow-lg overflow-auto"
            [class.text-dark]="isPatientsTodayApp"
            [class.patients]="isPatientsTodayApp"
          >
            <div class="h-100 quickfacts-inner">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="hide-mobile">{{ 'Event.Quickfacts' | translate }}</h4>
                <app-social-share-button class="hide-tablet" size="small"></app-social-share-button>
              </div>
              <div class="d-flex mt-0 mt-lg-0 flex-wrap">
                <div class="badge badge-dark p-2 mr-1 my-1" *ngIf="congressMetaData.cmeData.cmeEnabled">
                  {{ 'Common.CME Punkte' | translate }}
                </div>
                <div
                  class="badge badge-dark p-2 mr-1 my-1 mb-2"
                  [class.text-dark]="isPatientsTodayApp"
                  *ngFor="let topic of (programmeData$ | async)?.topics"
                >
                  {{ topic.title }}
                </div>
              </div>
              <p
                class="mb-4"
                *ngIf="congressMetaData.layout.logo"
                [class.mt-2]="congressMetaData.cmeData.cmeEnabled && (programmeData$ | async)?.topics"
              >
                {{ congressData.title }}
              </p>
              <div class="d-flex icon-text">
                <i [inlineSVG]="'assets/icons/calendar.svg'"></i>
                <p>
                  {{ congressTimeRange }}
                </p>
              </div>
              <div class="d-flex icon-text" *ngIf="congressMetaData.congressCategory">
                <i [inlineSVG]="'assets/icons/type.svg'"></i>
                <p>{{ setEventType(congressMetaData) }}</p>
              </div>
              <div
                class="d-flex align-items-start icon-text"
                *ngIf="congressMetaData.street || congressMetaData.zipCode || congressMetaData.city"
              >
                <i [inlineSVG]="'assets/icons/location.svg'"></i>
                <div class="d-flex flex-column">
                  <p *ngIf="congressMetaData.street" [innerHTML]="congressMetaData.street"></p>
                  <p *ngIf="congressMetaData.zipCode" [innerHTML]="congressMetaData.zipCode"></p>
                  <p *ngIf="congressMetaData.city" [innerHTML]="congressMetaData.city"></p>
                </div>
              </div>
              <div class="d-flex icon-text" *ngIf="expertsData$ | async as expertsData">
                <i [inlineSVG]="'assets/icons/users.svg'"></i>
                <p>{{ expertsData?.experts.length }} {{ 'ExpertsPage.expert' | translate }}</p>
              </div>
              <div class="d-flex icon-text" *ngIf="congressMetaData.language">
                <i [inlineSVG]="'assets/icons/language.svg'"></i>
                <p [innerHTML]="congressMetaData.language"></p>
              </div>
              <div
                *ngIf="congressUtils.isAfterCongress(congressMetaData)"
                class="d-flex info-line align-items-center mt-4 py-3 px-4 px-lg-5"
              >
                <i [inlineSVG]="'assets/icons/time.svg'"></i>
                <span class="pl-3">{{ 'Event.Over' | translate }}</span>
              </div>
              <div
                *ngIf="
                  !congressUtils.isAfterCongress(congressMetaData) && (congressParticipantData$ | async)?.isParticipant
                "
                class="d-flex info-line align-items-center mt-4 py-3 px-4 px-lg-5"
              >
                <i [inlineSVG]="'assets/icons/check.svg'"></i>
                <span class="pl-3">{{ 'Event.AlreadyRegistered' | translate }}</span>
              </div>
              <div
                *ngIf="
                  registrationService.registrationClosed(congressMetaData) &&
                  !congressUtils.isAfterCongress(congressMetaData) &&
                  !(congressParticipantData$ | async)?.isParticipant
                "
                class="d-flex info-line align-items-center mt-4 py-3 px-4 px-lg-5"
              >
                <i [inlineSVG]="'assets/icons/info.svg'"></i>
                <span class="pl-3">{{ registrationService.registrationClosedText | translate }}</span>
              </div>
              <a
                class="btn btn-primary w-fit-content small-button"
                [class.btn-patients-blue]="isPatientsTodayApp"
                href="#registerModal"
                draggable="false"
                *ngIf="
                  !congressUtils.isAfterCongress(congressMetaData) &&
                  !(congressParticipantData$ | async)?.isParticipant &&
                  congressMetaData.authorizedOnly &&
                  registrationService.showSignUpBtn
                "
                data-toggle="modal"
                (click)="registrationService.handleShowRegisterModal(true)"
                role="button"
              >
                {{ 'Auth.SignUp' | translate }}
              </a>
              <button
                class="btn btn-primary w-fit-content small-button"
                [class.btn-patients-blue]="isPatientsTodayApp"
                (click)="handleAddToCalendarClick(congressMetaData)"
                *ngIf="
                  (isAnySessionLive | async) === false &&
                  congressUtils.isBeforeCongress(congressMetaData) &&
                  !congressMetaData.authorizedOnly
                "
              >
                {{ 'Event.AddToCalendar' | translate }}
              </button>
            </div>
          </div>
        </div>

        <app-event-register-modal
          [congressMetaData]="congressMetaData"
          [user]="registrationService.user"
          (registrationCompleteEmitter)="registrationService.setHideRegisterModal()"
        ></app-event-register-modal>

        <div class="col-lg-6 col-12 not-full-width">
          <div class="description mb-5 mt-4">
            <h4>{{ 'Event.Description' | translate }}</h4>
            <div [innerHTML]="congressData.description"></div>
          </div>

          <div class="d-flex flex-fill tabs-container">
            <a (click)="changeTab(EventTabs.Programme)" *ngIf="programmeData$ | async">
              <h5
                [ngClass]="selectedTab === EventTabs.Programme ? 'text-primary selected-tab' : ''"
                class="tab m-0 p-1"
                [class.text-dark]="isPatientsTodayApp"
              >
                {{ 'ChannelPage.Programme' | translate }}
              </h5>
            </a>
            <a (click)="changeTab(EventTabs.Experts)" *ngIf="expertsData$ | async">
              <h5
                [ngClass]="selectedTab === EventTabs.Experts ? 'text-primary selected-tab' : ''"
                class="tab m-0 p-1"
                [class.text-dark]="isPatientsTodayApp"
              >
                {{ 'ChannelPage.Experts' | translate }}
              </h5>
            </a>
          </div>

          <div class="divider w-100 mb-3"></div>

          <ng-container *ngIf="selectedTab === EventTabs.Programme">
            <div
              id="programme-section"
              class="programme-section my-4"
              *ngIf="timeSlotsById && (programmeData$ | async) as programmeData"
            >
              <div class="d-flex justify-content-between dropright mb-3">
                <span class="d-flex align-items-center">
                  <h4 class="mb-n2 hide-tablet">{{ 'ChannelPage.Programme' | translate }}</h4>
                  <a
                    *ngIf="congressData.programPdf"
                    target="_blank"
                    [href]="s3BucketUrl + pdfImageUrl + congressData.programPdf"
                    class="btn btn-pdf-menu justify-content-between d-flex p-2 ml-3"
                    [class.btn-dark]="isMedTodayApp"
                    [class.text-dark]="isPatientsTodayApp"
                  >
                    <p class="button-text text-light mb-0 mr-2 ml-1">{{ 'Event.SavePDF' | translate }}</p>
                    <div
                      class="download-link d-flex align-items-center"
                      [inlineSVG]="'assets/icons/anchor-download.svg'"
                    ></div>
                  </a>
                </span>
                <button
                  class="btn calendar-toggle d-flex align-items-center ml-auto"
                  *ngIf="!congressMetaData.isMedEdVoDMode"
                  (click)="handleAddToCalendarClick(congressMetaData)"
                  [class.patients]="isPatientsTodayApp"
                >
                  <p class="button-text text-light mb-0 mr-3">
                    {{ 'Event.SaveInCalendar' | translate }}
                  </p>
                  <i [inlineSVG]="'assets/icons/calendar.svg'"></i>
                </button>
              </div>

              <ng-container *ngFor="let row of timeSlotRows">
                <ng-container
                  *ngIf="!congressUtils.isAfterCongress(congressMetaData) || !congressMetaData.isMedEdVoDMode"
                >
                  <div
                    [id]="checkOneSessionLive(timeSlotsById[session.timeSlotId], session) ? 'live' : ''"
                    class="d-flex flex-column my-2 py-2 info"
                    *ngFor="let session of row.sessions"
                  >
                    <p class="text-primary font-size-large mb-1" *ngIf="!isSameDay(timeSlotsById[session.timeSlotId])">
                      {{ timeSlotsById[session.timeSlotId]?.start | localeDate | date : 'EEEE, dd.MM.yyyy | HH:mm' }}
                      {{ 'Common.Hour' | translate }} -
                      {{ timeSlotsById[session.timeSlotId]?.finish | localeDate | date : 'EEEE, dd.MM.yyyy | HH:mm' }}
                      {{ 'Common.Hour' | translate }}
                    </p>
                    <p class="text-primary font-size-large mb-1" *ngIf="isSameDay(timeSlotsById[session.timeSlotId])">
                      {{ timeSlotsById[session.timeSlotId]?.start | localeDate | date : 'EEEE, dd.MM.yyyy | HH:mm' }}
                      - {{ timeSlotsById[session.timeSlotId]?.finish | localeDate | date : 'HH:mm' }}
                      {{ 'Common.Hour' | translate }}
                    </p>
                    <h5>
                      {{ session.title }}
                      <app-live-indicator
                        *ngIf="checkOneSessionLive(timeSlotsById[session.timeSlotId], session)"
                        [timeSlotRow]="timeSlotsById[session.timeSlotId]"
                      ></app-live-indicator>
                    </h5>
                    <span class="mb-2 text-note" [innerHTML]="session.description"></span>
                    <span class="mb-2 text-note" *ngFor="let expert of session.experts"
                      >{{ expert.title }} {{ expert.firstName }} {{ expert.lastName }}</span
                    >
                    <ng-container *ngIf="checkOneSessionLive(timeSlotsById[session.timeSlotId], session)">
                      <button
                        class="btn btn-primary w-fit-content small-button align-self-start"
                        [class.btn-patients-blue]="isPatientsTodayApp"
                        [disabled]="!registrationService.isUserAuthorized"
                        *ngIf="!liveSession?.zoomLink && congressMetaData.congressCategory !== 'ON_SITE'"
                        (click)="goToChannelSession(session.channelSlug)"
                      >
                        {{ 'Event.Stream' | translate }}
                        <i class="text-light pl-2" [inlineSVG]="'assets/icons/stream.svg'"></i>
                      </button>
                      <button
                        class="btn btn-primary w-fit-content small-button align-self-start"
                        [class.btn-patients-blue]="isPatientsTodayApp"
                        [disabled]="!registrationService.isUserAuthorized"
                        *ngIf="liveSession?.zoomLink"
                        (click)="goToZoomRoom(liveSession.zoomLink)"
                      >
                        {{ 'ChannelPage.JoinZoomRoom' | translate }}
                        <i class="text-light pl-2" [inlineSVG]="'assets/icons/stream.svg'"></i>
                      </button>
                    </ng-container>
                  </div>
                </ng-container>

                <div class="d-flex flex-column my-2 py-2 info" *ngFor="let video of row.videos">
                  <div class="my-2 py-2">
                    <p class="text-primary font-size-large mb-2" *ngIf="video.timeSlotStart">
                      {{ video.timeSlotStart | localeDate | date : 'EEEE, dd.MM.yyyy | HH:mm' }}
                      {{ 'Common.Hour' | translate }}
                    </p>
                    <h5>{{ video.title }}</h5>
                    <p *ngIf="video.description"><small [innerHTML]="video.description"></small></p>
                    <ng-container *ngIf="!!video.expertName">
                      <span class="text-note">{{ video.expertName }}<br /></span>
                    </ng-container>
                    <div class="d-flex">
                      <button
                        *ngIf="isVideoOnline(row)"
                        type="button"
                        class="btn btn-primary shadow-sm mt-1"
                        [class.rounded-pill]="isMedTodayApp"
                        [class.btn-patients-blue]="isPatientsTodayApp"
                        (click)="handleGoToVideo(video.id)"
                      >
                        {{ 'VideoOnDemand.ViewPost' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedTab === EventTabs.Experts">
            <div class="experts-section my-4" *ngIf="expertsData$ | async as expertsData">
              <ng-container *ngFor="let expert of expertsData.experts">
                <app-expert-list-item [expert]="expert"></app-expert-list-item>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ifApp="'MedToday'">
            <app-event-centered-sponsors [congressData]="congressData"></app-event-centered-sponsors>
          </ng-container>
          <ng-container *ifApp="'PatientsToday'">
            <ng-container *ngIf="sponsors$ | async as sponsors">
              <ng-container *ngIf="sponsors.length">
                <div class="divider w-100 my-3 mb-0"></div>
                <h6>Die Partner & Sponsoren haben keinerlei Einfluss auf die Inhalte der Webseite.</h6>
                <app-sponsors-box
                  [sponsors]="sponsors"
                  [showCategories]="true"
                  [noTopDivider]="true"
                  [noContainerPadding]="true"
                  class="ml-2"
                ></app-sponsors-box>
                <app-spacer></app-spacer>
              </ng-container>
            </ng-container>
          </ng-container>

          <div id="partners-section" class="my-5" *ngIf="congressData.partnersSection.partners.length">
            <app-partners-box [partners]="congressData.partnersSection.partners"></app-partners-box>
          </div>
        </div>
      </div>
    </div>

    <!-- Details Dialog -->
    <app-expert-details-modal></app-expert-details-modal>
  </ng-container>
</ng-container>
<app-async-state-overlay [processKey]="loadCongressLayoutDataAsyncKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="loadVideoOnDemandDataAsyncKey"></app-async-state-overlay>
