import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ELEARNING_STATE_KEY } from '../definitions/elearning.definitions';
import { QuestionAnswers } from 'medtoday-models-library';
import { ElearningDataState } from '../reducers/elearning.reducer';
import { ELearningMetadataResponse, ElearningResultResponse } from 'medtoday-models-library/lib';

export namespace ElearningSelectors {
  export const selectState = createFeatureSelector<ElearningDataState>(ELEARNING_STATE_KEY);

  export const selectQuestions: MemoizedSelector<object, QuestionAnswers[] | undefined> = createSelector(
    selectState,
    state => state.questionsAnswers
  );

  export const selectElearningResults: MemoizedSelector<object, ElearningResultResponse | undefined> = createSelector(
    selectState,
    state => state.answersResults
  );

  export const selectElearningUserMetadata: MemoizedSelector<object, ELearningMetadataResponse | undefined> =
    createSelector(selectState, state => state.userMetadata);
}
