import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getMediaLibraryItemSlugRouterParam } from 'projects/todaylib/core/router/selectors/router.selectors';
import { filter, take, tap } from 'rxjs';
import { FileDownloadActionTypes, GetMediaLibraryItem } from '../../actions/file-downloads-api.actions';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadFileComponent implements OnInit {
  public readonly asyncKey = FileDownloadActionTypes.GetMediaLibraryItem;
  public fileSlug$ = this.store.select(getMediaLibraryItemSlugRouterParam);

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.fileSlug$
      .pipe(
        filter((slug: string) => Boolean(slug)),
        take(1),
        tap((slug: string) => {
          this.store.dispatch(new GetMediaLibraryItem(slug));
        })
      )
      .subscribe();
  }
}
