import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { getSelectedOnDemandVideo } from '../../../../../core/data/selectors/data-api.selectors';
import { combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { GoToOnDemandVideo } from '../../../../../core/router/actions/main-navigation.actions';
import { BaseAppState } from '../../../../../core/store/reducers';
import { Store } from '@ngrx/store';
import { getCongressSlugRouterParam } from '../../../../../core/router/selectors/router.selectors';
import { ObservableComponent } from '../../../../components/observable/observable.component';
import { ElearningActionTypes, PurgeData } from '../../actions/elearning.actions';
import { ElearningSelectors } from '../../selectors/elearning.selectors';
import { TranslateService } from '@ngx-translate/core';
import { LoadOnDemandVideoUserDataSuccess } from '../../../../../../medtoday/src/app/medtoday-store/actions/medtoday-store.actions';

@Component({
  selector: 'app-questions-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultsComponent extends ObservableComponent implements OnInit {
  readonly submitElearningAnswersAsyncKey = ElearningActionTypes.SubmitElearningAnswers;
  onDemandVideoData$ = this.store.select(getSelectedOnDemandVideo);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  answersResults$ = this.store.select(ElearningSelectors.selectElearningResults);
  currentLang = this.translateService.currentLang;
  correctAnswersCount$ = this.answersResults$.pipe(
    map(answersResults => answersResults?.questionAnswers.filter(q => q.isCorrect).length)
  );

  constructor(private store: Store<BaseAppState>, private translateService: TranslateService) {
    super();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new PurgeData());
  }

  ngOnInit() {
    this.answersResults$.pipe(takeUntil(this.ngDestroy$)).subscribe(results => {
      this.store.dispatch(
        new LoadOnDemandVideoUserDataSuccess({
          position: 0,
          watched: !!results?.passed
        })
      );
    });
  }

  handleBackToVideo() {
    combineLatest([this.onDemandVideoData$, this.congressSlug$])
      .pipe(take(1))
      .subscribe(([vod, congressSlug]) => {
        this.store.dispatch(new GoToOnDemandVideo(vod.id, false, undefined, congressSlug));
      });
  }
}
