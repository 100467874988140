<div *ngIf="timestamps$ | async as timestamps">
  <div
    class="row timeslot-row mx-0 d-flex"
    *ngFor="let timestamp of timestamps; let i = index"
    [class.active]="(activeTimestampIndex$ | async) === i"
    (click)="handleTimestampSelected(timestamp)"
  >
    <div class="col col-1 text-center" *ngIf="showIndexColumn">
      <ng-container *ngIf="(activeTimestampIndex$ | async) === i">
        <div class="align-items-center d-flex h-100 mt-n1">
          <i class="bi bi-play-fill play-icon icon-16 d-flex pl-1"></i>
        </div>
      </ng-container>
      <ng-container *ngIf="(activeTimestampIndex$ | async) !== i">
        {{ i + 1 }}
      </ng-container>
    </div>
    <div class="col timeslot-row-label w-100 d-flex">
      <span>{{ timestamp.text }}</span>
      <i *ngIf="timestamp.hasStar" class="bi-star-fill float-right pr-3 align-self-center ml-auto"></i>
    </div>
  </div>
  <span class="mt-2 d-block" *ngIf="hasStar">
    <i class="bi-star-fill text-primary pr-1 align-self-center ml-auto"></i>
    {{ 'VideoOnDemand.AsteriskLegend' | translate }}
  </span>
</div>
