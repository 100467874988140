import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ObservableComponent } from 'projects/todaylib/shared/components/observable/observable.component';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';

import { BaseAppState } from '../../store/reducers';
import { TokenValidation } from '../actions/auth.actions';
import { isLoggedIn } from '../selectors/auth.selectors';

@Injectable()
export class TokenValidationService extends ObservableComponent {
  isLoggedIn$ = this.store.select(isLoggedIn);

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<BaseAppState>,
    @Inject('apiBaseUrl') protected apiBaseUrl: string,
    protected http: HttpClient
  ) {
    super();
  }

  public async observeToken(): Promise<void> {
    const login = await this.isLoggedIn$.pipe(take(1)).toPromise();
    if (!login) {
      this.activatedRoute.params
        .pipe(
          distinctUntilChanged(),
          filter(params => !!params),
          takeUntil(this.ngDestroy$)
        )
        .subscribe(params => {
          if (params.token) {
            this.updateCongressTokens(params);
            this.store.dispatch(new TokenValidation(params.congressSlug, params.token));
          }
        });
    }
  }

  private updateCongressTokens(action): void {
    const congressTokenArray = localStorage.getItem('medtoday_congress_tokens');

    if (!congressTokenArray) {
      localStorage.setItem(
        'medtoday_congress_tokens',
        JSON.stringify([{ congressSlug: action.congressSlug, token: action.token }])
      );
    } else {
      const updatedArray = JSON.parse(congressTokenArray);
      const found = updatedArray.findIndex(ele => ele.congressSlug === action.congressSlug);

      if (found === -1) {
        updatedArray.push({ congressSlug: action.congressSlug, token: action.token });
        localStorage.setItem('medtoday_congress_tokens', JSON.stringify(updatedArray));
      }
      if (found !== -1 && updatedArray[found].token !== action.token) {
        updatedArray[found].token = action.token;
        localStorage.setItem('medtoday_congress_tokens', JSON.stringify(updatedArray));
      }
    }
  }
}
