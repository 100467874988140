import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { SharedComponentsModule, SharedDirectivesModule } from '../../shared';
import { NavBarModule } from './nav-bar/nav-bar.module';
import { HeaderComponent } from './header/header.component';

@NgModule({
  imports: [CommonModule, SharedComponentsModule, NavBarModule, SharedDirectivesModule],
  declarations: [LayoutComponent, HeaderComponent],
  exports: [LayoutComponent, HeaderComponent]
})
export class LayoutModule {}
