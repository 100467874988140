import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { GoToHome, MainNavigationActionTypes } from '../actions/main-navigation.actions';
import { MAIN_NAVIGATION_PATH_ELEMENTS } from '../definitions/main-navigation.definitions';

@Injectable()
export class DemoFeatureNavigationEffects {


  goToHome$ = createEffect(() => this.actions$.pipe(
    ofType(MainNavigationActionTypes.GoToHome),
    tap((_action: GoToHome) => {
      this.router.navigate([MAIN_NAVIGATION_PATH_ELEMENTS.root.homeElement]);
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
  ) {}

}
