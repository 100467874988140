<div>
  <h2 class="mb-3">{{ 'Auth.SignInNow' | translate }}</h2>
  <p class="font-size-large mb-4">{{ 'LogIn.LogInCredentials.' | translate }}</p>
  <form [formGroup]="form" name="login" (ngSubmit)="handleLoginClick()" #ngForm="ngForm" novalidate>
    <h4>{{ 'Auth.LoginData' | translate }}</h4>
    <div class="form-group">
      <label>{{ 'Auth.Email' | translate }}</label>
      <input
        type="email"
        autocomplete="email"
        class="form-control form-control-lg"
        name="sign-up-email"
        placeholder="{{ 'SignUp.EnterEmailAddress' | translate }}"
        formControlName="emailAddress"
        [class.is-invalid]="ngForm.submitted && form.controls['emailAddress'].invalid"
      />
      <div class="invalid-feedback">
        <div *ngIf="form.controls['emailAddress'].hasError('required')">{{ 'Auth.EmailRequired' | translate }}</div>
        <div *ngIf="form.controls['emailAddress'].hasError('pattern')">{{ 'Auth.EmailInvalid' | translate }}</div>
      </div>
    </div>
    <div class="form-group">
      <label>{{ 'Auth.Passwort' | translate }}</label>
      <input
        type="password"
        autocomplete="current-password"
        class="form-control form-control-lg"
        name="sign-up-password"
        placeholder="{{ 'LogIn.EnterPassword' | translate }}"
        formControlName="password"
        [class.is-invalid]="ngForm.submitted && form.controls['password'].invalid"
      />
      <div class="invalid-feedback">
        <div *ngIf="form.controls['password'].hasError('required')">{{ 'LogIn.PasswordRequired' | translate }}</div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 w-100">
        <button class="btn btn-danger rounded btn-block" type="submit">{{ 'Auth.SignIn' | translate }}</button>
      </div>

      <div class="mt-4 col-12 d-flex justify-content-center align-items-center">
        <div class="text-small">
          <a class="text-danger" (click)="handleResetClick()" data-dismiss="modal">{{
            'LogIn.ForgotPassword' | translate
          }}</a>
        </div>
      </div>
      <div class="mt-2 col-12 d-flex justify-content-center align-items-center">
        <div class="text-small">
          {{ 'LogIn.DontHaveAccount' | translate }}
          <a class="text-danger" data-dismiss="modal" (click)="handleSignUpClick()">{{ 'Auth.Register' | translate }}</a
          >.
        </div>
      </div>
    </div>
  </form>
</div>
