<ng-container *ngIf="medBoardsData$ | async as medBoardsData">
  <section class="container" *ngIf="medBoardsData.boards.active?.length > 0">
    <div class="row mb-4">
      <div class="col-12 d-flex align-items-md-baseline align-items-end">
        <h4 class="mr-4 mb-0 medboards-headline hide-mobile">medboards</h4>
        <a class="mb-1 d-flex anchor" [href]="medboardsUrl" draggable="false">
          <h4 class="mr-4 mb-0 medboards-headline d-md-none">medboards</h4>
          <p class="mr-2 mb-0 hide-mobile">{{ 'CongressList.MyMedboards' | translate }}</p>
          <i [inlineSVG]="'assets/icons/anchor-right.svg'"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <h5>{{ 'CongressList.MedboardsText' | translate }}</h5>
        <div class="board-card" *ngFor="let card of medBoardsData.boards.active | slice : 0 : 2">
          <a [href]="card.url + '/introduction'">
            <img *ngIf="card.image" [src]="card.image" />
          </a>
          <div replaceToDownloadLink>
            <h6>{{ card.title }}</h6>
            <p>{{ card.description }}</p>
          </div>
        </div>
        <a [href]="medboardsUrl" class="btn ghost-button-light rounded-pill">
          {{ 'CongressList.ShowAll' | translate }}
        </a>
      </div>
      <div class="hide-tablet col-lg-6">
        <img class="w-100" src="assets/images/medboards_background.png" />
      </div>
    </div>
    <app-spacer></app-spacer>
  </section>
</ng-container>
