<ng-container *ngIf="congressLandingPageData$ | async as congressData">
  <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
    <div class="container" *ngIf="congressMetaData.layout">
      <div class="row my-0 my-md-3 mobile-full-width">
        <div class="col-12">
          <div class="header-card card shadow-lg h-100" [class.patients]="isPatientsTodayApp">
            <img
              *ngIf="!congressMetaData.layout.backgroundImage"
              class="card-image"
              src="assets/images/Mobile-Kugel@2x.png"
            />
            <img
              *ngIf="congressMetaData.layout.backgroundImage"
              class="card-image"
              [src]="s3BucketUrl + congressMetaData.layout.backgroundImage"
            />
            <h1 class="hide-desktop show-mobile mb-4 headline" *ngIf="!congressMetaData.layout.logo">
              {{ congressData.title }}
            </h1>
            <div class="row h-100">
              <div class="col-lg-8 pt-lg-4 col-sm-12 col-12 d-flex align-items-center hide-mobile">
                <div
                  [class.text-white]="congressMetaData.headerDarkTheme"
                  class="p-5 d-flex flex-column w-100 h-100 justify-content-between"
                >
                  <div class="mt-0 mt-md-4">
                    <h1>{{ congressData.title }}</h1>
                    <h4 class="my-4">{{ seriesDropdownOptions.length }} {{ 'Event.Series' | translate }}</h4>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 d-flex justify-content-center justify-content-lg-end align-items-end"
                *ngIf="congressMetaData.layout.logo"
              >
                <div class="p-lg-5 p-3 d-flex flex-column w-100 logo">
                  <img [src]="s3BucketUrl + logosImageFolderSlug + congressMetaData.layout.logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3 mt-md-5">
        <div class="left-column col-12 col-md-6">
          <div class="d-flex flex-fill tabs-container">
            <a (click)="changeTab(EventTabs.Programme)" *ngIf="programmeData$ | async">
              <h5
                [ngClass]="selectedTab === EventTabs.Programme ? 'text-danger selected-tab' : ''"
                class="tab m-0 p-1"
                [class.text-dark]="isPatientsTodayApp"
              >
                {{ 'Event.Content' | translate }}
              </h5>
            </a>
            <a (click)="changeTab(EventTabs.Experts)" *ngIf="(expertsData$ | async)?.experts?.length">
              <h5
                [ngClass]="selectedTab === EventTabs.Experts ? 'text-danger selected-tab' : ''"
                class="tab m-0 p-1"
                [class.text-dark]="isPatientsTodayApp"
              >
                {{ 'ChannelPage.Experts' | translate }}
              </h5>
            </a>
          </div>

          <div class="divider w-100 mb-3"></div>

          <ng-container *ngIf="selectedTab === EventTabs.Programme">
            <app-video-series></app-video-series>
          </ng-container>

          <ng-container *ngIf="selectedTab === EventTabs.Experts">
            <div class="experts-section my-4" *ngIf="expertsData$ | async as expertsData">
              <ng-container *ngFor="let expert of expertsData.experts">
                <app-expert-list-item [expert]="expert"></app-expert-list-item>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="right-column col-12 offset-0 offset-md-1 col-md-5">
          <div class="divider w-100 my-2"></div>
          <div class="py-3">
            <h4>{{ 'Event.Description' | translate }}</h4>
          </div>
          <p replaceToDownloadLink [innerHTML]="congressData.description"></p>

          <ng-container *ifApp="'MedToday'">
            <div id="sponsors-section" class="mt-5" *ngIf="sponsors$ | async as sponsors">
              <div class="divider w-100 my-3 mb-0"></div>
              <p class="text-transparent">{{ 'Event.SponsorNoInfluence' | translate }}</p>
              <app-sponsors-box
                class="pl-2 d-block"
                [noContainerPadding]="true"
                [noTopDivider]="true"
                [sponsors]="sponsors"
                [showCategories]="true"
              ></app-sponsors-box>
            </div>
          </ng-container>

          <ng-container *ifApp="'PatientsToday'">
            <div id="partners-section" class="mt-5" *ngIf="sponsors$ | async as sponsors">
              <div class="divider w-100 my-3 mb-0"></div>
              <p class="w-100 pl-2 mb-0">{{ 'Event.PartnerSponsorsNoInfluence' | translate }}</p>
              <app-sponsors-box class="stick-top" [sponsors]="sponsors" [noTopDivider]="true"></app-sponsors-box>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<app-spacer></app-spacer>
<app-async-state-overlay [processKey]="loadCongressLayoutDataAsyncKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="loadVideoOnDemandDataAsyncKey"></app-async-state-overlay>
