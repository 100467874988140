<div class="sponsor-pdf-container" *ngIf="sponsor.hasDetailPage">
  <i [inlineSVG]="'assets/icons/booth-icon.svg'"></i>
  <a (click)="handleOpenSponsorBooth(sponsor)">
    <h6 class="text-note ml-16">
      {{ 'ChannelPage.SponsorPdfDownload' | translate }}
    </h6>
  </a>
</div>
<div class="sponsor-pdf-container" *ngIf="!sponsor.hasDetailPage && sponsor.pdf">
  <i class="download-icon brand" [inlineSVG]="'assets/icons/pdf-download.svg'"></i>
  <a
    (click)="handleDownloadSponsorPdf(sponsor.pdf)"
    target="_blank"
    [href]="s3BucketUrl + pdfUrl + sponsor.pdf"
    download
  >
    <h6 class="text-note ml-16">
      {{ 'ChannelPage.SponsorPdfDownload' | translate }}
    </h6>
  </a>
</div>
