import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../core/store/reducers';
import { handleAsyncErrors } from '../../../../core/async-state/operators/handle-errors';
import { OnDemandVideoCrossPlatformSearchResultResponse } from 'medtoday-models-library';
import { AsyncAction } from '../../../../core/async-state/models/async-action.model';
import { of } from 'rxjs';
import { SearchService } from '../services/search.service';
import {
  LoadSearchResults,
  LoadSearchResultsFail,
  LoadSearchResultsSuccess,
  SearchActionTypes
} from '../actions/search.actions';
import { SearchSelectors } from '../selectors/search.selectors';

@Injectable({
  providedIn: 'root'
})
export class SearchEffects {
  searchTerm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActionTypes.LoadSearchResults),
        withLatestFrom(this.store.select(SearchSelectors.selectGetNextPage)),
        switchMap(([action, nextPage]: [LoadSearchResults, number | null]) =>
          this.api.search(action.searchTerm, action.reset ? 1 : nextPage!).pipe(
            handleAsyncErrors(() => new LoadSearchResultsFail()),
            map(
              (response: OnDemandVideoCrossPlatformSearchResultResponse) =>
                new LoadSearchResultsSuccess(response, !action.reset)
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(private actions$: Actions, public store: Store<BaseAppState>, public api: SearchService) {}
}
