import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { OnDemandVideoListItem } from 'medtoday-models-library/lib/models';
import { filter, map, startWith, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { GoToOnDemandVideo } from '../../../../core/router/actions/main-navigation.actions';
import { FormControl } from '@angular/forms';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { Programme } from 'medtoday-models-library';
import { ObservableComponent } from '../../../../shared/components/observable/observable.component';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../core/store/reducers';
import { getProgrammeData } from '../../../../core/data/selectors/data-api.selectors';
import { getCongressSlugRouterParam } from '../../../../core/router/selectors/router.selectors';
import { findKey } from 'lodash';
import { IS_MED_TODAY_APP } from '../../../../core/definitions/app.definitions';
import { KNOWLEDGE_SERIES_OPTIONS_TITLES } from '../../knowledge-series-constants';

declare const jwplayer;

@Component({
  selector: 'app-video-series',
  templateUrl: './video-series.component.html',
  styleUrls: ['./video-series.component.scss']
})
export class VideoSeriesComponent extends ObservableComponent implements OnInit, AfterViewInit {
  seriesDropdownOptions = Object.keys(this.knowledgeSeriesVideosIds);
  selectedSeries = new FormControl(this.seriesDropdownOptions[0]);
  selectedSeriesVideos$: Observable<OnDemandVideoListItem[]>;
  programmeData$ = this.store.select(getProgrammeData);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  seriesNames = KNOWLEDGE_SERIES_OPTIONS_TITLES;

  @Input() currentVideoId: number | null;

  constructor(
    @Inject('knowledgeSeriesVideosIds') private knowledgeSeriesVideosIds: object,
    protected store: Store<BaseAppState>,
    @Inject(IS_MED_TODAY_APP) public isMedtodayApp: string
  ) {
    super();
  }

  ngOnInit(): void {
    this.changeSeriesIfVideoProvided();
    this.observeVideoSeries();
  }

  ngAfterViewInit() {
    this.initAutoPlayInSeries();
  }

  private observeVideoSeries() {
    this.selectedSeriesVideos$ = combineLatest([
      this.programmeData$,
      this.selectedSeries.valueChanges.pipe(startWith(this.selectedSeries.value))
    ]).pipe(
      takeUntil(this.ngDestroy$),
      filter(([program, selectedSeries]: [Programme, string]) => !!program && !!selectedSeries),
      map(([program, selectedSeries]) =>
        program.onDemandVideos
          .filter(vod => this.knowledgeSeriesVideosIds[selectedSeries].includes(vod.id))
          .sort((vodA, vodB) => vodA.id - vodB.id)
      )
    );
  }

  handleVideoClick(video: OnDemandVideoListItem) {
    this.congressSlug$.pipe(take(1)).subscribe(slug => {
      this.store.dispatch(new GoToOnDemandVideo(video.id, true, undefined, slug));
    });
  }

  handleSelectedSeries(series: string) {
    this.selectedSeries.setValue(series);
  }

  private changeSeriesIfVideoProvided() {
    if (this.currentVideoId) {
      const series = findKey(
        this.knowledgeSeriesVideosIds,
        (item: number[]) => item.indexOf(this.currentVideoId!) !== -1
      );
      if (series) {
        this.selectedSeries.setValue(series, { emitEvent: true });
      }
    }
  }

  private initAutoPlayInSeries() {
    setTimeout(() => {
      fromEvent(jwplayer(), 'complete')
        .pipe(takeUntil(this.ngDestroy$), withLatestFrom(this.selectedSeriesVideos$, this.congressSlug$))
        .subscribe(([_, selectedSeriesVideos, congressSlug]) => {
          if (selectedSeriesVideos.length === 1) {
            return;
          }

          if (this.selectedSeries.value) {
            const nextVideo =
              selectedSeriesVideos[selectedSeriesVideos.findIndex(video => video.id === this.currentVideoId) + 1];
            if (nextVideo) {
              this.store.dispatch(new GoToOnDemandVideo(nextVideo.id, true, undefined, congressSlug));
            }
          }
        });
    });
  }
}
