<div
  class="sponsors-container card-body"
  [class.no-border]="noTopDivider"
  [class.p-3]="!noContainerPadding"
  [class.p-0]="noContainerPadding"
>
  <div class="row pt-0">
    <p
      *ngIf="sponsorAreaTitle"
      class="col-12 px-2 sponsor-area-title"
      [class.text-dark]="applicationName === 'PatientsToday'"
    >
      {{ sponsorAreaTitle }}
    </p>
  </div>
  <div class="row flex-wrap">
    <ng-container *ngIf="!showCategories">
      <ng-container *ngFor="let sponsor of sponsors">
        <ng-container [ngTemplateOutlet]="sponsorRef" [ngTemplateOutletContext]="{ sponsor }"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showCategories">
      <ng-container *ngFor="let item of sponsorsByLevels | keyvalue : unsorted">
        <div class="sponsor-category-container">
          <div class="w-100 px-2" *ngIf="item.key !== 'null'">
            <h5>{{ item.key | translate }}</h5>
          </div>
          <div class="sponsors">
            <ng-container *ngFor="let sponsor of item.value">
              <ng-container [ngTemplateOutlet]="sponsorRef" [ngTemplateOutletContext]="{ sponsor }"></ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template let-sponsor="sponsor" #sponsorRef>
  <div
    class="col-12 p-2 logo-column"
    *ngIf="sponsor.logo"
    [class.col-xl-4]="sponsorsPerLine === 3"
    [class.col-xl-6]="sponsorsPerLine === 2"
  >
    <div class="card logo d-flex overflow-hidden">
      <a *ngIf="sponsor.link" [href]="sponsor.link" target="_blank">
        <img draggable="false" [src]="s3BucketUrl + logosUrl + sponsor.logo" />
      </a>
      <img *ngIf="!sponsor.link" draggable="false" [src]="s3BucketUrl + logosUrl + sponsor.logo" />
    </div>
    <app-sponsor-more-info-link [sponsor]="sponsor" [congressSlug]="congressSlug$ | async"></app-sponsor-more-info-link>
  </div>
</ng-template>
