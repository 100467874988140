<div
  class="teaser-card h-100"
  [class.cme]="isCMECard"
  [class.sponsor]="isFeatureCard"
  [class.newsletter]="isSubscribeCard"
  [class.register]="isRegisterCard"
  [class.none]="isNoStyleCard"
  [class.generic]="isGenericCard"
  [class.image-only]="isNoStyleCard && !title && !description"
  [class.large-card]="cardSize === 'large'"
  [class.teaser-card-image]="cardImage"
  [class.qr-code]="isQRCard"
  [ngClass]="cardCongressTheme"
>
  <img class="hide-desktop newlsetter-img" src="assets/images/Bild-hottopic@2x.png" *ngIf="isSubscribeCard" />

  <!--  BADGE -->
  <ng-container
    *ngIf="badgeText || isFeatureCard || isCMECard"
    [ngTemplateOutlet]="labelBadge"
    [ngTemplateOutletContext]="{ labelText: getBadgeText }"
  >
  </ng-container>

  <!--  TeaserBackground -->
  <ng-container
    *ngIf="cardSize === 'large' && applicationName === 'MedToday'"
    [ngTemplateOutlet]="teaserBackground"
    [ngTemplateOutletContext]="{
      backgroundImg: isCMECard || isFeatureCard ? congressBackgroundImage : ''
    }"
  >
  </ng-container>

  <!--  Small/Medium CardImage -->
  <ng-container *ngIf="cardImage && cardSize !== 'large'">
    <div class="d-block card-image card-small-image mb-4">
      <ng-container *ngIf="buttonUrl">
        <a target="_blank" [href]="buttonUrl">
          <img [src]="cardImageFullUrl" />
        </a>
      </ng-container>
      <img *ngIf="!buttonUrl" [src]="cardImageFullUrl" />
    </div>
  </ng-container>

  <!--  TeaserBody -->
  <div class="col col-auto p-0 teaser-card-body" [class.col-6]="cardSize === 'large'">
    <ng-container
      [ngTemplateOutlet]="teaserContent"
      [ngTemplateOutletContext]="{ content: { title: title, description: description } }"
    >
    </ng-container>

    <ng-container *ngIf="buttonLabel" [ngTemplateOutletContext]="{ type: type }" [ngTemplateOutlet]="teaserActionBtn">
    </ng-container>
  </div>

  <!-- Large CardImage -->
  <ng-container *ngIf="cardImage && cardSize === 'large'">
    <div class="col col-6 d-none d-md-block card-image">
      <img [src]="cardImageFullUrl" />
    </div>
  </ng-container>

  <!--  AdditionalInfo -->
  <ng-container
    *ngIf="isRegisterCard"
    [ngTemplateOutletContext]="{ cardSize: cardSize }"
    [ngTemplateOutlet]="teaserInfo"
  >
  </ng-container>
</div>

<ng-template #labelBadge let-labelText="labelText">
  <label>
    <span>{{ labelText | translate }}</span>
  </label>
</ng-template>

<ng-template #teaserBackground let-backgroundImg="backgroundImg">
  <img [alt]="title" [src]="backgroundImg" *ngIf="backgroundImg" />

  <ng-container *ngIf="!backgroundImg">
    <img class="background-image bubbles-bottom" [alt]="title" [src]="'/assets/images/bubbles-bottom.png'" />
    <img class="background-image bubbles-top" [alt]="title" [src]="'/assets/images/bubbles-top.png'" />
  </ng-container>
</ng-template>

<ng-template #teaserContent let-content="content">
  <h4>{{ content.title }}</h4>
  <p replaceToDownloadLink [innerHTML]="content.description"></p>
</ng-template>

<ng-template #teaserActionBtn let-type="type">
  <button
    class="btn"
    [ngClass]="{
      'btn-primary': isCMECard,
      'btn-featured': isFeatureCard,
      'ghost-button-light': isNoStyleCard || isQRCard,
      'btn-danger rounded-pill': isRegisterCard || isSubscribeCard
    }"
    (click)="handleTeaserCardClick(type)"
  >
    {{ buttonLabel }}
  </button>
</ng-template>

<ng-template #teaserInfo let-cardSize="cardSize">
  <span [ngClass]="{ mobile: cardSize === 'small' }">
    <br />
    {{ 'Auth.AlreadyRegistered' | translate }}
    <a (click)="handleGoToLoginClick()">{{ 'Auth.SignIn' | translate }}</a
    >.
  </span>
</ng-template>
