import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { SurveyActionTypes } from '../actions/survey.actions';

@Injectable({
  providedIn: 'root'
})
export class SurveyEffects {
  hideMainSurveyPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActionTypes.CloseMainSurveyPopup),
        tap(() => {
          localStorage.setItem('main-survey-closed', JSON.stringify(true));
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
