import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

const UPDATE_CHECK_INTERVAL = 1000 * 40;

export function appUpdaterInitFactory(s: AppUpdateService) {
  return () => s.init();
}

@Injectable({ providedIn: 'root' })
export class AppUpdateService {
  private checkInterval;

  constructor(public updates: SwUpdate, private injector: Injector) {}

  public init() {
    this.initUpdateListeners();
    this.initCheckInterval();
  }

  private initCheckInterval() {
    // We wait until the application has determined we are "stable" before we start the check interval
    // https://github.com/angular/angular-cli/issues/8779#issuecomment-350737663
    const applicationRef = this.injector.get(ApplicationRef);
    applicationRef.isStable.subscribe(stable => {
      if (stable && this.updates.isEnabled && !this.checkInterval) {
        this.checkInterval = setInterval(() => {
          if (!navigator || navigator.onLine) {
            this.updates.checkForUpdate();
          }
        }, UPDATE_CHECK_INTERVAL);
      }
    });
  }

  private initUpdateListeners() {
    if (this.updates.versionUpdates) {
      this.updates.versionUpdates.subscribe((event: VersionReadyEvent) => {
        if (event.latestVersion) {
          this.updates.activateUpdate().then(() => document.location.reload());
        }
      });
    }
  }
}
