import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CongressDropdownComponent } from './components/congress-dropdown/congress-dropdown.component';
import { CongressDropdownListMobileComponent } from './components/congress-dropdown-list-mobile/congress-dropdown-list-mobile.component';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer/nav-bar.reducer';
import { SharedComponentsModule, SharedDirectivesModule } from '../../../shared';
import { CongressDropdownListItemComponent } from './components/congress-dropdown-list-item/congress-dropdown-list-item.component';

export const COMPONENTS = [
  NavBarComponent,
  NavigationMenuComponent,
  CongressDropdownComponent,
  CongressDropdownListItemComponent,
  CongressDropdownListMobileComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    InlineSVGModule,
    StoreModule.forFeature('nav-bar-store', reducer),
    SharedDirectivesModule,
    SharedComponentsModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [{ provide: 'domain', useValue: 'de' }]
})
export class NavBarModule {}
