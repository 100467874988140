import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayerCustomDimensionsService, PartialCustomDimensionsSet } from './ga-data-layer.service';

@Injectable()
export class AnalyticsService {
  constructor(
    private _dataLayerCustomDimensions: DataLayerCustomDimensionsService,
    private _router: Router,
  ) {}

  pageView(someDimensions: PartialCustomDimensionsSet, overridePath?: string) {
    this._dataLayerCustomDimensions.dimensions = someDimensions;
    // tslint:disable-next-line: no-any
    (<any>window).dataLayer.push({
      event: 'pageView',
      virtualPageURL: overridePath || this._router.url,
      virtualPageTitle: overridePath || document.title,
    });
  }

  pushTag(
    someDimensions: PartialCustomDimensionsSet,
    eventName: string,
    eventLabel?: string,
    data?: string | boolean | object,
  ) {
    this._dataLayerCustomDimensions.dimensions = someDimensions;
    this._dataLayerCustomDimensions.trigger();

    const event = {
      event: eventName,
    };

    if (data instanceof Object) {
      Object.keys(data).forEach((key: string) => {
        event[key] = data[key];
      });
    } else {
      event[eventLabel!] = data;
    }
    // tslint:disable-next-line: no-any
    (<any>window).dataLayer.push(event);
  }

}
