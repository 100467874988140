import { Component } from '@angular/core';
import { TokenValidationService } from 'projects/todaylib/core/auth/services/token-validation.service';
import { EventPageComponent } from 'projects/todaylib/shared/pages/components/event-page/event-page.component';
import { EventRegistrationService } from 'projects/todaylib/shared/services/event-registration.service';
import { CongressUtils } from 'projects/todaylib/shared/utilities/congress.utils';

@Component({
  selector: 'app-event-page',
  templateUrl: '../../../../../../todaylib/shared/pages/components/event-page/event-page.component.html',
  styleUrls: ['../../../../../../todaylib/shared/pages/components/event-page/event-page.component.scss'],
  providers: [EventRegistrationService, CongressUtils, TokenValidationService]
})
export class MedTodayEventPageComponent extends EventPageComponent {}
