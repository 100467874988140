import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';

import { RouterStateUrl } from '../reducers/custom-router-state-serializer';
import { ROUTER_STATE_KEY } from '../definitions/router.definitions';
import { CONGRESS_PARAMS, GLOBAL_PARAMS, MISC_PARAMS } from '../definitions/main-navigation.definitions';

export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>(ROUTER_STATE_KEY);

export const getRouterState = createSelector(selectRouterState, routerState =>
  routerState && routerState.state ? routerState.state || {} : {}
);

export const getUrl = createSelector(getRouterState, (routerState: RouterStateUrl) =>
  routerState ? routerState.url || null : null
);

export const getParams = createSelector(getRouterState, (routerState: RouterStateUrl) =>
  routerState ? routerState.params || {} : {}
);

export const getQueryParams = createSelector(getRouterState, (routerState: RouterStateUrl) =>
  routerState ? routerState.queryParams || {} : {}
);

export const getReturnUrl = createSelector(getQueryParams, params => params['returnUrl'] as string);

export const getFirstUrlSegment = createSelector(selectRouterState, routerState =>
  routerState && routerState.state ? routerState.state.firstUrlSegment || null : null
);

export const getThirdUrlSegment = createSelector(selectRouterState, routerState =>
  routerState && routerState.state ? routerState.state.thirdUrlSegment || null : null
);

export const getLastUrlSegment = createSelector(getUrl, url => (!url ? '' : url.split('/').slice(-1)[0]));

export const getCongressSlugRouterParam = createSelector(getParams, params => params[CONGRESS_PARAMS.congressSlug]);

export const getChannelSlugRouterParam = createSelector(getParams, params => params[CONGRESS_PARAMS.channelSlug]);

export const getMediaLibraryItemSlugRouterParam = createSelector(getParams, params => params[MISC_PARAMS.fileSlug]);

export const getOnDemandVideoIdRouterParam = createSelector(
  getParams,
  params => params[CONGRESS_PARAMS.onDemandVideoId]
);

export const getPartnerSlugParam = createSelector(getParams, params => params[GLOBAL_PARAMS.partnerSlug]);

export const getSponsorBoothSlugParam = createSelector(getParams, params => params[CONGRESS_PARAMS.sponsorBoothSlug]);
