import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseAppState } from '../../../core/store/reducers';
import { Store } from '@ngrx/store';
import { getSelectedUpcomingEvent } from '../../../core/data/selectors/data-api.selectors';
import { Mode } from 'medtoday-models-library/lib/models/responses/congress/congress-metadata.model';
import { CongressUtils } from '../../utilities/congress.utils';
import { UpcomingEventListItemModel } from 'medtoday-models-library';
import { ICalendar } from 'datebook';

@Component({
  selector: 'app-event-info-modal',
  templateUrl: './event-info-modal.component.html',
  styleUrls: ['./event-info-modal.component.scss'],
  providers: [CongressUtils],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventInfoModalComponent {
  selectedUpcomingEvent$ = this.store.select(getSelectedUpcomingEvent);
  Mode = Mode;
  constructor(private store: Store<BaseAppState>, private congressUtils: CongressUtils) {}

  handleAddToCalendarClick(upcomingEventItem: UpcomingEventListItemModel) {
    const icalendar = new ICalendar(
      this.congressUtils.createCalendarEvent(this.congressUtils.createCalendarEventItem(undefined, upcomingEventItem))
    );
    icalendar.download();
  }
}
