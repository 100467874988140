import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  CrossPlatformSearchVideoListItem,
  MediaProvider,
  OnDemandVideoListItem,
  Sponsor
} from 'medtoday-models-library/lib/models';
import { imagePaths } from '../../../../medtoday/src/environments/environment';
import { ExpertIdentity } from 'medtoday-models-library/lib/models/responses/expert/expert-identity';
import { TranslateService } from '@ngx-translate/core';
import { BaseAppState } from '../../../core/store/reducers';
import { Store } from '@ngrx/store';
import { getCongressMetaData } from '../../../core/data/selectors/data-api.selectors';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface VideoListItem {
  experts: ExpertIdentity[] | null;
  id: number;
  videoType: string;
  subVideoType?: string | null;
  title: string | null;
  thumbnail: string | undefined;
  duration: string | number;
  timeSlotStart?: string | null;
  topicId?: number;
  subTopicIds?: number[];
  formatId: number | null;
  sponsors?: Sponsor[] | null;
  mediaIdentifier?: string | null;
  topicName?: string;
  seriesName?: string | null;
  congressShortcut?: string | null;
  congressColor?: string | null;
  congressSlug?: string;
  languages: string[];
  spokenLanguage: string | null;
  mediaProvider?: MediaProvider;
}

@Component({
  selector: 'video-list-item',
  templateUrl: './video-list-item.component.html',
  styleUrls: ['./video-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoListItemComponent {
  congressMetadata$ = this.store.select(getCongressMetaData);
  @Input() video: OnDemandVideoListItem | VideoListItem | CrossPlatformSearchVideoListItem;
  @Input() thumbnailsDirectory = this.s3BucketUrl + imagePaths.thumbnails;
  @Input() topicTitle: string | undefined;
  @Input() formatTitle: string | undefined;

  @Output() goToVideo = new EventEmitter<VideoListItem>();

  mediaProvider$: Observable<MediaProvider> = this.congressMetadata$.pipe(
    map(congressMetaData =>
      this.video['mediaProvider'] ? this.video['mediaProvider'] : congressMetaData.mediaProvider
    )
  );

  constructor(
    @Inject('applicationName') public applicationName,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    public translateService: TranslateService,
    private store: Store<BaseAppState>
  ) {}

  handleGoToVideoClick() {
    this.goToVideo.emit(this.video as VideoListItem);
  }
}
