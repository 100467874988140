import { NavBarActions, NavBarActionTypes } from '../actions/nav-bar.actions';

export interface NavBarState {
  navBarToggled: boolean | undefined;
}

const initialState: NavBarState = {
  navBarToggled: false
};

export function reducer(state = initialState, action: NavBarActions) {
  switch (action.type) {
    case NavBarActionTypes.ToggleNavBar: {
      return {
        ...state,
        navBarToggled: action.toggledNavBar
      };
    }
    default:
      return state;
  }
}
