import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicScriptLoaderService } from '../../services/dynamic-script-loader-service.service';
import { environment } from '../../../../medtoday/src/environments/environment';

@Component({
  selector: 'app-mt-social-wall',
  templateUrl: './mt-social-wall.component.html',
  styleUrls: ['./mt-social-wall.component.scss']
})
export class MtSocialWallComponent implements AfterViewInit, OnDestroy {
  currentLang = this.translateService.currentLang;
  linkedInUrl = environment.linkedInProfileUrl;
  twitterWallId = environment.twitterWallId;
  twitterWallUrl = `https://widget.tagembed.com/${this.twitterWallId}?view`;

  constructor(private translateService: TranslateService, private dynamicScriptLoader: DynamicScriptLoaderService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.dynamicScriptLoader.loadScript(`https://widget.tagembed.com/embed.min.js`);
    });
  }

  ngOnDestroy() {
    this.dynamicScriptLoader.removeScript(`https://widget.tagembed.com/embed.min.js`);
  }
}
