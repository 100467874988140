import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {filter, map, tap, withLatestFrom} from 'rxjs/operators';

import {
  AsyncStateActionTypes,
  SetAsyncStateFail,
  SetAsyncStateRetry,
  SetAsyncStateStart,
  SetAsyncStateSuccess,
} from '../actions/async-state.actions';
import {AsyncAction} from '../models/async-action.model';
import {AsyncState} from '../reducers/async-state.reducers';
import {makeGetAsyncInitialAction} from '../selectors/async-state.selectors';

@Injectable()
export class AsyncStateEffects {

  handleAsyncAction$ = createEffect(() => this.actions$.pipe(
    filter((action) => Boolean((action as AsyncAction).asyncKey)),
    tap((action: AsyncAction) => {
      if (!action.asyncState) {
        return;
      }
      switch (action.asyncState) {
        case 'start':
          this.store.dispatch(new SetAsyncStateStart(action.asyncKey, action));
          break;
        case 'success':
          this.store.dispatch(new SetAsyncStateSuccess(action.asyncKey));
          break;
        case 'fail':
          this.store.dispatch(
            new SetAsyncStateFail(
              action.asyncKey,
              action.asyncError,
              action.asyncHttpError,
              action.asyncErrorTitle,
              action.asyncErrorMessage,
              action.asyncValidationErrors,
            ),
          );
          break;
        default:
          break;
      }
    }),
  ), { dispatch: false });


  retryOriginalAction$ = createEffect(() => this.actions$.pipe(
    ofType(AsyncStateActionTypes.SetAsyncStateRetry),
    withLatestFrom(this.store),
    map(([action, state]: [SetAsyncStateRetry, AsyncState]) => {
      return makeGetAsyncInitialAction(action.key)(state) as Action;
    }),
  ), { dispatch: true });

  constructor(private actions$: Actions, private store: Store<AsyncState>) {}
}
