import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Channel,
  CongressCmePageDataResponse,
  CongressesListResponse,
  CongressLandingPage,
  CongressMetadata,
  ExpertsResponse,
  FeaturedContentResponse,
  MedicalEducationResponse,
  NewsletterCreateUpdateResponseModel,
  OnDemandVideoDetail,
  OnDemandVideoListResponse,
  OnDemandVideoSearchResultResponse,
  Programme,
  QuestionAndAnswerRequestModel,
  CongressParticipantResponseModel,
  CongressTopicsResponseModel,
  UpcomingEventsResponseModel,
  UpcomingEventsTopicLanguagesResponseModel,
  UpcomingEventsDatesResponseModel,
  UpcomingEventsCountriesResponseModel,
  NewsletterCreateUpdateRequestModel,
  OnDemandVideoUserData,
  CmeCertificatesResponse
} from 'medtoday-models-library';
import { createNeedsAuthenticationHeader } from '../utilities/networking.utilites';

@Injectable({ providedIn: 'root' })
export class DataApiService {
  constructor(@Inject('apiBaseUrl') protected apiBaseUrl: string, protected http: HttpClient) {}

  updateNewsLetterSetting(needAuth: boolean, userNewsletter: NewsletterCreateUpdateRequestModel) {
    let request = { ...userNewsletter };
    let headers = {};

    if (needAuth) {
      headers = createNeedsAuthenticationHeader();
    } else {
      request = {
        ...userNewsletter
      };
    }

    return this.http.post<NewsletterCreateUpdateResponseModel>(`${this.apiBaseUrl}/user/newsletter`, request, headers);
  }

  getCongresses() {
    return this.http.get<CongressesListResponse>(`${this.apiBaseUrl}/congresses`);
  }

  getCongressTopics(exclude: string | null) {
    let params = '';
    if (exclude) {
      params = `congressModeExclude=${exclude}`;
    }

    return this.http.get<CongressTopicsResponseModel>(`${this.apiBaseUrl}/congress-topics?${params}`);
  }

  getCongressMetaData(congressSlug: string) {
    return this.http.get<CongressMetadata>(`${this.apiBaseUrl}/congress/${congressSlug}/metadata`);
  }

  getHomeData(congressSlug: string) {
    return this.http.get<CongressLandingPage>(`${this.apiBaseUrl}/congress/${congressSlug}`);
  }

  getExperts(congressSlug: string) {
    return this.http.get<ExpertsResponse>(`${this.apiBaseUrl}/congress/${congressSlug}/experts`);
  }

  getChannelDataBySlug(congressSlug: string, channelSlug: string) {
    const headers = createNeedsAuthenticationHeader();

    return this.http.get<Channel>(`${this.apiBaseUrl}/congress/${congressSlug}/channel/${channelSlug}`, headers);
  }

  getTokenizedChannel(congressSlug: string, channelSlug: string, token: string) {
    return this.http.get<OnDemandVideoDetail>(
      `${this.apiBaseUrl}/tokenAccess/${token}/congress/${congressSlug}/channel/${channelSlug}`
    );
  }

  getProgrammeData(congressSlug: string) {
    return this.http.get<Programme>(`${this.apiBaseUrl}/congress/${congressSlug}/program`);
  }

  getOnDemandVideos(congressSlug: string) {
    return this.http.get<OnDemandVideoListResponse>(`${this.apiBaseUrl}/congress/${congressSlug}/onDemand`);
  }

  getOnDemandVideo( onDemandVideoId: number, congressSlug?: string) {
    const headers = createNeedsAuthenticationHeader();

    return this.http.get<OnDemandVideoDetail>(
      `${this.apiBaseUrl}/congress/${congressSlug}/onDemand/${onDemandVideoId}`,
      headers
    );
  }

  getTokenizedOnDemandVideo(onDemandVideoId: number, token: string, congressSlug?: string) {
    return this.http.get<OnDemandVideoDetail>(
      `${this.apiBaseUrl}/tokenAccess/${token}/congress/${congressSlug}/onDemand/${onDemandVideoId}`
    );
  }

  addQuestionForOnDemandVideo(congressSlug: string, onDemandVideoId: number, request: QuestionAndAnswerRequestModel) {
    const headers = createNeedsAuthenticationHeader();

    return this.http.post<{}>(
      `${this.apiBaseUrl}/congress/${congressSlug}/onDemand/${onDemandVideoId}/hellobox`,
      request,
      headers
    );
  }

  getFeaturedContent(congressSlug: string) {
    return this.http.get<FeaturedContentResponse>(`${this.apiBaseUrl}/congress/${congressSlug}/featured-content`);
  }

  getMedicalEducationData() {
    return this.http.get<MedicalEducationResponse>(`${this.apiBaseUrl}/page/medicalEducation`);
  }

  getUpcomingEventsPageData(
    page: number | null,
    topicCategory: string | null,
    year: number | null,
    month: number | null,
    language: string | null,
    limit: number | null,
    onlyCme: boolean,
    onlyLive: boolean
  ) {
    let params = new HttpParams();
    params = params.set('page', page ?? '');
    params = params.set('topicCategory', topicCategory ?? '');
    params = params.set('year', year ?? '');
    params = params.set('month', month ?? '');
    params = params.set('language', language ?? '');
    params = params.set('limit', limit ?? '');
    params = params.set('onlyCme', onlyCme);
    params = params.set('onlyLive', onlyLive);
    return this.http.get<UpcomingEventsResponseModel>(`${this.apiBaseUrl}/upcomingEvents?${params.toString()}`);
  }

  getUpcomingEventsTopicCategories() {
    return this.http.get<UpcomingEventsTopicLanguagesResponseModel>(
      `${this.apiBaseUrl}/upcomingEvents/topicCategories`
    );
  }

  getUpcomingEventsDates() {
    return this.http.get<UpcomingEventsDatesResponseModel>(`${this.apiBaseUrl}/upcomingEvents/dates`);
  }

  getUpcomingEventsCountries() {
    return this.http.get<UpcomingEventsCountriesResponseModel>(`${this.apiBaseUrl}/upcomingEvents/countries`);
  }

  getPagedOnDemandVideos(
    page: number | null,
    topicCategoryId: number | null,
    topicId: number | null,
    congressMode: string | null,
    formatId: number | null,
    tagId?: number | null
  ) {
    let params = new HttpParams();

    params = params.set('page', page ?? '');
    params = params.set('topicCategoryId', topicCategoryId ?? '');
    params = params.set('topicId', topicId ?? '');
    params = params.set('keyWord', congressMode ?? '');
    params = params.set('formatId', formatId ?? '');
    params = params.set('tagId', tagId ?? '');

    return this.http.get<OnDemandVideoSearchResultResponse>(`${this.apiBaseUrl}/onDemand?` + params.toString());
  }

  getPagedResearchContributions(
    page: number | null,
    topicCategoryId: number | null,
    subVideoType: string | null,
    topicId: number | null,
    formatId: number | null,
    tagId?: number | null,
    pageId?: number | null
  ) {
    let params = new HttpParams();

    params = params.set('page', page ?? '');
    params = params.set('topicCategoryId', topicCategoryId ?? '');
    params = params.set('subVideoType', subVideoType ?? '');
    params = params.set('topicId', topicId ?? '');
    params = params.set('formatId', formatId ?? '');
    params = params.set('tagId', tagId ?? '');
    params = params.set('pageId', pageId ?? '');

    return this.http.get<OnDemandVideoSearchResultResponse>(
      `${this.apiBaseUrl}/researchContributions?` + params.toString()
    );
  }

  getCongressCmePageData(congressSlug: string) {
    return this.http.get<CongressCmePageDataResponse>(`${this.apiBaseUrl}/congress/${congressSlug}/cme`);
  }

  addCongressParticipant(congressSlug: string, request) {
    const headers = createNeedsAuthenticationHeader();

    return this.http.post<CongressParticipantResponseModel>(
      `${this.apiBaseUrl}/congress/${congressSlug}/participation`,
      request,
      headers
    );
  }

  getCongressParticipantData(congressSlug: string, userSubId: string) {
    return this.http.get<CongressParticipantResponseModel>(
      `${this.apiBaseUrl}/congress/${congressSlug}/participation/${userSubId}`
    );
  }

  getVideoUserData(congressSlug: string, vodId: number) {
    const headers = createNeedsAuthenticationHeader();

    return this.http.get<boolean>(`${this.apiBaseUrl}/congress/${congressSlug}/onDemand/${vodId}/user`, headers);
  }

  setVideoAsWatched(congressSlug: string, vodId: number) {
    const headers = createNeedsAuthenticationHeader();

    return this.http.patch<OnDemandVideoUserData>(
      `${this.apiBaseUrl}/congress/${congressSlug}/onDemand/${vodId}/user`,
      {
        watched: true
      },
      headers
    );
  }

  updateVideoUserPosition(congressSlug: string, vodId: number, position: number) {
    const headers = createNeedsAuthenticationHeader();

    return this.http.patch<boolean>(
      `${this.apiBaseUrl}/congress/${congressSlug}/onDemand/${vodId}/user`,
      {
        position
      },
      headers
    );
  }

  loadCmeCertificates() {
    const headers = createNeedsAuthenticationHeader();

    return this.http.get<CmeCertificatesResponse>(`${this.apiBaseUrl}/my-cme`, headers);
  }
}
