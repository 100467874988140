<main class="container">
  <div class="row mt-0 mt-lg-5 flex-column-reverse flex-lg-row">
    <div class="col-12 col-lg-6 mt-5">
      <h1 class="mb-3" [innerHTML]="'UpcomingEvents.Title' | translate"></h1>
      <h6>
        {{ 'UpcomingEvents.Description' | translate }}
      </h6>
    </div>
    <div class="col-12 col-lg-6 align-items-start justify-content-sm-center justify-content-lg-end d-flex header-image">
      <img class="w-100" src="assets/images/upcoming-events.png" />
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12 d-flex align-items-center justify-content-between mb-3">
      <h3>{{ 'MedicalEducation.FilterContent' | translate }}</h3>
      <button (click)="showFilterForm()" class="btn filter-btn text-light">
        {{
          showFilterFormMobile ? ('MedicalEducation.filterClose' | translate) : ('MedicalEducation.filter' | translate)
        }}
        <i [inlineSVG]="'assets/icons/filter.svg'" class="ml-1"></i>
      </button>
    </div>
  </div>
  <form class="filter-form" [formGroup]="filterForm" name="filter-form" #ngForm="ngForm" novalidate>
    <div class="form-group row mb-5 mobile-full-width">
      <!-- topic categories -->
      <div *ngIf="upcomingEventsTopicCategories$ | async as topicCategories" class="col-lg-3 col-md-6 col-12 mb-3">
        <div class="dropdown secondary w-100">
          <button
            type="button"
            class="btn d-flex dropdown-toggle navigation-menu align-items-center justify-content-between w-100 h-100 text-light"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p class="m-0">
              {{
                filterForm.controls['selectedTopicCategory'].value
                  ? filterForm.controls['selectedTopicCategory'].value
                  : ('MedicalEducation.AllTopicCategories' | translate)
              }}
            </p>
            <i class="bi bi-chevron-down"></i>
          </button>
          <div class="dropdown-menu custom-scrollbar bg-dark w-100" aria-labelledby="select">
            <a
              class="dropdown-item d-flex flex-row"
              [ngClass]="!filterForm.controls['selectedTopicCategory'].value ? 'text-danger' : 'text-light'"
              (click)="handleResetFilter('selectedTopicCategory')"
            >
              {{ 'MedicalEducation.AllTopicCategories' | translate }}
            </a>
            <ng-container *ngFor="let topicCategory of topicCategories.topicCategories">
              <a
                class="dropdown-item d-flex flex-row"
                *ngIf="topicCategory !== 'NO_CLASS'"
                [ngClass]="
                  filterForm.controls['selectedTopicCategory'].value === topicCategory ? 'text-danger' : 'text-light'
                "
                (click)="handleSelectTopicCategory(topicCategory)"
              >
                {{ topicCategory }}
              </a>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- month -->
      <div *ngIf="upcomingEventsDates$ | async as years" class="col-lg-3 col-md-6 col-12 mb-3">
        <div class="dropdown secondary w-100">
          <button
            type="button"
            class="btn d-flex dropdown-toggle navigation-menu align-items-center justify-content-between w-100 h-100 text-light"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p class="m-0">
              {{
                filterForm.controls['selectedDate'].controls['month'].value
                  ? getSelectedDate()
                  : ('UpcomingEvents.Months' | translate)
              }}
            </p>
            <i class="bi bi-chevron-down"></i>
          </button>
          <div class="dropdown-menu custom-scrollbar bg-dark w-100" aria-labelledby="select">
            <a
              class="dropdown-item d-flex flex-row"
              [ngClass]="!filterForm.controls['selectedDate'].value ? 'text-danger' : 'text-light'"
              (click)="handleResetFilter('selectedDate')"
            >
              {{ 'UpcomingEvents.AllMonths' | translate }}
            </a>
            <ng-container *ngFor="let year of years.years">
              <a
                class="dropdown-item d-flex flex-row"
                *ngFor="let month of year.months"
                (click)="handleSelectDate(year.year, month)"
                [ngClass]="
                  filterForm.controls['selectedDate'].controls['month'].value === month &&
                  filterForm.controls['selectedDate'].controls['year'].value === year.year
                    ? 'text-danger'
                    : 'text-light'
                "
              >
                {{ returnMonthName(month) }} {{ year.year }}
              </a>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- country -->
      <div *ngIf="upcomingEventsCountries$ | async as countries" class="col-lg-3 col-md-6 col-12 mb-3">
        <div class="dropdown secondary w-100">
          <button
            type="button"
            class="btn d-flex dropdown-toggle navigation-menu align-items-center justify-content-between w-100 h-100 text-light"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p class="m-0">
              {{
                filterForm.controls['selectedCountry'].value
                  ? ('Countries.' + filterForm.controls['selectedCountry'].value | translate)
                  : ('UpcomingEvents.Country' | translate)
              }}
            </p>
            <i class="bi bi-chevron-down"></i>
          </button>
          <div class="dropdown-menu custom-scrollbar bg-dark w-100" aria-labelledby="select">
            <a
              class="dropdown-item d-flex flex-row"
              [ngClass]="!filterForm.controls['selectedCountry'].value ? 'text-danger' : 'text-light'"
              (click)="handleResetFilter('selectedCountry')"
            >
              {{ 'UpcomingEvents.AllCountries' | translate }}
            </a>
            <a
              class="dropdown-item d-flex flex-row"
              *ngFor="let country of countries.countries"
              (click)="handleSelectCountry(country)"
              [ngClass]="filterForm.controls['selectedCountry'].value === country ? 'text-danger' : 'text-light'"
            >
              {{ 'Countries.' + country | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- upcoming -->
  <section *ngIf="upcomingEventsPageData$ | async as data">
    <div *ngIf="data.events && data.events.length > 0; else noEvents">
      <div class="events-wrapper">
        <ng-container *ngFor="let event of data.events">
          <app-upcoming-event-card [event]="event" [user]="userAttributes$ | async"></app-upcoming-event-card>
        </ng-container>
      </div>
      <div *ngIf="data.nextPage" class="row btn-row flex-row align-items-center justify-content-center mb-3">
        <div class="divider w-100"></div>
        <button
          type="button"
          class="btn ghost-button-light rounded-pill w-sm-100 more-btn"
          (click)="handleLoadMoreEventsClick()"
        >
          {{ 'MedicalEducation.ShowMoreButtonLabel' | translate }}
        </button>
      </div>
    </div>
    <ng-template #noEvents>
      <div class="no-events">
        <p>{{ 'UpcomingEvents.NoEvents' | translate }}</p>
      </div>
    </ng-template>
  </section>
</main>
<app-spacer></app-spacer>
