import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  AuthUINavigationActionTypes,
  GoToEditPassword,
  GoToEditUser,
  GoToLogin,
  GoToRequestPasswordReset,
  GoToSignUp,
  GoToSignUpSuccess
} from 'projects/medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';
import { AUTH_UI_PATHS } from 'projects/medtoday/src/app/auth-ui/definitions/auth-ui-navigation.definitions';
import { tap } from 'rxjs/operators';

import { BaseAppState } from '../../store/reducers';

@Injectable()
export class AuthUINavigationEffects {
  goToLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActionTypes.GoToLogin),
        tap((_action: GoToLogin) => {
          this.router.navigate([AUTH_UI_PATHS.root.login]).then(() => {
            if (_action.reload) {
              window.location.reload();
            }
          });
        })
      ),
    { dispatch: false }
  );

  goToSignUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActionTypes.GoToSignUp),
        tap((_action: GoToSignUp) => {
          this.router.navigate([AUTH_UI_PATHS.root.signUp], {
            queryParams: _action.step ? { step: _action.step } : {}
          });
        })
      ),
    { dispatch: false }
  );

  goToSignUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActionTypes.GoToSignUpSuccess),
        tap((_action: GoToSignUpSuccess) => {
          this.router.navigate([AUTH_UI_PATHS.root.signUpSuccess]);
        })
      ),
    { dispatch: false }
  );

  goToRequestPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActionTypes.GoToRequestPasswordReset),
        tap((_action: GoToRequestPasswordReset) => {
          // tslint:disable-next-line: max-line-length
          this.router.navigate([AUTH_UI_PATHS.root.passwordReset.request]);
        })
      ),
    { dispatch: false }
  );

  goToEditPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActionTypes.GoToEditPassword),
        tap((_action: GoToEditPassword) => {
          this.router.navigate([AUTH_UI_PATHS.root.editPassword]);
        })
      ),
    { dispatch: false }
  );

  goToEditUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActionTypes.GoToEditUser),
        tap((_action: GoToEditUser) => {
          this.router.navigate([AUTH_UI_PATHS.root.editUser]);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, public store: Store<BaseAppState>, private router: Router) {}
}
