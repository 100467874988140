import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../store/reducers';
import { Observable } from 'rxjs';

export class AuthBaseInterceptor implements HttpInterceptor {
  constructor(
    @Inject('apiBaseUrl') protected apiBaseUrl: string,
    protected store: Store<BaseAppState>,
    protected router: Router,
  ) { }

  public intercept(
    // tslint:disable-next-line:no-any
    request: HttpRequest<any>,
    next: HttpHandler,
    // tslint:disable-next-line:no-any
  ): Observable<HttpEvent<any>> {
    return next.handle(request);
  }

  // tslint:disable-next-line: no-any
  protected async needsAuthentication(request: HttpRequest<any>): Promise<boolean> {
    if (request.headers.get('needs-authentication')) {
      return true;
    }

    return false;
  }
}
