<div class="card">
  <div class="image-wrapper">
    <img *ngIf="backgroundImage" class="background" [src]="s3BucketUrl + imagesPath + backgroundImage" />
    <img *ngIf="logo" class="logo hide-desktop" [src]="s3BucketUrl + imagesPath + logo" />
    <div class="mobile-headline hide-desktop">
      <h2 class="mb-0" *ngIf="!logo && headline">{{ headline }}</h2>
    </div>
    <div class="image-pre hide-mobile"></div>
    <div class="row px-md-5 pt-md-5 px-sm-4 pt-sm-4 box-content justify-content-center justify-content-md-start">
      <div class="col-lg-8 col-md-8 col-12" replaceToDownloadLink>
        <h2 *ngIf="headline" [class.hide-mobile]="!logo && headline">{{ headline }}</h2>
        <h6 *ngIf="description" class="font-weight-light mt-4 hide-mobile" [innerHtml]="description"></h6>
        <h4 *ngIf="time" class="text-danger mt-3">
          {{ time }}
        </h4>
      </div>
      <div class="col-lg-6 col-12 d-flex flex-row mb-md-5 mt-lg-5 mt-0 buttons-container hide-mobile">
        <a
          class="btn ghost-button-light rounded-pill mr-2"
          *ngIf="detailButtonUrl"
          [href]="detailButtonUrl"
          [target]="openInNewTab ? '_blank' : ''"
          >{{ detailButtonText }}</a
        >
        <a *ngIf="liveUrl" class="btn btn-danger rounded-pill mr-2" [href]="liveUrl">
          {{ 'ChannelPage.LiveNow' | translate }}
          <i [inlineSVG]="'/assets/icons/live.svg'"></i>
        </a>
      </div>
      <div class="col-12 mt-3 hide-tablet hide-desktop show-mobile">
        <a
          class="btn ghost-button-light rounded-pill mr-2"
          *ngIf="detailButtonUrl && !liveUrl"
          [href]="detailButtonUrl"
          [target]="openInNewTab ? '_blank' : ''"
          >{{ detailButtonText }}</a
        >
        <a *ngIf="liveUrl" class="btn btn-danger rounded-pill mr-2" [href]="liveUrl">
          {{ 'ChannelPage.LiveNow' | translate }}
          <i [inlineSVG]="'/assets/icons/live.svg'"></i>
        </a>
      </div>
      <img *ngIf="logo" class="logo hide-tablet" [src]="s3BucketUrl + imagesPath + logo" />
    </div>
  </div>
</div>
