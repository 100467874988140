import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TypeOfCarouselItem } from '../carousel-small-slide/carousel-small-slide.component';
import { CarouselComponent } from '../carousel/carousel.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-carousel-small',
  templateUrl: './carousel-small.component.html',
  styleUrls: ['../carousel/carousel.component.scss', './carousel-small.component.scss']
})
export class CarouselSmallComponent extends CarouselComponent implements OnInit, OnChanges {
  @Input() coverImage = true;
  @Input() stretchSingleItemWidth = true;
  @Input() labelColor = '';
  @Input() isCongressList = false;
  @Input() isUpcomingEvents = false;
  @Input() showCmePoints = false;
  @Input() showTitleOnThumbnail = false;
  @Input() typeOfItem: TypeOfCarouselItem | null;
  @Input() scaleOnHover = false;
  @Input() carouselDisplayConfig;
  @Input() imageKeyField = 'logo';

  ngOnInit() {
    if (!this.carouselDisplayConfig) {
      this.carouselDisplayConfig = {
        mobile: this.isCongressList ? 2.1 : 1.1,
        tablet: this.isCongressList ? 4 : 2,
        desktop: this.isCongressList ? 6 : 4,
        spacing: this.isCongressList ? 8 : 16
      };
    }
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.initCarousel(
        this.carouselDisplayConfig.mobile,
        this.carouselDisplayConfig.tablet,
        this.carouselDisplayConfig.desktop,
        this.carouselDisplayConfig.spacing
      );
    });
  }

  public isElementInViewPort(i: number) {
    return this.nextSlide$.pipe(
      map(slide => {
        if (window.innerWidth >= 992) {
          return i >= slide && i < this.carouselDisplayConfig.desktop + slide;
        } else {
          return i >= slide && i < this.carouselDisplayConfig.mobile + slide;
        }
      })
    );
  }
}
