import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ROUTER_CANCEL,
  ROUTER_ERROR,
  ROUTER_NAVIGATION,
  RouterNavigationAction,
  BaseRouterStoreState,
} from '@ngrx/router-store';
import { filter, tap } from 'rxjs/operators';

import { BaseAppState } from '../../store/reducers';

@Injectable()
export class RouterEffects {

  private previousRouterNavigationAction: RouterNavigationAction<BaseRouterStoreState>;
  private currentRouterNavigationAction: RouterNavigationAction<BaseRouterStoreState>;


  saveCurrentNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    tap((action: RouterNavigationAction<BaseRouterStoreState>) => {
      this.previousRouterNavigationAction = this.currentRouterNavigationAction;
      this.currentRouterNavigationAction = { ...action };
    }),
  ), { dispatch: false });


  restoreAfterCancellationOrError$ = createEffect(() =>  this.actions$.pipe(
    ofType(ROUTER_CANCEL, ROUTER_ERROR),
    filter((action) => Boolean(action)),
    tap((_action) => {
      if (this.previousRouterNavigationAction) {
        this.store.dispatch(this.previousRouterNavigationAction);
      }
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    public store: Store<BaseAppState>,
  ) {}

}
