import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../store/reducers';
import { from, Observable, throwError } from 'rxjs';
import { catchError, tap, switchMap, take } from 'rxjs/operators';

import { AuthActionTypes, RefreshAuth, SessionExpired } from '../actions/auth.actions';
import { AuthBaseInterceptor } from './auth-base.interceptor';
import { getAuthType } from '../selectors/auth.selectors';
import { AuthorizationMode } from 'medtoday-models-library';
@Injectable()
export class AuthTokenInvalidInterceptor extends AuthBaseInterceptor {
  private refreshTokenInProgress = false;
  authType$ = this.store.select(getAuthType);

  constructor(
    @Inject('apiBaseUrl') apiBaseUrl: string,
    protected store: Store<BaseAppState>,
    router: Router,
    private actions$: Actions
  ) {
    super(apiBaseUrl, store, router);
  }

  public intercept(
    // tslint:disable-next-line:no-any
    request: HttpRequest<any>,
    next: HttpHandler
    // tslint:disable-next-line:no-any
  ): Observable<HttpEvent<any>> {
    return from(this.needsAuthentication(request)).pipe(
      switchMap(needsAuthentication => {
        if (needsAuthentication) {
          return this.handleSessionRenewal(request, next);
        } else {
          return next.handle(request).pipe(catchError(err => this.handleErrorResponse(err)));
        }
      })
    );
  }

  // tslint:disable-next-line: no-any
  private handleSessionRenewal(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;

      this.authType$
        .pipe(
          take(1),
          tap((type: AuthorizationMode) => this.store.dispatch(new RefreshAuth(type)))
        )
        .subscribe();
    }

    return this.actions$.pipe(
      ofType(AuthActionTypes.RefreshAuthSuccess, AuthActionTypes.RefreshAuthFail),
      take(1),
      switchMap(() => {
        this.refreshTokenInProgress = false;
        return next.handle(request).pipe(catchError(err => this.handleErrorResponse(err)));
      })
    );
  }

  // tslint:disable-next-line: no-any
  private handleErrorResponse(error: any): Observable<HttpEvent<any>> {
    console.log(error);
    if (error instanceof HttpErrorResponse && error.status === 401) {
      this.store.dispatch(new SessionExpired());
    } else {
      return throwError(error);
    }
    // tslint:disable-next-line: no-any
    return new Observable<HttpEvent<any>>();
  }
}
