import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoToCongressList } from 'projects/todaylib/core/router/actions/main-navigation.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';

@Component({
  selector: 'app-sign-up-successful-page',
  templateUrl: './sign-up-successful-page.component.html',
  styleUrls: ['./sign-up-successful-page.component.scss']
})
export class SignUpSuccessfulPageComponent {
  constructor(private store: Store<BaseAppState>) {}

  handleGoToCongresses() {
    this.store.dispatch(new GoToCongressList());
  }
}
