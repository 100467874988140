import { Action } from '@ngrx/store';

export enum NavBarActionTypes {
  ToggleNavBar = '[Nav Bar] Toggle Nav Bar',
  ToggleCME = '[Nav Bar] Toggle CME State'
}
export class ToggleNavBar implements Action {
  readonly type = NavBarActionTypes.ToggleNavBar;
  constructor(public toggledNavBar: boolean) {}
}

export class ToggleCME implements Action {
  readonly type = NavBarActionTypes.ToggleCME;
}

export type NavBarActions = ToggleNavBar | ToggleCME;
