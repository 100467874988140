import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PatientsTodayStoreState } from '../reducers/patientstoday-store.reducer';
import {
  TopicCategory,
  LandingPageVideoListItem,
  TopicCategoriesRelatedResourcesResponse,
  CongressListItem,
  Tag
} from 'medtoday-models-library';
import {
  extendToHeroSliderItem,
  extendToHeroSliderItemTag,
  fromCongress,
  fromEvent,
  fromTopicCategory,
  HeroSliderSideItem
} from '../../../../../todaylib/shared/components/hero-slider-sidely/hero-slider-mapper';
import { getCongressesList } from '../../../../../todaylib/core/data/selectors/data-api.selectors';
import { Sponsor } from 'medtoday-models-library';

export const getPatientsTodayStoreRootState = createFeatureSelector<PatientsTodayStoreState>('patientstoday-store');

export const getTopicCategories: MemoizedSelector<object, TopicCategory[]> = createSelector(
  getPatientsTodayStoreRootState,
  (state: PatientsTodayStoreState) => state?.topicCategories!
);

export const getMenuTopicCategories: MemoizedSelector<object, TopicCategory[]> = createSelector(
  getPatientsTodayStoreRootState,
  getTopicCategories,
  (_, topicCategories) =>
    topicCategories?.filter(topicCategory => !topicCategory.hideInMenu).sort((a, b) => a.title.localeCompare(b.title))
);

export const getChunkedMenuTopicCategories: MemoizedSelector<object, [TopicCategory[]]> = createSelector(
  getMenuTopicCategories,
  topicCategories => {
    if (!topicCategories?.length) {
      return [];
    }

    // counting arguments
    let topicCategoriesSource = [
      ...topicCategories
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(tC => {
          return {
            ...tC,
            topics: [...tC.topics!].sort((a, b) => a.title.localeCompare(b.title))
          };
        })
    ];
    const itemCount = topicCategoriesSource.reduce((agg, topicCategory) => {
      agg += (topicCategory.topics?.length || 0) + 1;
      return agg;
    }, 0);
    const perColumn = Math.ceil(itemCount / 3) + 2;

    // divide by 3 columns, avoid empty last column
    let columns: any = [[], [], []];

    if (topicCategoriesSource.length <= 3) {
      columns.forEach(column => column.push(topicCategoriesSource.shift()));
    } else {
      for (let colIndex = 0; colIndex < 3; colIndex++) {
        let i = 0;
        topicCategoriesSource = topicCategoriesSource.filter(topicCategory => {
          const entriesLength = (topicCategory.topics?.length || 0) + 1;
          if (i + entriesLength >= perColumn && i >= 4) {
            return true;
          }

          //  avoid empty last column
          if (colIndex === 1 && columns[1].length) {
            if (topicCategoriesSource.length === 1) {
              return true;
            }
          }

          columns[colIndex].push(topicCategory);
          i += entriesLength;
          return false;
        });
      }
    }
    return columns;
  }
);

export const getOnDemandVideosForTopicCategory: MemoizedSelector<object, LandingPageVideoListItem[]> = createSelector(
  getPatientsTodayStoreRootState,
  (state: PatientsTodayStoreState) => state?.topicCategoryRelated.onDemandVideos?.results!
);

export const getResearchContributionsForTopicCategory: MemoizedSelector<object, LandingPageVideoListItem[]> =
  createSelector(getPatientsTodayStoreRootState, (state: PatientsTodayStoreState) => [
    ...(state.topicCategoryRelated.researchContributions.researchPhase3.results ?? []),
    ...(state.topicCategoryRelated.researchContributions.researchGeneralKnowledge.results ?? [])
  ]);

export const getResearchContributionsSponsors: MemoizedSelector<object, Sponsor[]> = createSelector(
  getPatientsTodayStoreRootState,
  (state: PatientsTodayStoreState) =>
    state.topicCategoryRelated.researchContributions.researchGeneralKnowledge.sponsors ?? []
);

export const getResearchPhase3Contributions: MemoizedSelector<object, LandingPageVideoListItem[]> = createSelector(
  getPatientsTodayStoreRootState,
  (state: PatientsTodayStoreState) => state.topicCategoryRelated.researchContributions.researchPhase3.results ?? []
);

export const getResearchGeneralKnowledgeContributions: MemoizedSelector<object, LandingPageVideoListItem[]> =
  createSelector(
    getPatientsTodayStoreRootState,
    (state: PatientsTodayStoreState) =>
      state.topicCategoryRelated.researchContributions.researchGeneralKnowledge.results ?? []
  );

export const getOnDemandVideosForTopicCategoryNextPage: MemoizedSelector<object, number> = createSelector(
  getPatientsTodayStoreRootState,
  (state: PatientsTodayStoreState) => state?.topicCategoryRelated.onDemandVideos?.nextPage!
);

export const getResearchContributionsNextPage: MemoizedSelector<
  object,
  { researchPhase3: number; researchGeneralKnowledge: number }
> = createSelector(getPatientsTodayStoreRootState, (state: PatientsTodayStoreState) => ({
  researchPhase3: state.topicCategoryRelated.researchContributions.researchPhase3.nextPage ?? 0,
  researchGeneralKnowledge: state.topicCategoryRelated.researchContributions.researchGeneralKnowledge.nextPage ?? 0
}));

export const getResearchPhase3ContributionsNextPage: MemoizedSelector<object, number> = createSelector(
  getPatientsTodayStoreRootState,
  (state: PatientsTodayStoreState) => state.topicCategoryRelated.researchContributions.researchPhase3.nextPage ?? 0
);

export const getResearchGeneralKnowledgeContributionsNextPage: MemoizedSelector<object, number> = createSelector(
  getPatientsTodayStoreRootState,
  (state: PatientsTodayStoreState) =>
    state.topicCategoryRelated.researchContributions.researchGeneralKnowledge.nextPage ?? 0
);

export const getTopicCategoryRelatedResource: MemoizedSelector<object, TopicCategoriesRelatedResourcesResponse> =
  createSelector(getPatientsTodayStoreRootState, (state: PatientsTodayStoreState) => state?.topicCategoryRelated.data!);

export const getHeroSliderItems: MemoizedSelector<object, HeroSliderSideItem[] | undefined> = createSelector(
  getPatientsTodayStoreRootState,
  getCongressesList,
  (state: PatientsTodayStoreState, congresses: CongressListItem[]) => {
    const topicCategories = state.topicCategories;
    if (congresses?.length && topicCategories !== undefined) {
      return congresses.map(congress =>
        extendToHeroSliderItem(
          congress,
          congress.congressMode === 'MedEd' ? fromEvent : fromCongress,
          topicCategories
            .filter(topicCategory => congress.topicCategoriesIds!.includes(topicCategory.id))
            .map(topicCategory => extendToHeroSliderItemTag(topicCategory, fromTopicCategory))
        )
      );
    } else {
      return undefined;
    }
  }
);

export const getTopicCategoryTagsFilters: MemoizedSelector<object, Tag[]> = createSelector(
  getPatientsTodayStoreRootState,
  (state: PatientsTodayStoreState) => state?.tags
);
