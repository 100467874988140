<div class="form-group" *ngIf="isLoggedIn$ | async">
  <div>
    <label>
      <span *ngIf="(efnNumber$ | async) !== undefined">{{ 'CmeInfo.EfnPresentLabel' | translate }}</span>
      <span *ngIf="(efnNumber$ | async) === undefined">{{ 'CmeInfo.EfnLabel' | translate }}</span>
    </label>
    <input
      type="text"
      class="form-control form-control-lg"
      [formControl]="efnNumberControl"
      [readonly]="!!(efnNumber$ | async)"
    />
    <button
      class="btn btn-outline-danger rounded mt-3 w-100"
      *ngIf="(efnNumber$ | async) === undefined"
      (click)="handleUpdateEfnNumber()"
      [disabled]="!efnNumberControl.value"
    >
      {{ 'CmeInfo.EfnProfileBtn' | translate }}
    </button>
  </div>
</div>
