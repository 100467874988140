<header class="container">
  <app-hero-slider [congresses]="congresses"></app-hero-slider>
</header>

<!-- upcomming events -->
<ng-container *ngIf="currentLang !== 'en'">
  <ng-container *ngIf="upcomingEventsPageData$ | async as upcomingEventsPageData">
    <section id="upcomingEvents" *ngIf="upcomingEventsPageData.events.length">
      <div class="container">
        <div class="row mb-lg-4 mb-2">
          <div class="col-12 d-flex align-items-md-baseline align-items-end">
            <h4 class="mr-md-4 mr-2 mb-0 hide-mobile">{{ 'UpcomingEvents.LiveAndUpcoming' | translate }}</h4>
            <a (click)="handleGoToUpcomingEventsPageClick()" class="d-flex anchor" draggable="false">
              <h4 class="mr-md-4 mr-2 mb-0 d-md-none">{{ 'UpcomingEvents.LiveAndUpcoming' | translate }}</h4>
              <p class="mr-2 mb-0 hide-mobile">{{ 'CongressList.ShowAll' | translate }}</p>
              <i [inlineSVG]="'assets/icons/anchor-right.svg'"></i>
            </a>
          </div>
        </div>
      </div>
      <app-carousel-small
        [carouselItems]="upcomingEventsPageData.events"
        [coverImage]="false"
        [isUpcomingEvents]="true"
      ></app-carousel-small>
    </section>
  </ng-container>
</ng-container>

<section id="past-congresses" *ngIf="congressSlider?.show">
  <div class="container">
    <div class="row mb-lg-4 mb-2">
      <div class="col-12">
        <h4 class="mb-0">{{ 'CongressList.OurCongresses' | translate }}</h4>
      </div>
    </div>
  </div>
  <app-carousel-small
    [stretchSingleItemWidth]="true"
    [isCongressList]="true"
    [carouselItems]="displayedCongressCarouselItems"
    [coverImage]="false"
    *ngIf="displayedCongressCarouselItems.length > 0"
  ></app-carousel-small>
</section>

<section *ngIf="(isLoggedIn$ | async) === false" class="container">
  <div class="row mobile-full-width">
    <div class="col-12">
      <div class="sign-up card shadow-lg min-h50">
        <img class="register-image" src="assets/images/Landingpage-Teaser-Kugeln@2x.png" />
        <div class="card-body">
          <div class="row m-0 p-sm-4 p-md-3 p-lg-5">
            <div class="col-xl-7 col-lg-6 col-12">
              <h4 class="mb-1 card-headline">{{ 'CongressList.JoinNow' | translate }}</h4>
              <h4 class="mb-3">{{ 'CongressList.ShapeTomorrow.' | translate }}</h4>
              <p class="card-text">{{ 'CongressList.SignUpDesc' | translate }}</p>
            </div>
            <div class="col-xl-5 col-lg-6 col-12">
              <h4 class="mb-3 p-lg-3 hide-mobile">{{ 'CongressList.NoAccount' | translate }}</h4>
              <form [formGroup]="signUpForm" name="sign-up" #ngForm="ngForm" novalidate>
                <div class="form-group row m-0">
                  <div class="col-12 hide-mobile">
                    <label>{{ 'CongressList.Email' | translate }}</label>
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="{{ 'SignUp.EnterEmailAddress' | translate }}"
                      formControlName="email"
                    />
                  </div>
                  <div class="col-12 mt-2 mb-2 hide-mobile">
                    <label>{{ 'CongressList.Passwort' | translate }}</label>
                    <input
                      class="form-control form-control-lg"
                      type="password"
                      placeholder="{{ 'LogIn.EnterPassword' | translate }}"
                      formControlName="password"
                      autocomplete="new-password"
                    />
                  </div>
                  <div class="col-12 mt-4">
                    <button class="btn btn-danger rounded-pill w-100" (click)="handleGoToSignUpClick()">
                      {{ 'Auth.Register' | translate }}
                    </button>
                  </div>
                  <div class="col-12 mt-3">
                    <p>
                      {{ 'CongressList.AlreadyRegistered' | translate }}
                      <a class="text-danger link" (click)="handleGoToLoginClick()"> {{ 'Auth.SignIn' | translate }} </a
                      >.
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-spacer></app-spacer>
</section>

<!-- Our highlights -->
<section *ngIf="upcomingEvents?.length">
  <div class="container">
    <div class="row mb-lg-4 mb-2">
      <div class="col-12 d-flex align-items-md-baseline align-items-end">
        <h4 class="mb-0 mr-md-4 mr-2 hide-mobile">{{ 'Common.MostRecent' | translate }}</h4>
        <a (click)="handleGoToMedicalEducationClick()" class="d-flex anchor" draggable="false">
          <h4 class="mb-0 mr-md-4 mr-2 d-md-none">{{ 'Common.MostRecent' | translate }}</h4>
          <p class="mr-2 mb-0 hide-mobile">{{ 'Navigation.GoToMedicalEducation' | translate }}</p>
          <i [inlineSVG]="'assets/icons/anchor-right.svg'"></i>
        </a>
      </div>
    </div>
  </div>
  <app-carousel-large
    [carouselItems]="upcomingEvents"
    [folderUrl]="thumbnailPathUrl"
    [halfWidthItem]="true"
  ></app-carousel-large>
  <app-spacer></app-spacer>
</section>

<!-- Medboards -->
<ng-container *ngIf="isLoggedIn$ | async">
  <app-medboards></app-medboards>
</ng-container>

<!-- Premium Teaser Box -->
<div class="container" *ngIf="premiumBox">
  <div class="row">
    <div class="col-12">
      <app-premium-teaser-box
        [headline]="premiumBox?.headline"
        [description]="premiumBox?.description"
        [backgroundImage]="premiumBox?.backgroundImage"
        [logo]="premiumBox?.logo"
        [time]="premiumBox?.date"
        [start]="premiumBox?.timeslotStart"
        [end]="premiumBox?.timeslotEnd"
        [openInNewTab]="premiumBox?.detailsButtonOpenNewTab"
        [detailButtonUrl]="premiumBox?.detailsButtonUrl"
        [detailButtonText]="premiumBox?.detailsButtonText"
        [liveUrl]="premiumBox?.goLiveButtonUrl"
        [postUrl]="premiumBox?.detailsButtonUrl"
      ></app-premium-teaser-box>
    </div>
  </div>
  <app-spacer></app-spacer>
</div>

<!-- Most Watched -->
<section id="most-watched-videos" *ngIf="mostWatchedVideosCarouselItems.length > 0">
  <div class="container">
    <div class="row mb-lg-4 mb-2">
      <div class="col-12">
        <h4 class="mb-0" [attr.data-number]="mostWatchedVideosCarouselItems.length">
          {{ 'CongressList.MostWatchedTitle' | translate }}
        </h4>
      </div>
    </div>
  </div>
  <app-carousel-small
    [carouselItems]="mostWatchedVideosCarouselItems"
    [imageKeyField]="'thumbnail'"
    [coverImage]="true"
  ></app-carousel-small>
</section>

<!-- inernational content -->
<ng-container *ngIf="congressListInertnationalContent$ | async as congressListInertnationalContent">
  <section id="internationalContent" *ngIf="congressListInertnationalContent.length > 0">
    <div class="container">
      <div class="row mb-lg-4 mb-2">
        <div class="col-12">
          <h4 class="mb-0">{{ 'Common.InternationalContent' | translate }}</h4>
        </div>
      </div>
    </div>
    <app-carousel-large
      [carouselItems]="congressListInertnationalContent"
      [folderUrl]="thumbnailPathUrl"
      [halfWidthItem]="true"
    ></app-carousel-large>
    <app-spacer></app-spacer>
  </section>
</ng-container>

<!-- Twitter -->
<ng-container *ngIf="currentLang !== 'ch'">
  <app-mt-social-wall></app-mt-social-wall>
  <app-spacer></app-spacer>
</ng-container>

<app-scroll-to-top-button [ngClass]="hasScrolled() ? 'd-block' : 'd-none'"></app-scroll-to-top-button>
<app-async-state-overlay [processKey]="loadOnDemandVideoAsyncKey"></app-async-state-overlay>
<!-- Uncomment if no CORS error -->
<!-- <app-async-state-overlay [processKey]="medBoardsDataAsyncKey"></app-async-state-overlay> -->
