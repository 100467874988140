import { Pipe, PipeTransform } from '@angular/core';
import { ExpertIdentity } from 'medtoday-models-library/lib/models/responses/expert/expert-identity';

@Pipe({
    name: 'expert',
})
export class ExpertPipe implements PipeTransform {
    transform(expert: ExpertIdentity): string {
        return [expert.title, expert.firstName, expert.lastName]
            .join(' ');
    }
}
