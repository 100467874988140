import { AsyncAction } from '../../async-state/models/async-action.model';
import { Action } from '@ngrx/store';

export enum ProvidersActionTypes {
  LoadYoutubeVideoData = '[Providers] Load Youtube Video data',
  LoadYoutubeVideoDataSuccess = '[Providers] Load Youtube Video data success',
  LoadYoutubeVideoDataFail = '[Providers] Load Youtube Video data fail',
  ClearYoutubeVideoData = '[Providers] Clear Youtube Video data'
}

export class LoadYoutubeVideoData implements AsyncAction {
  readonly asyncKey = ProvidersActionTypes.LoadYoutubeVideoData;
  readonly type = ProvidersActionTypes.LoadYoutubeVideoData;
  readonly asyncState = 'start';
  constructor(public id: string) {}
}

export class LoadYoutubeVideoDataSuccess implements AsyncAction {
  readonly asyncKey = ProvidersActionTypes.LoadYoutubeVideoData;
  readonly type = ProvidersActionTypes.LoadYoutubeVideoDataSuccess;
  readonly asyncState = 'success';
  constructor(public response: any) {}
}

export class LoadYoutubeVideoDataFail implements AsyncAction {
  readonly asyncKey = ProvidersActionTypes.LoadYoutubeVideoData;
  readonly type = ProvidersActionTypes.LoadYoutubeVideoDataFail;
  readonly asyncState = 'fail';
  constructor() {}
}

export class ClearYoutubeVideoData implements Action {
  readonly type = ProvidersActionTypes.ClearYoutubeVideoData;
}

export type ProvidersActions = LoadYoutubeVideoDataSuccess | ClearYoutubeVideoData;
