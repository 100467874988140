import { AsyncAction } from '../../async-state/models/async-action.model';
import { SignUpRequest } from '../../../shared/models';

export enum SignUpActionTypes {
  SignUp = '[SignUp] SignUp',
  SignUpSuccess = '[SignUp API] SignUp Success',
  SignUpFailure = '[SignUp API] SignUp Failure',
  SignUpConfirmation = '[SignUp] SignUp Check Confirmation',
  SignUpConfirmationSuccess = '[SignUp API] SignUp Confirmation Success',
  SignUpConfirmationFailure = '[SignUp API] SignUp Confirmation Failure',
  ResendSignUp = '[SignUp] Resend SignUp',
  ResendSignUpSuccess = '[SignUp] Resend SignUp Success',
  ResendSignUpFailure = '[SignUp] Resend SignUp Failure'
}

export class SignUp implements AsyncAction {
  readonly type = SignUpActionTypes.SignUp;
  readonly asyncKey = SignUpActionTypes.SignUp;
  readonly asyncState = 'start';

  constructor(public payload: SignUpRequest) {}
}

export class SignUpSuccess implements AsyncAction {
  readonly type = SignUpActionTypes.SignUpSuccess;
  readonly asyncKey = SignUpActionTypes.SignUp;
  readonly asyncState = 'success';

  constructor(public request: SignUpRequest) {}
}

export class SignUpFailure implements AsyncAction {
  readonly type = SignUpActionTypes.SignUpFailure;
  readonly asyncKey = SignUpActionTypes.SignUp;
  readonly asyncState = 'fail';
}

export class SignUpConfirmation implements AsyncAction {
  readonly type = SignUpActionTypes.SignUpConfirmation;
  readonly asyncKey = SignUpActionTypes.SignUpConfirmation;
  readonly asyncState = 'start';

  constructor(public userId: string, public code: string) {}
}

export class SignUpConfirmationSuccess implements AsyncAction {
  readonly type = SignUpActionTypes.SignUpConfirmationSuccess;
  readonly asyncKey = SignUpActionTypes.SignUpConfirmation;
  readonly asyncState = 'success';
}

export class SignUpConfirmationFailure implements AsyncAction {
  readonly type = SignUpActionTypes.SignUpConfirmationFailure;
  readonly asyncKey = SignUpActionTypes.SignUpConfirmation;
  readonly asyncState = 'fail';
}
export class ResendSignUp implements AsyncAction {
  readonly type = SignUpActionTypes.ResendSignUp;
  readonly asyncKey = SignUpActionTypes.ResendSignUp;
  readonly asyncState = 'start';

  constructor(public username: string) {}
}

export class ResendSignUpSuccess implements AsyncAction {
  readonly type = SignUpActionTypes.ResendSignUpSuccess;
  readonly asyncKey = SignUpActionTypes.ResendSignUp;
  readonly asyncState = 'success';
}

export class ResendSignUpFailure implements AsyncAction {
  readonly type = SignUpActionTypes.ResendSignUpFailure;
  readonly asyncKey = SignUpActionTypes.ResendSignUp;
  readonly asyncState = 'fail';
}

export type SignUpActions =
  | SignUp
  | SignUpSuccess
  | SignUpFailure
  | SignUpConfirmation
  | SignUpConfirmationSuccess
  | SignUpConfirmationFailure
  | ResendSignUp
  | ResendSignUpSuccess
  | ResendSignUpFailure;
