import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'app-image-box',
  templateUrl: './image-box.component.html',
  styleUrls: ['./image-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageBoxComponent {
  @Input() src: string;
  @Input() link: string;
  @Input() textBelow: string | null;
  @Input() imageSize: ImageSizeValues | null;

  constructor(@Inject('applicationName') public applicationName: string) {}
}

export type ImageSizeValues = 'cover' | 'contain';
