<ng-container *ngIf="isCongressList && !isUpcomingEvents">
  <a
    *ngIf="carouselItem.isExternalUrl && !!carouselItem.url"
    [href]="carouselItem.url"
    target="_blank"
    draggable="false"
  >
    >
    <ng-template
      [ngTemplateOutlet]="congressListInner"
      [ngOutletContext]="{ carouselItem: carouselItem }"
    ></ng-template>
  </a>

  <a
    *ngIf="!carouselItem.url"
    (click)="handleCongressClick(carouselItem.slug, carouselItem.congressMode)"
    draggable="false"
  >
    <ng-template
      [ngTemplateOutlet]="congressListInner"
      [ngTemplateOutletContext]="{ carouselItem: carouselItem }"
    ></ng-template>
  </a>

  <a *ngIf="carouselItem.url" draggable="false" [href]="carouselItem.url" target="_blank" rel="noreferrer">
    <ng-template
      [ngTemplateOutlet]="congressListInner"
      [ngTemplateOutletContext]="{ carouselItem: carouselItem }"
    ></ng-template>
  </a>
</ng-container>

<ng-container *ngIf="!isCongressList && !isUpcomingEvents">
  <a (click)="handleItemClick(carouselItem)" target="_blank" draggable="false">
    <app-carousel-label
      *ngIf="carouselItem.label"
      [label]="carouselItem.label"
      [labelColor]="labelColor ? labelColor : carouselItem.labelColor"
    ></app-carousel-label>
    <div
      class="image-wrapper video-list-img"
      [class.patients-border]="applicationName === 'PatientsToday'"
      [class.patients-bg]="applicationName === 'PatientsToday'"
    >
      <img
        draggable="false"
        class="w-100"
        *ngIf="carouselItem.thumbnail"
        [src]="s3BucketUrl + folderUrl + carouselItem.thumbnail"
      />
      <ng-container *ngIf="!carouselItem.thumbnail">
        <ng-container
          *ngIf="
            !carouselItem.additionalData?.mediaProvider ||
            carouselItem.additionalData.mediaProvider !== MediaProvider.YT
          "
        >
          <img
            draggable="false"
            class="w-100 cover-image"
            [src]="'https://cdn.jwplayer.com/v2/media/' + carouselItem.additionalData.mediaIdentifier + '/poster.jpg'"
          />
        </ng-container>
        <ng-container *ngIf="carouselItem.additionalData?.mediaProvider === MediaProvider.YT">
          <img
            draggable="false"
            class="w-100 cover-image"
            [src]="'https://i.ytimg.com/vi/' + carouselItem.additionalData.mediaIdentifier + '/mqdefault.jpg'"
          />
        </ng-container>
      </ng-container>
      <label *ngIf="carouselItem.viewsCount" class="label-end">
        <div class="icon-16 mr-2" [inlineSVG]="'assets/icons/form-show.svg'"></div>
        {{ carouselItem.viewsCount }}
      </label>
    </div>
    <h6 *ngIf="carouselItem.title" [innerHTML]="carouselItem?.title"></h6>
    <div class="d-flex flex-row" *ngIf="carouselItem.topic || carouselItem.format">
      <div
        class="badge p-2 mr-1 my-1 hide-mobile"
        [ngClass]="applicationName === 'PatientsToday' ? 'badge-light' : 'badge-dark'"
        *ngIf="carouselItem.topic"
      >
        {{ carouselItem.topic }}
      </div>
      <div
        class="badge p-2 mr-1 my-1 hide-mobile"
        [ngClass]="applicationName === 'PatientsToday' ? 'badge-light' : 'badge-dark'"
        *ngIf="carouselItem.format"
      >
        {{ carouselItem.format }}
      </div>
    </div>
    <p *ngIf="carouselItem.subTitle">
      <small [class.text-dark]="applicationName === 'PatientsToday'">{{ carouselItem.subTitle }}</small>
    </p>
  </a>
</ng-container>

<ng-container *ngIf="isUpcomingEvents && !isCongressList">
  <a
    (click)="handleUpcomingLink($event)"
    [attr.data-toggle]="!carouselItem.congressLinkActive && carouselItem.upcomingPopupHeadline ? 'modal' : ''"
    [attr.href]="!carouselItem.congressLinkActive ? '#eventInfoModal' : ''"
    [class.link-disabled]="!carouselItem.congressLinkActive"
  >
    <div class="image-wrapper event-thumbnail" [class.text-light]="applicationName === 'PatientsToday'">
      <app-carousel-label *ngIf="sessionIsLive">
        <app-live-indicator *ngIf="liveSession" [timeSlotRow]="liveSession"></app-live-indicator>
      </app-carousel-label>
      <span *ngIf="!showTitleOnThumbnail">{{ getEventDates(carouselItem) }}<br />{{ carouselItem.city }}</span>
      <span class="text-wrap" *ngIf="showTitleOnThumbnail">{{ carouselItem.title }}</span>
      <img *ngIf="carouselItem.thumbnail" [src]="carouselItem.thumbnail" />
    </div>
  </a>
  <h6 *ngIf="carouselItem.title && !showTitleOnThumbnail" [innerHTML]="carouselItem?.title"></h6>
  <div
    class="badge p-2 mr-1 mb-1"
    [class.mt-1]="!showTitleOnThumbnail"
    [ngClass]="applicationName === 'PatientsToday' ? 'badge-light' : 'badge-dark'"
    [appTopicCategoryColor]
    [topicCategoryName]="carouselItem.topicCategory[0]"
    *ngIf="carouselItem.topicCategory[0]"
  >
    {{ carouselItem.topicCategory[0] }}
  </div>
  <p class="font-size-small" *ngIf="carouselItem.cmePoints">{{ carouselItem.cmePoints }} Live CME Punkte</p>
</ng-container>

<ng-template #congressListInner let-carouselItem="carouselItem">
  <app-carousel-label
    *ngIf="carouselItem?.label"
    [label]="carouselItem.label"
    [labelColor]="labelColor ? labelColor : carouselItem.labelColor"
  ></app-carousel-label>
  <div
    id="congress-list-{{ index }}"
    (mouseover)="handleCongressHover(index, carouselItem.colors?.primary, true)"
    (mouseout)="handleCongressHover(index, false)"
    class="image-wrapper congress-list-img"
    [class.patients-border]="applicationName === 'PatientsToday'"
    [class.patients-bg]="applicationName === 'PatientsToday'"
    [class.has-padding]="applicationName !== 'PatientsToday'"
    [class.hover-scale]="scaleOnHover"
  >
    <img
      *ngIf="carouselItem[imageKeyField]"
      [class.cover-image]="coverImage"
      draggable="false"
      [src]="s3BucketUrl + folderUrl + carouselItem[imageKeyField]"
      alt="thumbnail"
    />
    <h5 class="text-center mb-0" *ngIf="!carouselItem?.logo">{{ carouselItem?.title }}</h5>
  </div>
  <p class="congress-list-subtitle" *ngIf="carouselItem?.title">
    <small [class.text-dark]="applicationName === 'PatientsToday'" [innerHTML]="carouselItem?.title"></small>
  </p>
</ng-template>
