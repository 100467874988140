import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ObservableComponent } from '../../../../../todaylib/shared/components/observable/observable.component';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../../todaylib/core/store/reducers';
import { CloseCmeTrackingPromptModal } from '../../medtoday-store/actions/medtoday-store.actions';
import { getCMEActiveState, getUserHasEfnNumber } from '../../../../../todaylib/core/auth/selectors/auth.selectors';
import { FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ToggleCME } from '../../../../../todaylib/core/layout/nav-bar/actions/nav-bar.actions';

declare var $;
declare const jwplayer;

@Component({
  selector: 'app-cme-tracking-prompt-modal',
  templateUrl: './cme-tracking-prompt-modal.component.html',
  styleUrls: ['./cme-tracking-prompt-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmeTrackingPromptModalComponent extends ObservableComponent implements OnInit, OnDestroy, AfterViewInit {
  cmeActiveState$ = this.store.select(getCMEActiveState);
  cmeActiveStateControl = new FormControl<boolean>(false);
  hasUserEfnNumber$ = this.store.select(getUserHasEfnNumber);

  constructor(private store: Store<BaseAppState>) {
    super();
  }

  ngOnInit() {
    $('#cmeTrackingPrompt').modal('show');
    this.cmeActiveState$.pipe(take(1)).subscribe(cmeActiveState => this.cmeActiveStateControl.setValue(cmeActiveState));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (jwplayer) {
        jwplayer().stop();
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    $('#cmeTrackingPrompt').modal('hide');
  }

  handleCloseModal() {
    this.store.dispatch(new CloseCmeTrackingPromptModal());
  }

  handleUpdateCmeClick() {
    this.cmeActiveState$.pipe(take(1)).subscribe(cmeActiveState => {
      if (cmeActiveState !== this.cmeActiveStateControl.value) {
        this.store.dispatch(new ToggleCME());
      }

      this.handleCloseModal();
    });
  }
}
