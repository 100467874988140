import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../store/reducers';
import { NavBarService } from '../../services/nav-bar.service';
import { getCongressTopics, getSpecialEventCongresses } from '../../../../data/selectors/data-api.selectors';
import { filter, take, tap } from 'rxjs';
import { CongressesTopicModel } from 'medtoday-models-library';
@Component({
  selector: 'app-congress-dropdown-list-mobile',
  templateUrl: './congress-dropdown-list-mobile.component.html',
  styleUrls: ['./congress-dropdown-list-mobile.component.scss'],
  providers: [NavBarService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CongressDropdownListMobileComponent {
  @Input() hideDesktop: boolean;
  @Input() showTopicIndicator: any;
  @Output() congressSelected = new EventEmitter();

  specialEvents$ = this.store.select(getSpecialEventCongresses);
  congressTopics$ = this.store.select(getCongressTopics);

  constructor(
    protected store: Store<BaseAppState>,
    public navBarService: NavBarService,
    @Inject('domain') public domain: string
  ) {}

  public handleTopicCategoryClick(congressTopic?: CongressesTopicModel) {
    if (congressTopic) {
      this.navBarService.showTopicChildren(true, congressTopic?.congresses, congressTopic?.topicCategoryName);
    } else {
      this.specialEvents$
        .pipe(
          filter(events => Boolean(events)),
          take(1),
          tap(events => {
            this.navBarService.showTopicChildren(true, events, 'Special Events');
          })
        )
        .subscribe();
    }
  }
}
