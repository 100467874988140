import { SearchActions, SearchActionTypes } from '../actions/search.actions';
import { CrossPlatformSearchVideoListItem } from 'medtoday-models-library/src/models/responses/on-demand-video/cross-platform-search-video-list-item.model';

export interface SearchDataState {
  results: CrossPlatformSearchVideoListItem[] | undefined;
  nextPage: number | null;
}

const initialState: SearchDataState = {
  results: undefined,
  nextPage: 1
};

export function reducer(state = initialState, action: SearchActions): SearchDataState {
  switch (action.type) {
    case SearchActionTypes.LoadSearchResultsSuccess: {
      return {
        ...state,
        results: action.append ? [...state.results!, ...action.response.results] : action.response.results,
        nextPage: action.response.nextPage
      };
    }
    case SearchActionTypes.PurgeData: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
