import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { AUTH_STATE_KEY } from '../definitions/auth.definitions';
import { AuthState, AuthStatus } from '../reducers/auth.reducer';
import { decodeToken, getEmailFromToken } from '../utilities/auth-utilities';

export const getAuthState = createFeatureSelector<AuthState>(AUTH_STATE_KEY);

export const getAuthStatus = createSelector(getAuthState, (state: AuthState) => state.status);

export const isLoggedIn = createSelector(getAuthStatus, (state: AuthStatus) => Boolean(state.token));

export const getCMEActiveState: MemoizedSelector<object, boolean> = createSelector(
  getAuthState,
  (state: AuthState) => state.settings && state.settings.cmeActive
);

export const getToken = createSelector(getAuthStatus, (state: AuthStatus) => state.token);

export const getAuthType = createSelector(getAuthStatus, (state: AuthStatus) => state.type);

export const getCongressToken = createSelector(getAuthStatus, (state: AuthStatus) => state.congressToken?.token);

export const getDecodedToken = createSelector(getToken, (token: string) => (token ? decodeToken(token) : null));

export const getEmailFromAuthToken = createSelector(getDecodedToken, (token: string) => getEmailFromToken(token));

export const getRedirectUrl = createSelector(getAuthState, (state: AuthState) => state.redirectUrl);

export const getUserAttributes = createSelector(getAuthState, (state: AuthState) => state.loggedInUser.userAttributes);

export const getUserProfile = createSelector(getAuthState, (state: AuthState) => state.userProfile);

export const getUserHasEfnNumber = createSelector(getAuthState, (state: AuthState) =>
  Boolean(
    state &&
      state.loggedInUser?.userAttributes &&
      state?.loggedInUser?.userAttributes['custom:efnNumber'] &&
      state?.loggedInUser?.userAttributes['custom:efnNumber'] !== ''
  )
);

export const getCurrentAWSMember = createSelector(
  getAuthState,
  (state: AuthState) => Boolean(state.status.token) && state.loggedInUser.currentMember
);

export const getTemporarySignUpData = createSelector(getAuthState, (state: AuthState) => state.temporarySignUpData);

export const getTemporaryEditUserData = createSelector(getAuthState, (state: AuthState) => state.temporaryEditUserData);

export const getShouldScrollToNewsletter = createSelector(
  getAuthState,
  (state: AuthState) => state.temporaryEditUserData?.scrollToNewsletter
);

export const getShowMissingUserDataModal = createSelector(
  getAuthState,
  (state: AuthState) => state.showMissingUserDataModal
);
