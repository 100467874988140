import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-carousel-label',
  templateUrl: './carousel-label.component.html',
  styleUrls: ['./carousel-label.component.scss'],
})
export class CarouselLabelComponent {

  @Input() label = '';
  @Input() labelColor = '';

}
