<ng-container *ngIf="mobileView">
  <a
    data-toggle="collapse"
    data-target=".navbar-collapse.show"
    aria-haspopup="true"
    aria-expanded="false"
    draggable="false"
    class="py-1 w-100 text-nowrap text-light"
    (click)="handleCongressClick()"
  >
    <ng-container *ngIf="congressForTopic.congressShortcut && domain !== 'ch'"
      >{{ congressForTopic.congressShortcut }}:
    </ng-container>
    {{ congressForTopic?.congressName }}
  </a>
</ng-container>
<ng-container *ngIf="!mobileView">
  <a
    data-toggle="collapse"
    data-target="#congressDropdown"
    aria-haspopup="true"
    aria-expanded="false"
    draggable="false"
    class="py-1 w-100 hide-tablet"
    (click)="handleCongressClick()"
  >
    <ng-container *ngIf="congressForTopic.congressShortcut && domain !== 'ch'"
      >{{ congressForTopic.congressShortcut }}:
    </ng-container>
    {{ congressForTopic.congressName }}
  </a>
</ng-container>
