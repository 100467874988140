import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoToCongressLandingPage } from 'projects/todaylib/core/router/actions/main-navigation.actions';
import { ObservableComponent } from 'projects/todaylib/shared/components/observable/observable.component';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { BaseAppState } from '../../../store/reducers';
import { ToggleNavBar } from '../actions/nav-bar.actions';
import { getNavBarToggled } from '../selectors/nav-bar.selectors';

@Injectable()
export class NavBarService extends ObservableComponent {
  public navBarOpenedFromMobile = false;
  public topicCategoryChildren;
  public selectedTopic = '';

  public navBarToggled$ = this.store.select(getNavBarToggled);

  constructor(protected store: Store<BaseAppState>) {
    super();
  }

  private getToggleState(): void {
    this.navBarToggled$
      .pipe(
        takeUntil(this.ngDestroy$),
        filter(toggled => !!toggled),
        tap(toggled => (this.navBarOpenedFromMobile = toggled))
      )
      .subscribe();
  }

  handleToggleNavBar() {
    this.getToggleState();
    const main = document.querySelector('main');
    const footer = document.querySelector('app-footer');

    if (!this.navBarOpenedFromMobile) {
      main?.setAttribute('style', 'display: none');
      footer?.setAttribute('style', 'display: none');
      this.navBarOpenedFromMobile = true;
      this.store.dispatch(new ToggleNavBar(true));
    } else {
      main?.removeAttribute('style');
      footer?.removeAttribute('style');
      this.navBarOpenedFromMobile = false;
      this.store.dispatch(new ToggleNavBar(false));
    }
    this.showTopicChildren(false);
    this.showMobileTopicChildren(false);
  }

  showMobileTopicChildren(shown: boolean, componentClass?) {
    const componentClassSelector = componentClass ? `.${componentClass}` : '';
    const slideLeft = document.querySelectorAll(
      `${componentClassSelector}.slide-left, ${componentClassSelector} .slide-left, .navbar-nav.slide-left`
    );
    if (shown) {
      for (let i = 0; i < slideLeft.length; i++) {
        slideLeft[i].classList.add('active');
      }
    } else {
      for (let i = 0; i < slideLeft.length; i++) {
        slideLeft[i].classList.remove('active');
      }
    }
  }

  showTopicChildren(shown: boolean, children?, categoryName?) {
    const list = document.querySelectorAll('.congress-topic-inner');
    if (shown) {
      for (let i = 0; i < list.length; i++) {
        list[i].classList.add('active');
      }
      this.topicCategoryChildren = children;
      this.selectedTopic = categoryName;
    } else {
      for (let i = 0; i < list.length; i++) {
        list[i].classList.remove('active');
      }
      this.topicCategoryChildren = null;
      this.selectedTopic = '';
    }
  }

  toggleNavCongress() {
    this.getToggleState();
    this.navBarOpenedFromMobile ? this.handleToggleNavBar() : null;
  }

  handleGoToCongressClick(slug: string, congressMode = 'Live') {
    this.toggleNavCongress();
    this.store.dispatch(new GoToCongressLandingPage(slug, congressMode));
  }

  getTopicCategoryChildren(from = 0, to = 8) {
    return this.topicCategoryChildren?.length ? this.topicCategoryChildren.slice(from, to) : [];
  }
}
