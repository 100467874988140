export const AUTH_UI_PATH_ELEMENTS = {
  rootElement: 'auth',
  root: {
    loginElement: 'login',
    authElement: 'authorize',
    signUpElement: 'sign-up',
    signUpSuccessElement: 'sign-up-success',
    signUpConfirmElement: 'sign-up-confirm',
    passwordResetElement: 'password-reset',
    passwordReset: {
      requestElement: 'request',
      resetElement: 'reset'
    },
    editPasswordElement: 'edit-password',
    editUserElement: 'edit-user'
  },
  token: 'token'
};

export const AUTH_UI_PATHS = {
  root: {
    login: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.loginElement}`,
    oauth: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.authElement}/`,
    signUp: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.signUpElement}`,
    signUpSuccess: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.signUpSuccessElement}`,
    signUpConfirm: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.signUpConfirmElement}`,
    passwordReset: {
      // tslint:disable-next-line: max-line-length
      request: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.requestElement}`,
      reset: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.resetElement}`
    },
    editPassword: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.editPasswordElement}`,
    editUser: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.editUserElement}`
  }
};
