import { Component, HostListener, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CongressListItem } from 'medtoday-models-library';
import { imagePaths } from '../../../../medtoday/src/environments/environment';
import { GoToCongressLandingPage } from '../../../core/router/actions/main-navigation.actions';
import { BaseAppState } from '../../../core/store/reducers';
import { ObservableComponent } from '../observable/observable.component';
import { HeroSliderSideItem } from '../hero-slider-sidely/hero-slider-mapper';

@Component({
  selector: 'app-hero-slider',
  templateUrl: './hero-slider.component.html',
  styleUrls: ['./hero-slider.component.scss']
})
export class HeroSliderComponent extends ObservableComponent {
  @Input() congresses: CongressListItem[] = [];

  defaultTouch = { x: 0, time: 0 };
  logoPathUrl = imagePaths.logos;
  activeCarouselItem = 0;

  constructor(
    protected store: Store<BaseAppState>,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string
  ) {
    super();
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];

    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaTime = event.timeStamp - this.defaultTouch.time;
      this.onSwipe(deltaX, deltaTime);
    }
  }

  onSwipe(distance: number, time: number) {
    const movement = distance / time;
    if (movement > 0) {
      // @ts-ignore
      $('#congressCarousel').carousel('prev');
    }

    if (movement < 0) {
      // @ts-ignore
      $('#congressCarousel').carousel('next');
    }
  }

  handleGoToCongressClick(congress: CongressListItem | HeroSliderSideItem) {
    if (congress.externalLink) {
      window.open(congress.externalLink, '_blank');
    } else if (congress.slug) {
      this.store.dispatch(new GoToCongressLandingPage(congress.slug, congress.congressMode));
    }
  }
}
