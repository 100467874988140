import { Action } from '@ngrx/store';

export enum SurveyActionTypes {
  OpenMainSurveyPopup = '[Survey] Open main survey popup',
  CloseMainSurveyPopup = '[Survey] Close main survey popup'
}
export class OpenMainSurveyPopup implements Action {
  readonly type = SurveyActionTypes.OpenMainSurveyPopup;
}

export class CloseMainSurveyPopup implements Action {
  readonly type = SurveyActionTypes.CloseMainSurveyPopup;
}

export type SurveyActions = OpenMainSurveyPopup | CloseMainSurveyPopup;
