import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { getCongressListUpcomingEvents } from '../../../../../../todaylib/core/data/selectors/data-api.selectors';
import { imagePaths } from '../../../../environments/environment';
import { GoToCMEContent } from '../../../../../../todaylib/core/router/actions/main-navigation.actions';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../../../todaylib/core/store/reducers';

@Component({
  selector: 'app-cme-info-page',
  templateUrl: './cme-info-page.component.html',
  styleUrls: ['./../event-cme-page/event-cme-page.component.scss', './cme-info-page.component.scss']
})
export class CmeInfoPageComponent {
  upcomingCmeCongresses$ = this.store
    .select(getCongressListUpcomingEvents)
    .pipe(map(congresses => congresses?.filter(congress => congress.cmeActive)));

  thumbnailPath = imagePaths.thumbnails;

  constructor(private store: Store<BaseAppState>) {}

  carouselItemClickCallback(congressSlug: string) {
    this.store.dispatch(new GoToCMEContent(congressSlug));
  }
}
