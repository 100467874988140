import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import {
  passwordPatternValidator,
  matchingPasswordsValidator
} from 'projects/todaylib/shared/form-validators/password-validator';
import { UpdatePasswordRequest } from 'projects/todaylib/shared/models';
import { GoToEditUser } from '../../../actions/auth-ui-navigation.actions';

@Component({
  selector: 'app-edit-password-form',
  templateUrl: './edit-password-form.component.html',
  styleUrls: ['./edit-password-form.component.scss']
})
export class EditPasswordFormComponent {
  @Output('editPassword') editPasswordEmitter = new EventEmitter<UpdatePasswordRequest>();

  constructor(private store: Store<BaseAppState>) {}
  // TODO: get user id from selector

  form: FormGroup = new FormGroup(
    {
      currentPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), passwordPatternValidator]),
      passwordConfirmation: new FormControl('', Validators.required)
    },
    {
      validators: [matchingPasswordsValidator]
    }
  );

  editPassword() {
    if (!this.form.valid) {
      return;
    }

    const request: UpdatePasswordRequest = {
      currentPassword: this.form.controls['currentPassword'].value,
      password: this.form.controls['password'].value
    };

    this.editPasswordEmitter.emit(request);
  }

  handleBackToProfileClick() {
    this.store.dispatch(new GoToEditUser());
  }
}
