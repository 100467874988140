import { SurveyActions, SurveyActionTypes } from '../actions/survey.actions';

export interface SurveyState {
  showMainSurveyPopup: boolean | undefined;
}

const initialState: SurveyState = {
  showMainSurveyPopup: false
};

export function reducer(state = initialState, action: SurveyActions) {
  switch (action.type) {
    case SurveyActionTypes.OpenMainSurveyPopup: {
      return {
        ...state,
        showMainSurveyPopup: true
      };
    }
    case SurveyActionTypes.CloseMainSurveyPopup: {
      return {
        ...state,
        showMainSurveyPopup: false
      };
    }
    default:
      return state;
  }
}
