<div class="congress-banner" *ngIf="disclaimer || congressSeriesLogo || congressLogo" [class.on-bottom]="onBottom">
  <div class="container">
    <div class="row">
      <div class="col-12 d-md-none mb-1" [class.d-md-flex]="onBottom">
        <div class="divider w-100 h-30"></div>
      </div>
      <div class="congress-logo col-12 col-lg-6">
        <img *ngIf="congressLogo" [src]="s3BucketUrl + logosUrl + congressLogo" alt="" draggable="false" />
        <img class="hide-desktop" [src]="s3BucketUrl + logosUrl + congressSeriesLogo" alt="" draggable="false" />
      </div>
      <div class="congress-disclaimer col-12 col-lg-6 d-flex align-items-center justify-content-lg-end">
        <div *ngIf="disclaimer || congressSeriesLogo" class="disclaimer-wrapper">
          <p [innerHtml]="disclaimer"></p>
          <img
            *ngIf="congressSeriesLogo"
            class="hide-tablet"
            [src]="s3BucketUrl + logosUrl + congressSeriesLogo"
            alt=""
            draggable="false"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="divider w-100"></div>
      </div>
    </div>
  </div>
</div>
