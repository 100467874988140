import { Action } from '@ngrx/store';

export enum MainNavigationActionTypes {
  GoToCongressList = '[Main Navigation] Go To Congress List',
  GoToMedicalEducationPage = '[Main Navigation] Go To Medical Education Page',
  GoToCongressLandingPage = '[Main Navigation] Go To Congress Langing Page',
  GoToProgramme = '[Main Navigation] Go To Programme',
  GoToExperts = '[Main Navigation] Go To Experts',
  GoToChannel = '[Main Navigation] Go To Channel',
  GoToSponsorChannel = '[Main Navigation] Go To Sponsor Channel',
  GoToOnDemandVideos = '[Main Navigation] Go To On Demand Videos',
  GoToOnDemandVideo = '[Main Navigation] Go To On Demand Video',
  GoToFeaturedContent = '[Main Navigation] Go To Featured Content',
  GoToNewsletter = '[Main Navigation] Go To Newsletter',
  GoToCMEContent = '[Main Navigation] Go To CME Content',
  GoToCMEPage = '[Main Navigation] Go To Global CME Page',
  GoToUpcomingEventsPage = '[Main Navigation] Go To Upcoming Events Page',

  GoToHome = '[Router] Navigate to start',
  GoToImprint = '[Router] Navigate to imprint',
  GoToDisclaimer = '[Router] Navigate to disclaimer',
  GoToDataProtection = '[Router] Navigate to data protection',
  GoToAboutUs = '[Router] Navigate to about us',
  GoToNetiquette = '[Router] Navigate to netiquette',
  GoToTermsOfService = '[Router] Navigate to terms of service',
  GoToSearch = '[Router] Navigate to search',
  GoToPartnerDetails = '[Router] Navigate to partner details',
  GoToSponsorBoothDetails = '[Router] Navigate to sponsor booth details',
  GoToElearning = '[Router] Navigate to e-learning',
  GoToElearningResults = '[Router] Navigate to e-learning results',
  GoToMyCme = '[Router] Navigate to my cme',
  GoToDiseasesPage = '[Router] Navigate to Diseases Page',
  GoToBasicStudyKnowledgePage = '[Router] Navigate to Basic Study Knowledge Page',
  GoToPhase3Studies = '[Router] Navigate to Phase 3 Studies Page'
}

export class GoToCongressList implements Action {
  readonly type = MainNavigationActionTypes.GoToCongressList;
}
export class GoToMedicalEducationPage implements Action {
  readonly type = MainNavigationActionTypes.GoToMedicalEducationPage;
}

export class GoToCongressLandingPage implements Action {
  readonly type = MainNavigationActionTypes.GoToCongressLandingPage;

  constructor(public slug: string, public congressMode?: string) {}
}

export class GoToProgramme implements Action {
  readonly type = MainNavigationActionTypes.GoToProgramme;

  constructor(public slug: string, public navigatedFromLandingPage?: boolean) {}
}

export class GoToExperts implements Action {
  readonly type = MainNavigationActionTypes.GoToExperts;

  constructor(public slug: string) {}
}

export class GoToOnDemandVideos implements Action {
  readonly type = MainNavigationActionTypes.GoToOnDemandVideos;

  constructor(public congressSlug: string, public topicIdToFilter?: number) {}
}

export class GoToOnDemandVideo implements Action {
  readonly type = MainNavigationActionTypes.GoToOnDemandVideo;

  constructor(
    public onDemandVideoId: number,
    public shouldReload = false,
    public language?: string,
    public congressSlug?: string
  ) {}
}

export class GoToChannel implements Action {
  readonly type = MainNavigationActionTypes.GoToChannel;

  constructor(public congressSlug: string, public channelSlug: string, public thirdUrlSegment?: string) {}
}

export class GoToSponsorChannel implements Action {
  readonly type = MainNavigationActionTypes.GoToSponsorChannel;

  constructor(public congressSlug: string, public channelSlug: string) {}
}

export class GoToFeaturedContent implements Action {
  readonly type = MainNavigationActionTypes.GoToFeaturedContent;

  constructor(public congressSlug: string, public navigatedFromLandingPage?: boolean) {}
}

export class GoToNewsletter implements Action {
  readonly type = MainNavigationActionTypes.GoToNewsletter;

  constructor(public emailAddress?: string) {}
}

export class GoToCMEContent implements Action {
  readonly type = MainNavigationActionTypes.GoToCMEContent;

  constructor(public congressSlug: string) {}
}

export class GoToCMEPage implements Action {
  readonly type = MainNavigationActionTypes.GoToCMEPage;
}

export class GoToUpcomingEventsPage implements Action {
  readonly type = MainNavigationActionTypes.GoToUpcomingEventsPage;
}

export class GoToImprint implements Action {
  readonly type = MainNavigationActionTypes.GoToImprint;
}

export class GoToDisclaimer implements Action {
  readonly type = MainNavigationActionTypes.GoToDisclaimer;
}

export class GoToNetiquette implements Action {
  readonly type = MainNavigationActionTypes.GoToNetiquette;
}

export class GoToHome implements Action {
  readonly type = MainNavigationActionTypes.GoToHome;
}

export class GoToAboutUs implements Action {
  readonly type = MainNavigationActionTypes.GoToAboutUs;
}

export class GoToTermsOfService implements Action {
  readonly type = MainNavigationActionTypes.GoToTermsOfService;
}

export class GoToSearch implements Action {
  readonly type = MainNavigationActionTypes.GoToSearch;
}

export class GoToPartnerDetails implements Action {
  readonly type = MainNavigationActionTypes.GoToPartnerDetails;

  constructor(public partnerSlug: string) {}
}

export class GoToSponsorBoothDetails implements Action {
  readonly type = MainNavigationActionTypes.GoToSponsorBoothDetails;

  constructor(public congressSlug: string, public sponsorSlug: string) {}
}

export class GoToElearning implements Action {
  readonly type = MainNavigationActionTypes.GoToElearning;

  constructor(public vodId: number, public congressSlug: string) {}
}

export class GoToElearningResults implements Action {
  readonly type = MainNavigationActionTypes.GoToElearningResults;

  constructor(public vodId: number, public congressSlug: string) {}
}

export class GoToMyCme implements Action {
  readonly type = MainNavigationActionTypes.GoToMyCme;

  constructor() {}
}

export class GoToDiseasesPage implements Action {
  readonly type = MainNavigationActionTypes.GoToDiseasesPage;

  constructor(public topicCategorySlug: string, public topicId?: number | null) {}
}

export class GoToBasicStudyKnowledgePage implements Action {
  readonly type = MainNavigationActionTypes.GoToBasicStudyKnowledgePage;
}

export class GoToPhase3Studies implements Action {
  readonly type = MainNavigationActionTypes.GoToPhase3Studies;
}

export type MainNavigationActions = GoToOnDemandVideos | GoToProgramme | GoToFeaturedContent;
