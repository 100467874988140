import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { createNeedsAuthenticationHeader } from '../../../../../todaylib/core/data/utilities/networking.utilites';
import { MedBoardsResponseModel } from '../../models/medboards.model';

@Injectable()
export class MedboardsApiService {
  constructor(@Inject('medBoardsApiUrl') protected medBoardsApiUrl: string, protected http: HttpClient) {}

  getMedBoardsData() {
    const headers = createNeedsAuthenticationHeader();

    return this.http.get<MedBoardsResponseModel>(`${this.medBoardsApiUrl}/medtodayDashboard`, headers);
  }
}
