<div class="container subpage-sub-header mt-md-5" [class.mb-md-5]="congressMetaData.layout.subpageLogo">
  <div class="subpage-sub-header-row row" [class.has-subpage-logo]="congressMetaData.layout.subpageLogo">
    <div class="order-1 order-lg-0 col-xl-7 col-lg-7 col-12 align-self-center">
      <h1
        *ifApp="'MedToday'"
        class="my-3"
        [innerHtml]="subpageName + '<br>' + (congressMetaData.name | uppercase)"
      ></h1>
      <h1 *ifApp="'PatientsToday'" class="my-3" [innerHtml]="subpageName + '<br>' + congressMetaData.name"></h1>
    </div>

    <ng-container *ifApp="'PatientsToday'">
      <div
        *ngIf="congressMetaData.layout.subpageLogo"
        class="order-0 order-lg-1 col-xl-4 col-lg-4 col-12 d-flex justify-content-end subpage-logo-wrapper"
      >
        <img [src]="s3BucketUrl + logosFolderSlug + congressMetaData.layout.subpageLogo" />
      </div>
    </ng-container>

    <ng-container *ifApp="'MedToday'">
      <div
        *ngIf="congressMetaData.layout.subpageLogo"
        class="order-0 order-lg-1 col-xl-4 col-lg-4 col-12 d-flex justify-content-end"
      >
        <div class="logo-wrapper">
          <div class="d-flex align-items-center justify-content-center rounded-circle logo-circle">
            <img [src]="s3BucketUrl + logosFolderSlug + congressMetaData.layout.subpageLogo" />
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
