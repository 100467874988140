import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicScriptLoaderService } from 'projects/todaylib/shared/services/dynamic-script-loader-service.service';

import { BaseAppState } from '../../store/reducers';
import { CloseMainSurveyPopup, OpenMainSurveyPopup } from '../actions/survey.actions';
import { getMainSurveyPopupState } from '../selectors/survey.selectors';
import { IS_PATIENTS_TODAY_APP } from '../../definitions/app.definitions';

@Component({
  selector: 'app-survey-popup',
  templateUrl: './survey-popup.component.html',
  styleUrls: ['./survey-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyPopupComponent implements OnInit {
  private popupMinimized = false;
  private popupMaximized = false;

  @ViewChild('surveyPopup') surveyPopupRef: ElementRef<HTMLElement>;

  showMainSurveyPopup$ = this.store.select(getMainSurveyPopupState);
  closedMainSurveyPopup = localStorage.getItem('main-survey-closed');
  minimizedMainSurveyPopup = localStorage.getItem('main-survey-minimized');

  constructor(
    protected store: Store<BaseAppState>,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    @Inject('surveyMonkeyScriptUrl') private surveyMonkeyScriptUrl: string,
    @Inject(IS_PATIENTS_TODAY_APP) public isPatientsToday: boolean
  ) {}

  ngOnInit(): void {
    this.showMainSurveyOnFirstVisit();
    this.dynamicScriptLoader.loadScript(this.surveyMonkeyScriptUrl);
  }

  private showMainSurveyOnFirstVisit(): void {
    if (!this.closedMainSurveyPopup) {
      this.store.dispatch(new OpenMainSurveyPopup());
    }
  }

  public close(): void {
    this.store.dispatch(new CloseMainSurveyPopup());
  }

  public minimize(): void {
    if (!this.popupMinimized) {
      this.minimizedMainSurveyPopup ? null : localStorage.setItem('main-survey-minimized', JSON.stringify(true));
      this.surveyPopupRef.nativeElement.classList.add('minimized');
      this.surveyPopupRef.nativeElement.classList.remove('maximized');
      this.popupMaximized = false;
    } else {
      this.surveyPopupRef.nativeElement.classList.remove('minimized');
    }
    this.popupMinimized = !this.popupMinimized;
  }

  public maximize(): void {
    if (!this.popupMaximized) {
      this.surveyPopupRef.nativeElement.classList.add('maximized');
      this.surveyPopupRef.nativeElement.classList.remove('minimized');
      this.popupMinimized = false;
    } else {
      this.surveyPopupRef.nativeElement.classList.remove('maximized');
    }
    this.popupMaximized = !this.popupMaximized;
  }
}
