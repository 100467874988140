import { ActionReducer, Action } from '@ngrx/store';

import { BaseAppState } from './index';
import { AuthActionTypes } from '../../auth/actions/auth.actions';

export function logoutResetReducer(reducer: ActionReducer<BaseAppState>) {
  return function (state: BaseAppState, action: Action) {
    const isLogout =
      action.type === AuthActionTypes.LogoutSuccess ||
      action.type === AuthActionTypes.SessionExpired;

    const nextState = isLogout ? undefined : state;
    return reducer(nextState, action);
  };
}
