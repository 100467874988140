<div class="d-flex p-2 my-2 flex-column" [class.flex-md-row]="!isColumnOnDekstop">
  <div class="rounded-circle profile-image mb-3" [class.mb-md-0]="!isColumnOnDekstop">
    <img class="w-100" [src]="s3BucketUrl + avatarsImageFolderSlug + expert.profileImage" />
  </div>
  <div class="d-flex flex-column pl-0 info" [class.pl-md-3]="!isColumnOnDekstop">
    <p class="font-weight-bold mb-0">{{ expert | expert }}</p>
    <span class="mb-1" *ngIf="expert.areaOfActivity" [class.text-dark]="applicationName === 'PatientsToday'">{{
      expert.areaOfActivity
    }}</span>
    <a
      *ngIf="expert.disclosure || (applicationName === 'PatientsToday' && !expert.disclosure)"
      [class]="applicationName === 'PatientsToday' ? 'text-patients' : 'text-danger'"
      data-toggle="modal"
      href="#expertDetails"
      draggable="false"
      (click)="handleExpertDetailsClick(expert)"
      ><u>{{ 'ExpertsPage.Details' | translate }}</u></a
    >
  </div>
</div>
