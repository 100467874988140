import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import {
  PasswordActionTypes,
  RequestPasswordReset,
  ResetPassword
} from 'projects/todaylib/core/auth/actions/auth-password.actions';
import { GoToHome } from 'projects/todaylib/core/router/actions/main-navigation.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import {
  matchingPasswordsValidator,
  passwordPatternValidator
} from 'projects/todaylib/shared/form-validators/password-validator';
import { RequestPasswordResetRequest, ResetPasswordRequest } from 'projects/todaylib/shared/models';
import { combineLatest } from 'rxjs';

import { getCodeQueryParam, getUserIdQueryParam } from '../../selectors/auth-ui-navigation.selectors';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {
  asyncProcessKey = PasswordActionTypes.ResetPassword;

  userId$ = this.store.select(getUserIdQueryParam);
  code$ = this.store.select(getCodeQueryParam);

  form: FormGroup = new FormGroup(
    {
      password: new FormControl('', [Validators.required, Validators.minLength(8), passwordPatternValidator]),
      passwordConfirmation: new FormControl('', Validators.required)
    },
    {
      validators: [matchingPasswordsValidator]
    }
  );

  constructor(private store: Store<BaseAppState>) {}

  ngOnInit() {
    this.redirectIfParametersMissing();
  }

  handleChangeClick() {
    if (!this.form.valid) {
      return;
    }

    this.changePassword();
  }

  private changePassword() {
    combineLatest([this.userId$, this.code$])
      .pipe(
        take(1),
        filter(([userId, code]: [string, string]) => Boolean(userId && code))
      )
      .subscribe(([userId, code]: [string, string]) => {
        const request: ResetPasswordRequest = {
          userId,
          code,
          password: this.form.controls['password'].value
        };

        this.store.dispatch(new ResetPassword(request));
      });
  }

  private redirectIfParametersMissing() {
    combineLatest([this.userId$, this.code$])
      .pipe(
        take(1),
        filter(([userId, code]: [string, string]) => !(userId && code))
      )
      .subscribe(() => {
        return this.store.dispatch(new GoToHome());
      });
  }

  handleResetClick(email: string): void {
    const request: RequestPasswordResetRequest = {
      email: email
    };
    this.store.dispatch(new RequestPasswordReset(request));
  }
}
