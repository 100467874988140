import { Action } from '@ngrx/store';

export enum LanguageActionTypes {
  SetLanguage = '[Lang] Set Language'
}

export class SetLanguage implements Action {
  readonly type = LanguageActionTypes.SetLanguage;

  constructor(public language: string) {}
}

export type LanguageActions = SetLanguage;
