import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Session } from 'medtoday-models-library';
import { AnalyticsService } from 'projects/todaylib/core/data/services/ga-analytics.service';
import { GoToChannel } from 'projects/todaylib/core/router/actions/main-navigation.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { TimeSlotRow } from '../../../../models/time-slot-row';
import { SESSION_UTILS_TOKEN } from '../../../../utilities/session.utils';
import { getCongressMetaData } from '../../../../../core/data/selectors/data-api.selectors';

@Component({
  selector: 'app-session-row',
  templateUrl: 'session-row.component.html',
  styleUrls: ['./session-row.component.scss']
})
export class SessionRowComponent implements OnInit {
  congressMetadata$ = this.store.select(getCongressMetaData);
  @Input() row: TimeSlotRow;
  @Input() congressSlug: string;
  @Input() selectedTopic: boolean;
  @Input() isFeaturedContent = false;

  public format = '';

  constructor(
    @Inject(SESSION_UTILS_TOKEN) private sessionUtils,
    private store: Store<BaseAppState>,
    private analyticsService: AnalyticsService,
    @Inject('applicationName') public applicationName: string
  ) {}

  ngOnInit(): void {
    this.getCommonSessionFormat(this.row.sessions);
  }

  isLive(row: TimeSlotRow) {
    return row.sessions?.length && this.sessionUtils.isActive(row);
  }

  private getCommonSessionFormat(sessions: Session[]) {
    sessions.forEach((sn: Session) => {
      const areAllSessionOfSameFormat = sessions.every((session: Session) => sn.format === session.format);
      if (areAllSessionOfSameFormat) {
        this.format = sessions[0].format;
      }
    });
  }

  public handleGoToStream(channelSlug: string) {
    this.analyticsService.pushTag({}, 'navigate-to-channel', 'is-featured-content', false);
    this.store.dispatch(new GoToChannel(this.congressSlug, channelSlug));
  }
}
