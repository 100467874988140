import { Injectable } from '@angular/core';
import { DataLayerCustomDimensions } from '../enums/data-layer-custom-dimensions';
import { FullCustomDimensionsSet } from '../enums/full-custom-dimensions-set';

// This is a type that can be used for a partial version of the dimensions set,
// Where there are gaps and some dimensions are empty
// tslint:disable-next-line: interface-over-type-literal
export type PartialCustomDimensionsSet = { [key: string]: string };

@Injectable()
export class DataLayerCustomDimensionsService {
  private _currentSet: FullCustomDimensionsSet;

  constructor() {
    this._generateEmptyDimensionsSet();
  }

  set dimensions(someDimensions: PartialCustomDimensionsSet) {
    Object.keys(DataLayerCustomDimensions)
      .map(key => DataLayerCustomDimensions[key])
      .forEach(key => {
        this._currentSet[key] = someDimensions[key];
      });
  }

  trigger() {
    // tslint:disable-next-line: no-any
    (<any>window).dataLayer.push(this._currentSet);
  }

  private _generateEmptyDimensionsSet() {
    this._currentSet = {
      [DataLayerCustomDimensions.userType]: undefined,
      [DataLayerCustomDimensions.fieldOfExpertise]: undefined
    };
  }
}
