<div data-overlay class="d-flex flex-column justify-content-md-center o-hidden align-items-center">
  <div class="col-12 col-md-6 offset-0 offset-md-3 sign-up-container">
    <ng-container *ngIf="cdkStepper.selectedIndex === 0">
      <h4 class="text-left sign-up-header font-weight-bold" [innerHTML]="'SignUp.Headline' | translate"></h4>
      <p class="text-left mt-3" [innerHTML]="'SignUp.Subline' | translate"></p>
    </ng-container>

    <div class="form-container text-left">
      <app-sign-up-stepper #cdkStepper (signUpClick)="handleSignUpClick()">
        <!--   FIRST STEP     -->

        <cdk-step [stepControl]="firstStepForm" *ngIf="signupMode === SignupProcess.FULL">
          <ng-template cdkStepLabel>
            <h5 class="mb-3 mt-4">{{ 'Auth.LoginData' | translate }}</h5>
          </ng-template>
          <form [formGroup]="firstStepForm" name="sign-up" (ngSubmit)="handleSignUpClick()" #ngForm="ngForm" novalidate>
            <div class="form-group">
              <label>{{ 'Auth.Email' | translate }}*</label>
              <input
                type="email"
                class="form-control form-control-lg"
                name="sign-up-email"
                placeholder="{{ 'SignUp.EnterEmailAddress' | translate }}"
                formControlName="emailAddress"
                [class.is-invalid]="ngForm.submitted && firstStepForm.controls['emailAddress'].invalid"
              />
              <div class="invalid-feedback">
                <div *ngIf="firstStepForm.controls['emailAddress'].hasError('required')">
                  {{ 'Auth.EmailRequired' | translate }}
                </div>
                <div *ngIf="firstStepForm.controls['emailAddress'].hasError('email')">
                  {{ 'Auth.EmailInvalid' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ 'Auth.Passwort' | translate }}*</label>
              <input
                type="password"
                autocomplete="new-password"
                class="form-control form-control-lg"
                name="sign-up-password"
                placeholder="{{ 'LogIn.EnterPassword' | translate }}"
                formControlName="password"
                [class.is-invalid]="ngForm.submitted && firstStepForm.controls['password'].invalid"
              />
              <div class="invalid-feedback">
                <div *ngIf="firstStepForm.controls['password'].hasError('required')">
                  {{ 'LogIn.PasswordRequired' | translate }}
                </div>
                <div *ngIf="firstStepForm.controls['password'].hasError('minlength')">
                  {{ 'SignUp.PasswordMinLength' | translate }}
                </div>
                <div *ngIf="firstStepForm.controls['password'].hasError('pattern')">
                  {{ 'SignUp.PasswordInvalidRules' | translate }}
                </div>
              </div>
            </div>
          </form>
        </cdk-step>

        <!--   SECOND STEP     -->

        <cdk-step [stepControl]="secondStepForm">
          <ng-template cdkStepLabel>
            <h5 class="mb-3 mt-4">{{ 'SignUp.PersonalData' | translate }}</h5>
          </ng-template>
          <form
            [formGroup]="secondStepForm"
            name="sign-up"
            (ngSubmit)="handleSignUpClick()"
            #ngForm="ngForm"
            novalidate
          >
            <div class="row compact-controls">
              <div class="col">
                <div class="form-group">
                  <label>{{ 'SignUp.Salutation' | translate }}*</label>
                  <div class="dropdown secondary">
                    <button
                      class="btn d-flex align-items-center justify-content-between navigation-menu dropdown-toggle text-light w-100"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      [class.invalid]="ngForm.submitted && secondStepForm.controls['salutation'].invalid"
                      [class.is-invalid]="ngForm.submitted && secondStepForm.controls['salutation'].invalid"
                      aria-expanded="false"
                    >
                      <h6 class="text-left m-0 w-100">
                        {{
                          secondStepForm.controls['salutation'].value
                            ? ('SignUp.' + secondStepForm.controls['salutation'].value | translate)
                            : ('SignUp.Salutation' | translate)
                        }}
                      </h6>
                      <div class="icon-16 arrow-indicator" [inlineSVG]="'assets/icons/arrow-down.svg'"></div>
                    </button>
                    <div class="dropdown-menu dropdown-small-gap bg-dark w-100" aria-labelledby="select">
                      <a
                        [class.text-primary]="secondStepForm.controls['salutation'].value === 'Herr'"
                        (click)="handleSalutationSelect('Mr')"
                        class="dropdown-item text-light"
                        >{{ 'SignUp.Mr' | translate }}</a
                      >
                      <a
                        [class.text-primary]="secondStepForm.controls['salutation'].value === 'Frau'"
                        (click)="handleSalutationSelect('Ms')"
                        class="dropdown-item text-light"
                        >{{ 'SignUp.Ms' | translate }}</a
                      >
                      <a
                        [class.text-primary]="secondStepForm.controls['salutation'].value === 'Divers'"
                        (click)="handleSalutationSelect('Divers')"
                        class="dropdown-item text-light"
                        >{{ 'SignUp.Divers' | translate }}</a
                      >
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="secondStepForm.controls['salutation'].hasError('required')">
                        {{ 'SignUp.SalutationRequired' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>{{ 'SignUp.Title' | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    name="sign-up-title"
                    placeholder="{{ 'SignUp.EnterTitle' | translate }}"
                    formControlName="title"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ 'SignUp.FirstName' | translate }}*</label>
              <input
                type="text"
                class="form-control form-control-lg"
                name="sign-up-name"
                placeholder="{{ 'SignUp.EnterFirstName' | translate }}"
                formControlName="name"
                [class.is-invalid]="ngForm.submitted && secondStepForm.controls['name'].invalid"
              />
              <div class="invalid-feedback">
                <div *ngIf="secondStepForm.controls['name'].hasError('required')">
                  {{ 'SignUp.FirstNameRequired' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ 'SignUp.Surname' | translate }}*</label>
              <input
                type="text"
                class="form-control form-control-lg"
                name="sign-up-lastName"
                placeholder="{{ 'SignUp.EnterSurname' | translate }}"
                formControlName="lastName"
                [class.is-invalid]="ngForm.submitted && secondStepForm.controls['lastName'].invalid"
              />
              <div class="invalid-feedback">
                <div *ngIf="secondStepForm.controls['lastName'].hasError('required')">
                  {{ 'SignUp.SurnameRequired' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ 'SignUp.Institution' | translate }}</label>
              <input
                type="text"
                class="form-control form-control-lg"
                name="sign-up-institution"
                placeholder="{{ 'SignUp.EnterInstitution' | translate }}"
                formControlName="institution"
              />
            </div>
          </form>
        </cdk-step>

        <!-- THIRD STEP     -->

        <cdk-step [stepControl]="thirdStepForm">
          <ng-template cdkStepLabel>
            <h5 class="mb-3 mt-4">{{ 'SignUp.MoreInformation' | translate }}</h5>
          </ng-template>
          <form [formGroup]="thirdStepForm" name="sign-up" (ngSubmit)="handleSignUpClick()" #ngForm="ngForm" novalidate>
            <div
              class="form-group"
              [ngClass]="
                ngForm.submitted && thirdStepForm.controls['occupationActivity'].invalid ? 'invalid is-invalid p-2' : ''
              "
            >
              <ng-container *ngFor="let occupationActivity of occupationActivities">
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    [id]="occupationActivity.key"
                    name="occupationActivity"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label"
                    [for]="occupationActivity.key"
                    (click)="handleSelectOccupationActivity(occupationActivity.key)"
                    >{{ occupationActivity.text | translate }}</label
                  >
                </div>
              </ng-container>

              <div class="form-group mt-2" *ngIf="isUserDoctor$ | async">
                <app-dropdown
                  class="d-block"
                  [control]="thirdStepForm.controls.topic"
                  [options]="topics"
                  (selected)="handleTopicSelect($event)"
                  [placeholder]="('EditUser.ChooseSubjectArea' | translate) + '*'"
                  [search]="true"
                  [hideAll]="true"
                  [searchPlaceholder]="'EditUser.SearchTopic' | translate"
                  (searchChanged)="searchForTopics($event)"
                ></app-dropdown>
                <div class="form-group mt-3" *ngIf="currentLang === 'de'">
                  <label>{{ 'SignUp.EFN' | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    name="sign-up-efn-number"
                    placeholder="{{ 'SignUp.EnterEFN' | translate }}"
                    formControlName="efnNumber"
                  />
                </div>
              </div>
            </div>
            <div class="invalid-feedback">
              <div *ngIf="thirdStepForm.controls['occupationActivity'].hasError('required')">
                {{ 'SignUp.ActionRequired' | translate }}
              </div>
            </div>
            <div class="form-group mt-3 mb-5">
              <div class="mr-4 custom-switch mb-3">
                <input
                  type="checkbox"
                  class="custom-control-input cms-toggle"
                  id="acceptNewsletterCheckbox"
                  formControlName="hasAcceptedNewsLetter"
                />
                <label class="custom-control-label pl-4" for="acceptNewsletterCheckbox">{{
                  'SignUp.SubscribeNewsletter' | translate
                }}</label>
              </div>
              <div class="data-privacy custom-control custom-checkbox text-small">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="acknowledgeDataProtectionInformationCheckbox"
                  formControlName="hasAcknowledgedDataProtectionInformation"
                  [class.is-invalid]="
                    ngForm.submitted && thirdStepForm.controls['hasAcknowledgedDataProtectionInformation'].invalid
                  "
                />
                <label class="custom-control-label pl-3" for="acknowledgeDataProtectionInformationCheckbox">
                  {{ 'SignUp.AckDataProtection' | translate }}
                  <a (click)="handleTOSClick()">{{ 'SignUp.PrivacyPolicy' | translate }}</a
                  >.
                </label>
              </div>
              <div
                class="invalid-feedback d-block"
                [class.d-block]="
                  ngForm.submitted && thirdStepForm.controls['hasAcknowledgedDataProtectionInformation'].invalid
                "
              >
                <div *ngIf="thirdStepForm.controls['hasAcknowledgedDataProtectionInformation'].hasError('required')">
                  {{ 'SignUp.HasAcknDataProtection' | translate }}
                </div>
              </div>

              <!--  Truthfulness            -->
              <div class="data-privacy custom-control custom-checkbox text-small">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="acknowledgeTruthfulness"
                  formControlName="hasAcknowledgedTruthfulness"
                  [class.is-invalid]="ngForm.submitted && thirdStepForm.controls['hasAcknowledgedTruthfulness'].invalid"
                />
                <label class="custom-control-label pl-3" for="acknowledgeTruthfulness">
                  {{ 'SignUp.AckTruthfulness' | translate }}
                </label>
              </div>
              <div
                class="invalid-feedback d-block"
                [class.d-block]="ngForm.submitted && thirdStepForm.controls['hasAcknowledgedTruthfulness'].invalid"
              >
                <div *ngIf="thirdStepForm.controls['hasAcknowledgedTruthfulness'].hasError('required')">
                  {{ 'SignUp.AckTruthfulnessMissing' | translate }}
                </div>
              </div>
            </div>
          </form>
        </cdk-step>
      </app-sign-up-stepper>

      <div class="mt-5">
        <p>Sie haben schon ein Konto? <a class="text-danger" (click)="handleLoginClick()">Anmelden</a>.</p>
      </div>
    </div>
  </div>
</div>
