<img class="d-md-block d-none background-image mt-5" src="assets/images/Service-Seiten-Kugeln@2x.png" />
<img class="d-md-none d-block background-image mobile mt-5" src="assets/images/Mobile-Kugel@2x.png" />
<div data-overlay class="d-flex flex-column justify-content-md-center mt-5">
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-6">
          <h1 class="h3 mb-4">{{ 'LogIn.ForgotPassword' | translate }}</h1>
          <p class="font-weight-light">{{ 'LogIn.ForgotPasswordDesc' | translate }}</p>
          <form [formGroup]="form" name="login" (ngSubmit)="handleResetClick()" #ngForm="ngForm" novalidate>
            <div class="form-group">
              <label>{{ 'Auth.Email' | translate }}</label>
              <input
                type="email"
                autocomplete="email"
                class="form-control form-control-lg"
                name="sign-up-email"
                placeholder="{{ 'SignUp.EnterEmailAddress' | translate }}"
                formControlName="emailAddress"
                [class.is-invalid]="ngForm.submitted && form.controls['emailAddress'].invalid"
              />
              <div class="invalid-feedback">
                <div *ngIf="form.controls['emailAddress'].hasError('required')">
                  {{ 'Auth.EmailRequired' | translate }}
                </div>
                <div *ngIf="form.controls['emailAddress'].hasError('pattern')">
                  {{ 'Auth.EmailInvalid' | translate }}
                </div>
              </div>
            </div>

            <button class="btn btn-danger rounded-pill btn-block" type="submit">
              {{ 'LogIn.ResetPassword' | translate }}
            </button>
          </form>
          <div class="text-center text-small mt-3">
            {{ 'LogIn.BackTo' | translate }}
            <a class="text-danger" (click)="handleLoginClick()">{{ 'Auth.SignIn' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-async-state-overlay [processKey]="asyncProcessKey"></app-async-state-overlay>
</div>
<app-spacer></app-spacer>
