import { CmeTrackingTrigger } from '../../models/cme-tracking-trigger.model';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { filter } from 'rxjs/operators';
import { CongressMetadata } from 'medtoday-models-library/lib/models';
import { SendCMESignal } from '../../medtoday-store/actions/medtoday-store.actions';
import {
  getCMEActiveState,
  getUserHasEfnNumber,
  isLoggedIn
} from '../../../../../todaylib/core/auth/selectors/auth.selectors';
import { getCongressMetaData } from '../../../../../todaylib/core/data/selectors/data-api.selectors';
import { getCongressSlugRouterParam } from '../../../../../todaylib/core/router/selectors/router.selectors';
import { environment } from '../../../environments/environment';
import { CongressCmeData } from 'medtoday-models-library/lib/models/responses/congress/congress-cme-data.model';
import moment from 'moment';
import { CongressDay } from 'medtoday-models-library/lib/models/responses/congress/congress-day.model';

const UPDATE_CHECK_INTERVAL = 30 * 1000;
const TIMESTAMPS_LOCALSTORAGE_KEY = 'medtoday_cme_timestamps';

@Injectable({
  providedIn: 'root'
})
export class CmeTrackingTriggerStrategy implements CmeTrackingTrigger {
  private timeStamps: string[] = [];
  private cmeSender: Subscription;
  private checkInterval;

  isLoggedIn$ = this.store.select(isLoggedIn);
  hasEfnNumber$ = this.store.select(getUserHasEfnNumber);
  cmeActive$ = this.store.select(getCMEActiveState);
  congressMetaData$ = this.store.select(getCongressMetaData);
  congressSlugParam$ = this.store.select(getCongressSlugRouterParam);

  constructor(protected store: Store, private injector: Injector) {}

  init() {
    this.triggerCmeStateIfNeeded();
    this.initCMESender();
  }

  triggerCmeStateIfNeeded(): Observable<any> {
    return EMPTY;
  }

  public initCMESender() {
    // We wait until the application has determined we are "stable" before we start the check interval
    // https://github.com/angular/angular-cli/issues/8779#issuecomment-350737663
    const applicationRef = this.injector.get(ApplicationRef);
    const storedTimeStamps = localStorage.getItem(TIMESTAMPS_LOCALSTORAGE_KEY);
    if (storedTimeStamps) {
      this.timeStamps = JSON.parse(storedTimeStamps);
    }

    this.cmeSender = combineLatest([
      applicationRef.isStable,
      this.isLoggedIn$,
      this.cmeActive$,
      this.hasEfnNumber$,
      this.congressSlugParam$,
      this.congressMetaData$
    ])
      .pipe(
        filter(
          ([stable, _loggedIn, _cmeActive, _hasEfn, _congressSlug, _metaData]: [
            boolean,
            boolean,
            boolean,
            boolean,
            string,
            CongressMetadata
          ]) => !stable
        )
        // tslint:disable-next-line: max-line-length
      )
      .subscribe(
        ([_stable, loggedIn, cmeActive, hasEfn, congressSlug, metaData]: [
          boolean,
          boolean,
          boolean,
          boolean,
          string,
          CongressMetadata
        ]) => {
          if (
            loggedIn &&
            cmeActive &&
            (environment.language === 'ch' || (environment.language === 'de' && hasEfn)) &&
            congressSlug &&
            metaData?.cmeData.days?.length &&
            metaData?.cmeData.cmeEnabled
          ) {
            if (this.checkInterval) {
              return;
            }

            const firstTimeStamp = new Date().toISOString();
            this.timeStamps = Object.assign([], this.timeStamps);
            this.timeStamps.push(firstTimeStamp);
            localStorage.setItem(TIMESTAMPS_LOCALSTORAGE_KEY, JSON.stringify(this.timeStamps));

            this.store.dispatch(new SendCMESignal(congressSlug, this.timeStamps));
            let elapsedTime = 0;
            this.checkInterval = setInterval(() => {
              if ((!navigator || navigator.onLine) && !document.hidden) {
                elapsedTime++;

                if (elapsedTime >= UPDATE_CHECK_INTERVAL / 1000) {
                  const timeStamp = new Date().toISOString();
                  this.timeStamps = Object.assign([], this.timeStamps);
                  this.timeStamps.push(timeStamp);
                  localStorage.setItem(TIMESTAMPS_LOCALSTORAGE_KEY, JSON.stringify(this.timeStamps));
                  elapsedTime = 0;
                  this.store.dispatch(new SendCMESignal(congressSlug, this.timeStamps));
                }
              }
            }, 1000);
          } else {
            clearInterval(this.checkInterval);
            this.checkInterval = null;
          }
        }
      );
  }

  stopCMESender() {
    this.cmeSender.unsubscribe();
    clearInterval(this.checkInterval);
    this.checkInterval = null;
  }

  hasActiveCmeDay(cmeDays: CongressCmeData) {
    const dateNow = moment().utc(true);

    // find congress day for current timepoint +- 30 minutes
    return cmeDays?.days.some((day: CongressDay) => {
      return dateNow.isBetween(moment(day.start).subtract(30, 'minutes'), moment(day.end).add(30, 'minutes'));
    });
  }
}
